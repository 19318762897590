import constants from './constants';

const {
  ECM_URL,
  API_URL_V1,
  DOCUMENT_CONTROLLER,
  ITEMS,
  FILE,
  DOWNLOAD,
} = constants;

const generateECMRoute = (path) => `${API_URL_V1}${path}`;
const getFileDownloadLink = ({
  docTypeId,
  recordId,
  fileId,
}) => `${ECM_URL}${API_URL_V1}${DOCUMENT_CONTROLLER}/${docTypeId}${ITEMS}/${recordId}${FILE}/${fileId}${DOWNLOAD}`;
const getFilesDocument = ({
  docTypeId,
  recordId,
}) => `${ECM_URL}${API_URL_V1}${DOCUMENT_CONTROLLER}/${docTypeId}${ITEMS}/${recordId}${FILE}`;

export default {
  generateECMRoute,
  getFileDownloadLink,
  getFilesDocument,
};
