/* eslint-disable react/no-unescaped-entities */
import { call } from 'redux-saga/effects';
import axios from 'axios';
import get from 'lodash/get';

import i18n from '../../i18nConfig';
import cookiesUtils from '../../cookiesUtils';
import constants from './constants';

const { getCookie } = cookiesUtils;
const { ECM_URL } = constants;
const { locale: { acceptedLanguages } } = i18n;

function* ecmAdapter(options) {
  const token = getCookie('digital_mp_at');
  const headers = {
    Accept: 'application/json',
    'Accept-Language': acceptedLanguages,
    'Content-Type': 'application/json',
    Authorization: `Bearer sso_1.0_${token}`,
    ...get(options, 'headers', {}),
  };
  console.log('AMP  ECM_URL', ECM_URL);
  const reqOptions = {
    baseURL: ECM_URL,
    ...options,
    headers,
  };

  return yield call(axios, reqOptions);
}

export default ecmAdapter;
