import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './CardPanel.module.scss';

const CardPanel = ({
  children,
  width,
  classNameCardPanel,
}) => (
  <div
    className={cn(styles.cardPanelWrapper, classNameCardPanel)}
    style={width ? { width } : null}
  >
    {children}
  </div>
);

CardPanel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classNameCardPanel: PropTypes.string,
};

CardPanel.defaultProps = {
  children: null,
  width: null,
  classNameCardPanel: '',
};

export default memo(CardPanel);
