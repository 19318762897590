/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function SortDesc({
  width,
  height,
  fill,
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="14" y="6.26562" width="10" height="1.9548" rx="0.977401" transform="rotate(-180 14 6.26562)" fill={fill} />
      <rect x="14" y="2.35547" width="14" height="1.9548" rx="0.977401" transform="rotate(-180 14 2.35547)" fill={fill} />
      <rect x="14" y="10.1748" width="7" height="1.9548" rx="0.977401" transform="rotate(-180 14 10.1748)" fill={fill} />
    </svg>
  );
}

SortDesc.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

SortDesc.defaultProps = {
  width: 14,
  height: 11,
  fill: '#6177FF',
};

export default React.memo(SortDesc);
