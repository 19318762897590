import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import styles from './CertificateProgressBar.module.scss';

const ProgressBar = ({ total, current, isSignLoading }) => {
  const totalLength = total.length;
  const currentLength = current.length;
  const percent = (100 / totalLength) * currentLength;

  const getText = useMemo(() => {
    if (isSignLoading && currentLength) {
      const lastDocId = current[current.length - 1];
      const currentDoc = total.find((el) => get(el, 'additionalFields.ecmUuid', '') === lastDocId);
      if (currentDoc) {
        return `Подписан документ ${currentDoc.description}`;
      }
      return 'Процесс подписания документов';
    }
    if (!isSignLoading && totalLength === currentLength) {
      return 'Все документы подписаны';
    }
    return '';
  }, [isSignLoading, currentLength, totalLength, total]);

  return (
    <>
      {
        totalLength ? (
          <div className={styles.wrapper}>
            <div className={styles.bar} style={{ backgroundPosition: `${100 - percent}% 0%` }} />
            <div className={styles.barText}>{getText}</div>
          </div>
        ) : null
      }
    </>
  );
};

ProgressBar.propTypes = {
  total: PropTypes.arrayOf({}),
  current: PropTypes.arrayOf(PropTypes.string),
  isSignLoading: PropTypes.bool,
};

ProgressBar.defaultProps = {
  total: [],
  current: [],
  isSignLoading: false,
};

export default memo(ProgressBar);
