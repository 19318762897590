import { directionCatalogApi, serviceCatalogApi } from '../../utils/helpers/filters';

export const filtersNames = {
  directions: 'serviceDirection',
  partnerService: 'partnerService',
};

export const catalogsApi = {
  [filtersNames.directions]: directionCatalogApi,
  [filtersNames.partnerService]: serviceCatalogApi,
};
