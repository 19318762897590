import { useCallback, useMemo } from 'react';
import { range } from 'lodash';

const PAGINATION_CONSTANTS = {
  siblingsCount: 1,
  boundaryCount: 1,
  ellipsisStart: 'ellipsis_start',
  ellipsisEnd: 'ellipsis_end',
};

const usePagination = ({
  current,
  isLoop,
  total,
  onSelect,
}) => {
  const content = useMemo(
    () => {
      const startPage = 1;

      const siblingsStart = Math.max(
        Math.min(
          current - PAGINATION_CONSTANTS.siblingsCount,
          total - PAGINATION_CONSTANTS.boundaryCount - PAGINATION_CONSTANTS.siblingsCount * 2 - 1,
        ),
        PAGINATION_CONSTANTS.boundaryCount + 2,
      );

      const siblingsEnd = Math.min(
        Math.max(
          current + PAGINATION_CONSTANTS.siblingsCount,
          PAGINATION_CONSTANTS.boundaryCount + PAGINATION_CONSTANTS.siblingsCount * 2 + 2,
        ),
        total - 2,
      );

      const result = [
        startPage,

        // eslint-disable-next-line no-nested-ternary
        ...(siblingsStart > PAGINATION_CONSTANTS.boundaryCount + 2
          ? [PAGINATION_CONSTANTS.ellipsisStart]
          : PAGINATION_CONSTANTS.boundaryCount + 1 < total - PAGINATION_CONSTANTS.boundaryCount
            ? [PAGINATION_CONSTANTS.boundaryCount + 1]
            : []
        ),

        ...(siblingsStart <= siblingsEnd
          ? range(siblingsStart, siblingsEnd + 1)
          : []
        ),

        // eslint-disable-next-line no-nested-ternary
        ...(siblingsEnd < total - PAGINATION_CONSTANTS.boundaryCount - 1
          ? [PAGINATION_CONSTANTS.ellipsisEnd]
          : total - PAGINATION_CONSTANTS.boundaryCount > PAGINATION_CONSTANTS.boundaryCount
            ? [total - PAGINATION_CONSTANTS.boundaryCount]
            : []
        ),

        total,
      ];

      return result.reduce((a, c) => {
        if (typeof c === 'string' || !a.includes(c)) {
          a.push(c);
        }
        return a;
      }, []);
    },
    [current, total],
  );

  const onNextButtonClick = useCallback(() => {
    if (current >= total && !isLoop) {
      return;
    }
    const target = current >= total ? 1 : current + 1;
    onSelect(target);
  }, [isLoop, total, current, onSelect]);

  const onPrevButtonClick = useCallback(() => {
    if (current <= 1 && !isLoop) {
      return;
    }
    const target = current <= 1 ? total : current - 1;
    onSelect(target);
  }, [isLoop, total, current, onSelect]);

  return {
    content,
    onNextButtonClick,
    onPrevButtonClick,
  };
};

export default usePagination;
