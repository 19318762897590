import { nanoid } from 'nanoid';

import PostStatisticTransport from './PostStatisticTransport';
import calculateChecksum from './calculateChecksum';

/**
 * @module RcmLic
 * @class
 * @internal
 */
class RcmLic {
  constructor(options = {}) {
    this.name = 'rcmlic';
    this.loaded = false;
    this.options = options;
    this.eventNames = {
      PAGE_VIEW_EVENT: 'page.display',
      LINK_EVENT: 'event.link',
      REGISTRATION_EVENTS: [
        'registration.step_one',
        'registration.step_two',
        'registration.step_three',
        'registration.step_four',
        'registration.back',
        'registration.access_token',
      ],
      PASSWORD_RECOVERY: [
        'passwordRecovery.email',
      ],
      SERVICE_EVENTS: [
        'service_get',
        'service_order',
      ],
      LOGIN_EVENT: 'login',
      REQUEST_EVENT: 'io.request',
      WIDGET_VIEW_EVENT: 'widget.display',
    };
  }

  static userId() {
    if (process.env.NODE_ENV === 'development') {
      return localStorage.getItem('clientId');
    }
    let mdmId = null;
    if (window.roox_user && window.roox_user.attributes && window.roox_user.attributes.mdm_id) {
      ({ mdm_id: mdmId } = window.roox_user.attributes);
    }
    return mdmId;
  }

  /**
   * @method pageView
   * @param {String} eventName
   * @param {Object} params
   * @returns {Promise}
   * @internal
   */
  pageView(eventName, params) {
    if (!this.options.VIEW_EVENTS_ENABLED) return false;

    return this.track(eventName, params);
  }

  /**
   * @method requestSent
   * @param {String} eventName
   * @param {Object} params
   * @returns {Promise}
   * @internal
   */
  requestSent(eventName, params) {
    if (!this.options.IO_EVENTS_ENABLED) return false;

    return this.track(eventName, params);
  }

  static getGUID() {
    let GUID = localStorage.getItem('GUID');

    if (!GUID) {
      GUID = nanoid(12);
      localStorage.setItem('GUID', GUID);
    }

    return GUID;
  }

  /**
   * @method track
   * @param {String} eventName
   * @param {Object} params
   * @returns {Promise}
   * @internal
   */
  track(eventName, params) {
    if (!this.options.ENABLED) return false;

    return new Promise((resolve, reject) => {
      this.load()
        .then(() => {
          this.trackOperation(eventName, params);
          resolve({ eventName, params });
        })
        .catch((error) => {
          console.error('rcmlic: Failed to initialize', error);
          reject(error);
        });
    });
  }

  /**
   * @method trackOperation
   * @param {String} eventName
   * @param {Object} params
   * @protected
   */
  trackOperation(eventName, params) {
    const requiredParams = {
      uiid: RcmLic.getGUID(),
      iid: RcmLic.getGUID(),
      tz: new Date().getTimezoneOffset(),
      userId: RcmLic.userId(),
      oid: this.options.OPERATOR_ID,
    };
    const operation = {
      name: eventName,
      quantity: 1,
      timeStart: new Date().getTime(),
      timeEnd: new Date().getTime(),
      data: {
        ...requiredParams,
        ...params,
      },
    };

    this.sendSyncReport([operation]);
  }

  /**
   * @method load
   * @protected
   */
  load() {
    if (!this.promise) {
      this.promise = new Promise((resolve) => {
        this.loaded = true;
        resolve();
      });
    }

    return this.promise;
  }

  static calculateParams(type, report) {
    return {
      YA_REPORT_SERVICE: type,
      YA_REPORT_CHECKSUM: calculateChecksum(report),
      YA_REPORT_SENDING_TIME: new Date().toISOString(),
    };
  }

  static getCreateReport(statistic) {
    const report = {
      data: statistic,
    };
    return `${JSON.stringify(report)}\n`;
  }

  sendSyncReport(statEvents) {
    const report = RcmLic.getCreateReport(statEvents);
    const statisticTransport = new PostStatisticTransport('SERVER_ADDRESS', true, this.options);
    const parameters = RcmLic.calculateParams('counter', report);

    if (this.options.LIC_ENABLED) {
      const statisticLicTransport = new PostStatisticTransport('LIC_SERVER_ADDRESS', true, this.options);
      return Promise.all([statisticTransport.sendSyncReport(report, parameters),
        statisticLicTransport.sendSyncReport(report, parameters)]);
    }

    return statisticTransport.sendSyncReport(report, parameters);
  }
}

export default RcmLic;
