import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'elk-uikit';

import styles from './OpenPanel.module.scss';
// import RoleBadge from '../RoleBadge/RoleBadge';
import RoleBadge from '../RoleBadge/RoleBadge';

const OpenPanel = (props) => {
  const { title, rightText, children } = props;

  return (
    <div className={styles.openPanelWrapper}>
      <div className={styles.panelHeader}>
        <Typography
          size="large"
        >
          {title}
        </Typography>
        {rightText && (
          <RoleBadge customClassname={styles.roleWrapper}>
            {rightText}
          </RoleBadge>
        )}
      </div>
      <div className={styles.panelBody}>
        {children}
      </div>
    </div>
  );
};

OpenPanel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  rightText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

OpenPanel.defaultProps = {
  title: '',
  children: null,
  rightText: '',
};

export default memo(OpenPanel);
