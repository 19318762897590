/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function Upload({
  width,
  height,
  fill,
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.3333 2.12622H10.6667C10.2985 2.12622 10 2.42469 10 2.79288C10 3.16106 10.2985 3.45953 10.6667 3.45953H14.6667V15.4595H1.33334V3.45953H5.33334C5.70153 3.45953 6 3.16106 6 2.79288C6 2.42469 5.70153 2.12622 5.33334 2.12622H0.666656C0.298469 2.12622 0 2.42469 0 2.79288V16.1262C0 16.4944 0.298469 16.7929 0.666656 16.7929H15.3333C15.7015 16.7929 16 16.4944 16 16.1262V2.79288C16 2.42469 15.7015 2.12622 15.3333 2.12622Z" fill={fill} />
      <path d="M5.13807 6.32156C4.87773 6.06122 4.4556 6.06122 4.19526 6.32156C3.93491 6.58191 3.93491 7.00403 4.19526 7.26438L7.52859 10.5977C7.5285 10.5976 7.52872 10.5978 7.52859 10.5977C7.54403 10.6131 7.56056 10.628 7.57744 10.6419C7.58516 10.6482 7.59328 10.6537 7.60122 10.6597C7.61069 10.6667 7.61997 10.6741 7.62984 10.6807C7.63934 10.687 7.64919 10.6925 7.65894 10.6983C7.66788 10.7037 7.67659 10.7093 7.68578 10.7142C7.69572 10.7195 7.70591 10.724 7.71606 10.7288C7.72566 10.7333 7.73509 10.7381 7.74491 10.7422C7.75475 10.7463 7.76481 10.7496 7.77481 10.7532C7.78534 10.7569 7.79572 10.761 7.80647 10.7642C7.81647 10.7672 7.82666 10.7695 7.83675 10.772C7.84772 10.7748 7.85856 10.7778 7.86972 10.7801C7.88144 10.7824 7.89325 10.7838 7.90503 10.7855C7.91475 10.7868 7.92431 10.7887 7.93419 10.7897C7.97794 10.794 8.02203 10.794 8.06578 10.7897C8.07563 10.7887 8.08519 10.7869 8.09494 10.7855C8.10672 10.7838 8.11856 10.7824 8.13025 10.7801C8.14144 10.7778 8.15225 10.7748 8.16322 10.772C8.17334 10.7695 8.1835 10.7672 8.1935 10.7642C8.20425 10.761 8.21466 10.7569 8.22516 10.7532C8.23516 10.7496 8.24522 10.7463 8.25506 10.7422C8.26491 10.7382 8.27431 10.7333 8.28391 10.7288C8.29403 10.724 8.30425 10.7195 8.31419 10.7142C8.32337 10.7093 8.33209 10.7037 8.34103 10.6983C8.35078 10.6925 8.36063 10.687 8.37012 10.6807C8.38 10.6741 8.38925 10.6667 8.39875 10.6597C8.40669 10.6537 8.41481 10.6482 8.42253 10.6419C8.43941 10.628 8.45562 10.6134 8.47103 10.598C8.47094 10.5982 8.47116 10.5979 8.47103 10.598L11.8047 7.26438C12.0651 7.00403 12.0651 6.58191 11.8047 6.32156C11.5444 6.06122 11.1222 6.06122 10.8619 6.32156L8.66665 8.51681V1.45963C8.66665 1.09144 8.36819 0.792969 8 0.792969C7.63181 0.792969 7.33335 1.09144 7.33335 1.45963V8.51681L5.13807 6.32156Z" fill={fill} />
    </svg>
  );
}

Upload.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

Upload.defaultProps = {
  width: 16,
  height: 17,
  fill: 'black',
};

export default React.memo(Upload);
