import configAdapter from '../../../config/config';

const API_URL_V0 = '/api/v0';
const DIGITAL_PROFILE_URL = configAdapter('com.rooxteam.widgets.digitalProfile.api.url');

const COMPANIES = '/companies';

export default {
  API_URL_V0,
  DIGITAL_PROFILE_URL,
  // routes
  COMPANIES,
};
