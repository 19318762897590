import React, { memo } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './Document.module.scss';

function Caption({
  text,
  muted,
}) {
  return (
    <span
      className={cn(
        styles.headerTypeDocument,
        styles.spanText,
        { [styles.muted]: muted },
      )}
    >
      {text}
    </span>
  );
}

Caption.propTypes = {
  text: PropTypes.string,
  muted: PropTypes.bool,
};

Caption.defaultProps = {
  text: '',
  muted: false,
};

export default memo(Caption);
