/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function Search({
  width,
  height,
  fill,
  fillOpacity,
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="#DB4444" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.9328 16.7398L22.7539 21.5586C23.082 21.8891 23.082 22.4234 22.7563 22.7539C22.5898 22.918 22.3766 23 22.1586 23C21.9406 23 21.725 22.918 21.5609 22.7539L16.7398 17.9328C15.1812 19.2242 13.182 20 11 20C6.02891 20 2 15.9711 2 11C2 6.02891 6.02891 2 11 2C15.9711 2 20 6.02891 20 11C20 13.182 19.2242 15.1812 17.9328 16.7398ZM11 18.3125C11.9891 18.3125 12.9453 18.1203 13.8453 17.7383C14.7172 17.3703 15.4977 16.843 16.1703 16.1703C16.843 15.4977 17.3703 14.7148 17.7383 13.8453C18.1203 12.9453 18.3125 11.9891 18.3125 11C18.3125 10.0109 18.1203 9.05469 17.7383 8.15469C17.3703 7.28516 16.843 6.50234 16.1703 5.82969C15.4977 5.15703 14.7148 4.62969 13.8453 4.26172C12.9453 3.87969 11.9891 3.6875 11 3.6875C10.0109 3.6875 9.05469 3.87969 8.15469 4.26172C7.28516 4.62969 6.50234 5.15703 5.82969 5.82969C5.15703 6.50234 4.62969 7.28516 4.26172 8.15469C3.87969 9.05469 3.6875 10.0109 3.6875 11C3.6875 11.9891 3.87969 12.9453 4.26172 13.8453C4.62969 14.7148 5.15703 15.4977 5.82969 16.1703C6.50234 16.843 7.28516 17.3703 8.15469 17.7383C9.05469 18.1203 10.0109 18.3125 11 18.3125Z" fill={fill} fillOpacity={fillOpacity} />
    </svg>
  );
}

Search.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  fillOpacity: PropTypes.string,
};

Search.defaultProps = {
  width: 24,
  height: 24,
  fill: 'black',
  fillOpacity: '0.25',
};

export default React.memo(Search);
