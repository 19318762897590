import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './index.module.scss';
import Icons from '../Icons';

const Stepper = ({ steps, currentStep }) => (
  <div className={styles.container}>
    {steps.map((step, index) => (
      <>
        <div
          key={step.id}
          className={
            cn(styles.step, {
              [styles.current]: step.id === currentStep,
              [styles.passed]: step.isPassed,
            })
          }
          onClick={() => {}}
        >
          <div>
            <Icons.Circle
              stroke={step.isPassed ? '#1890FF' : '#BFBFBF'}
            />
          </div>
          <div
            className={
             cn(styles.stepName, {
              [styles.current]: step.id === currentStep,
              [styles.passed]: step.isPassed,
             })
            }
          >
            {step.label}
          </div>
        </div>
        {(steps.length !== (index + 1)) && (
          <div
            className={
             cn(styles.interSteps, {
              [styles.passed]: step.isPassed,
             })
            }
          />
        )}
      </>
    ))}
  </div>
);

Stepper.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    label: PropTypes.string,
    isPassed: PropTypes.bool,
    isFailed: PropTypes.bool,
    isDisabled: PropTypes.bool,
  })).isRequired,
};

export default React.memo(Stepper);
