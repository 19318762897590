import { eventChannel, END } from 'redux-saga';
import axios from 'axios';
import { put, take, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { getCookie, i18n } from '../../../utils';
import actions from './actions';

const {
  locale: { acceptedLanguages },
} = i18n;
const REFRESH_PROCESS_ERRORS_CODES = [500, 504];

function uploadEmitter({ camundaId, url, file }) {
  let emit;
  const chan = eventChannel((emitter) => {
    emit = emitter;
    return () => {
    };
  });
  const token = getCookie('digital_mp_at');
  const Authorization = `Bearer sso_1.0_${token}`;
  const promise = axios({
    method: 'POST',
    url,
    headers: {
      'Accept-Language': acceptedLanguages,
      Authorization,
      camundaId,
    },
    data: file.formData,
    onUploadProgress(progressEvent) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total,
      );
      emit({ percentCompleted, fileId: file.id });
      if (percentCompleted === 100) {
        emit(END);
      }
    },
  });
  return [promise, chan];
}

function* progressListener(chan) {
  while (true) {
    const { percentCompleted, fileId } = yield take(chan);
    const { filesUploadingStatuses } = yield select(
      (state) => state.ServiceProfile,
    );
    const updatedStatuses = {
      uploading: filesUploadingStatuses.uploading,
      name: filesUploadingStatuses.name,
      statuses: filesUploadingStatuses.statuses.map((item) => {
        if (item.id === fileId) {
          return {
            id: item.id,
            loadPercent: percentCompleted,
          };
        }
        return item;
      }),
    };

    yield put(actions.handleFilesUploadingStatuses(updatedStatuses));
  }
}

function* checkCredentials(taskId, camundaId) {
  const token = getCookie('digital_mp_at');
  if (!taskId || !token || !camundaId) {
    yield put(
      actions.errorHandlerServices({
        hasError: true,
        type: 'no credentials',
      }),
    );
    return false;
  }
  return true;
}

function getSubmitType(step) {
  if (step === 'FINISH') return 'NEXT';
  return step;
}

function checkTaskAttachments(data) {
  const fields = get(data, 'uiSchema.fields', false);
  const attachlist = get(data, 'variables.attachList', false);
  if (!fields && !attachlist) return false;

  return (
    Object.values(fields).some(
      (item) => item.uiType === 'fileLink'
        || item.type === 'File'
        || item.uiType === 'documentForSign',
    ) || attachlist
  );
}

const getFormFile = ({
  id,
  name,
  contentType,
  url,
  size,
  description,
  additionalFields,
}, camundaId) => ({
  id,
  name,
  file: {
    name: description,
    size,
    url,
    additionalFields,
    contentType,
    requestConfig: { headers: { camundaId } },
  },
});

function parseAttachments({ formFields, taskAttachments, camundaId }) {
  const attachedFiles = {};
  const attachedFilesIds = [];
  const ecmUuids = [];
  taskAttachments.forEach((attachment) => {
    const additionalFields = [
      'docsOfTheExhibitedProducts',
      'photoOfTheExhibitedProducts',
      'docsOfDirectManufacturerProducts-yes',
      'docsOfDirectManufacturerProducts-no',
    ];
    const {
      id,
      name,
      description,
    } = attachment;
    if (additionalFields.includes(name) && description) {
      const formFile = getFormFile(attachment, camundaId);
      attachedFilesIds.push(id);
      attachedFiles[name] = [...(attachedFiles[name] || []), formFile];
    }
  });
  const documentsForSignCounter = taskAttachments.reduce((acc, attach) => {
    const ecmUuid = get(attach, 'additionalFields.ecmUuid', null);
    if (ecmUuid && !ecmUuids.includes(ecmUuid) && Number(attach.isexist)) {
      ecmUuids.push(ecmUuid);
      return acc + 1;
    }
    return acc;
  }, 0);

  const { documentsWithSign } = taskAttachments.reduce(
    (acc, field) => {
      const ecmUuid = get(field, 'additionalFields.ecmUuid', null);

      if (ecmUuid && !acc.ids.includes(ecmUuid)) {
        acc.documentsWithSign.push(field);
        acc.ids.push(ecmUuid);
      }
      return acc;
    },
    {
      documentsWithSign: [],
      ids: [],
    },
  );

  const updatedFields = formFields.reduce((acc, [key, field]) => {
    acc[key] = { ...field };

    if (field.uiType === 'fileLink' || field.uiType === 'documentForSign') {
      const keyForSearch = field.fileKeys || key;
      const attachmentsFromBpmn = taskAttachments.reduce(
        (currentAttachments, attachment) => {
          const condition = Array.isArray(keyForSearch)
            ? keyForSearch.includes(attachment.name)
            : attachment.name === key;

          if (condition) {
            const {
              description: fileName,
              size: fileSize,
              url: fileUrl,
              additionalFields,
              contentType,
              id,
            } = attachment;

            currentAttachments.push({
              fileName,
              fileSize,
              fileUrl,
              additionalFields,
              id,
              contentType,
              requestConfig: {
                headers: {
                  camundaId,
                },
              },
            });
          }
          return currentAttachments;
        },
        [],
      );
      const staticAttachments = get(field, 'attachments', []);

      acc[key].attachments = [...staticAttachments, ...attachmentsFromBpmn];
    }

    if (field.type === 'File') {
      taskAttachments.filter(({ name, description }) => name === key && description)
        .forEach((attachment) => {
          const { id, name } = attachment;
          const formFile = getFormFile(attachment, camundaId);
          attachedFilesIds.push(id);
          attachedFiles[name] = [...(attachedFiles[name] || []), formFile];
        });
    }
    return acc;
  }, {});

  return {
    updatedFields,
    attachedFiles,
    attachedFilesIds,
    documentsForSignCounter,
    documentsWithSign,
  };
}

function getFilesFormData(files) {
  return files.reduce((acc, { name, id, file }) => {
    const formData = new FormData();
    formData.append('description', file.name);
    formData.append('file', file);
    formData.append('name', name);
    acc.push({ id, formData });
    return acc;
  }, []);
}

function checkErrorResponseCode({ response }) {
  return REFRESH_PROCESS_ERRORS_CODES.includes(response?.status);
}

export {
  uploadEmitter,
  progressListener,
  checkCredentials,
  getSubmitType,
  checkTaskAttachments,
  parseAttachments,
  getFilesFormData,
  checkErrorResponseCode,
};
