import constants from './constants';

const {
  ZVAT_ROUTE,
  PROCESSES,
} = constants;

const getProcessAttachmentsPrintFnsUrl = (processId, attachmentId) => `${ZVAT_ROUTE}${PROCESSES}/${processId}/attachments/${attachmentId}/print`;

export default {
  getProcessAttachmentsPrintFnsUrl,
};
