import { lazy } from 'react';

import store from '../../store/store';

const LoadPage = (pageName, subPage) => lazy(() => import(`../../pages/${pageName}/effects`)
  .then((module) => {
    if (subPage) {
      store.injectReducer(pageName, module.reducer);
      store.injectSaga(pageName, module.sagas);
      return import(`../../pages/${pageName}/components/${subPage}`);
    }
    store.injectReducer(pageName, module.reducer);
    store.injectSaga(pageName, module.sagas);

    return import(`../../pages/${pageName}`);
  }));

const loadReducerAndSaga = (pageName) => import(`../../pages/${pageName}/effects`)
  .then((module) => {
    store.injectReducer(pageName, module.reducer);
    store.injectSaga(pageName, module.sagas);
  });

export {
// eslint-disable-next-line
  LoadPage,
  loadReducerAndSaga,
};
