/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import theme from '../../styles/theme';

const { darkGrey } = theme;

function Point({
  width,
  height,
  fill,
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 6 6" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <circle cx="3" cy="3" r="3" />
    </svg>
  );
}

Point.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

Point.defaultProps = {
  width: 6,
  height: 6,
  fill: darkGrey,
};

export default React.memo(Point);
