import React, {} from 'react';
import { Typography, OpenPanel, KrButton } from 'elk-uikit';
import { PageContainer, Button } from '../../components';

import styles from './index.module.scss';

const Support = () => (
  <PageContainer
    title='Служба поддержки пользователей портала «Мой экспорт»'
    classNameHeaderWrapper={styles.headerWrapper}
    actionBlock={(
      <div className={styles.button}>
        <Button
          color='dodgerBlue'
          onClick={() => window.open('https://myexport.exportcenter.ru/support/', '_blank', 'noopener, noreferrer')}
        >
          Отправить обращение в поддержку
        </Button>
      </div>
    )}
  >
    <div className={styles.contectWrapper}>
      <OpenPanel
        className={styles.panelContainer}
        title="Контакты"
      >
        <div className={styles.contactWrapper}>
          <div className={styles.phone}>
            <Typography variant="body" className={styles.number}>
              Телефон:
              {' '}
              <a href="tel:+74959670737">+7 (495) 967-0737</a>
            </Typography>
          </div>
          <div className={styles.mail}>
            <Typography variant="body" className={styles.address}>
              Электронная почта:
              {' '}
              <a href="mailto:ask@exportcenter.ru">ask@exportcenter.ru</a>
            </Typography>
          </div>
        </div>
      </OpenPanel>
      <OpenPanel
        className={styles.panelContainer}
        title="Инструкции"
      >
        <div className={styles.instructionButton}>
          <KrButton
            isLink
            onClick={() => window.open('https://market-search.exportcenter.ru/media/Инструкция по ЛК.pdf', '_blank', 'noopener, noreferrer')}
          >
            Инструкция по работе в Личном кабинете
          </KrButton>
          <KrButton
            isLink
            onClick={() => window.open('https://market-search.exportcenter.ru/media/Аккредитация.pdf', '_blank', 'noopener, noreferrer')}
          >
            Инструкция по работе с Аккредитацией
          </KrButton>
          <KrButton
            isLink
            onClick={() => window.open('https://market-search.exportcenter.ru/media/Профиль_компании.pdf', '_blank', 'noopener, noreferrer')}
          >
            Инструкция по заполнению профиля
          </KrButton>
        </div>
      </OpenPanel>
    </div>
  </PageContainer>
);

export default Support;
