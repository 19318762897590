import {
  put,
  call,
} from 'redux-saga/effects';

import {
  sagaGenerator,
} from '../../../../store/helpers';
import {
  getCookie,
  mdmApi,
} from '../../../../utils';
import actions from '../actions';

const {
  getProducts,
  getProductsSuccess,
  errorHandlerServices,
  getCatalog,
  getCatalogSuccess,
} = actions;

// helpers
const getAuthHeader = () => {
  const token = getCookie('digital_mp_at');
  return `Bearer sso_1.0_${token}`;
};
const HANDLERS = {
  * [getProducts]({ payload }) {
    try {
      const Authorization = getAuthHeader();
      const { data } = yield call(mdmApi.adapter, {
        method: 'post',
        url: `${mdmApi.utils.generateMDMRoute('/catalogs/org_goods/items/search?showDetails=1&showRefs=1&size=10')}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization,
        },
        data: payload || {},
      });
      yield put(getProductsSuccess(data.content));
    } catch (error) {
      yield put(errorHandlerServices(error));
    }
  },
  * [getCatalog]({ payload }) {
    try {
      const {
        reqData,
        catalog,
      } = payload;
      const path = `/catalogs/${catalog}/items/search?showDetails=1&showRefs=1&size=10`;
      const Authorization = getAuthHeader();
      const { data } = yield call(mdmApi.adapter, {
        method: 'post',
        url: `${mdmApi.utils.generateMDMRoute(path)}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization,
        },
        data: reqData || {},
      });
      yield put(getCatalogSuccess(data.content));
    } catch (error) {
      yield put(errorHandlerServices(error));
    }
  },
};

export default sagaGenerator(HANDLERS);
