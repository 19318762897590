/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import theme from '../../styles/theme';

function Refresh({
  width,
  height,
  fill,
}) {
  return (
    <svg
      width={width}
      height={height}
      fill={fill}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 1.5C5.28256 1.5 2.95287 3.16778 1.98205 5.53848C1.82508 5.92179 1.38709 6.10528 1.00377 5.94831C0.620452 5.79134 0.436962 5.35335 0.593933 4.97003C1.7875 2.0554 4.65283 0 8 0C10.6785 0 13.0484 1.31616 14.5 3.33555V0.75C14.5 0.335786 14.8358 0 15.25 0C15.6642 0 16 0.335786 16 0.75V5.25C16 5.66421 15.6642 6 15.25 6H14.7936C14.739 6.006 14.6845 6.00587 14.6309 6H10.75C10.3358 6 10 5.66421 10 5.25C10 4.83579 10.3358 4.5 10.75 4.5H13.4784C12.3228 2.69509 10.3003 1.5 8 1.5ZM14.995 10.0559C15.3782 10.2131 15.5615 10.6512 15.4043 11.0344C14.2096 13.9467 11.3455 16 8 16C5.32154 16 2.95163 14.6838 1.5 12.6645V15.25C1.5 15.6642 1.16421 16 0.75 16C0.335786 16 0 15.6642 0 15.25V10.75C0 10.3358 0.335786 10 0.75 10H5.25C5.66421 10 6 10.3358 6 10.75C6 11.1642 5.66421 11.5 5.25 11.5H2.52162C3.67718 13.3049 5.69966 14.5 8 14.5C10.7161 14.5 13.0448 12.8339 14.0165 10.4651C14.1737 10.0819 14.6118 9.89867 14.995 10.0559Z" />
    </svg>
  );
}

Refresh.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

Refresh.defaultProps = {
  width: 16,
  height: 16,
  fill: theme.blueNormal,
};

export default memo(Refresh);
