import moment from 'moment';
import uidmApi from './api/uidmApi';
import mdmApi from './api/mdmApi';
import ecmApi from './api/ecmApi';
import notifyApi from './api/notifyApi';
import bpmnApi from './api/bpmnApi';
import orgApi from './api/orgApi';
import tasksApi from './api/tasksApi';
import zvatApi from './api/zvatApi';
import digitalProfileApi from './api/digitalProfileApi';
import dadataApi from './api/dadataApi';
import metricsConfig from './metrics/metrics.config';
import i18n from './i18nConfig';
import hooks from './hooks';
import * as date from './date';

const PROCESS_STATUS_DRAFT = 0;
const PROCESS_STATUS_DRAFT1 = 1;
const PROCESS_STATUS_SECOND = 2;
const PROCESS_STATUS_THIRD = 3;
const PROCESS_STATUS_FOURTH = 4;
const PROCESS_STATUS_FIFTH = 5;
const PROCESS_STATUS_SIXTH = 6;
const PROCESS_STATUS_EXPORT_ACCELERATOR = 7;
const PROCESS_STATUS_ARCHIVE_OR_CANCEL = 9;

const { getFormattedMessage } = i18n;

const getWidthFromStep = (currentStep, maxSteps) => {
  if (currentStep >= maxSteps) return 100;
  return ((100 * currentStep) / maxSteps);
};

const encodeSocialParams = (params) => {
  let result = '';
  // eslint-disable-next-line no-restricted-syntax,no-unused-vars
  for (const param in params) {
    // eslint-disable-next-line no-prototype-builtins
    if (params.hasOwnProperty(param)) {
      result += `${param}=${params[param]}&`;
    }
  }
  result = result.substr(0, result.length - 1);
  return encodeURIComponent(result);
};

const getCookie = (name) => {
  const matches = document.cookie.match(new RegExp(
    `(?:^|; )${name.replace(/([.$?*|{}()[]\\\/\+\^])/g, '\\$1')}=([^;]*)`,
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

const goToNextField = (name, valuesNextFields, charCode, byCharCode = 13) => {
  // by press focus on next input
  if (charCode === byCharCode) {
    const nextField = valuesNextFields[name];
    if (!nextField) return;
    const refNextField = document.getElementById([nextField]);
    if (!refNextField) return;
    refNextField.focus();
  }
};

const blurCurrentField = (name, charCode, byCharCode = 13) => {
  // by press blur from current input
  if (charCode === byCharCode) {
    const refField = document.getElementById([name]);
    if (!refField) return;
    refField.blur();
  }
};

const formatDate = (value) => moment(value).locale('ru').format('LL');

const formatBytes = (bytes, decimals) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals <= 0 ? 0 : decimals || 2;
  const sizes = ['байт', 'Кб', 'Мб', 'Гб', 'Tб', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};

const validateEmail = (value) => {
  const reg = /^[\w\\.\d-_]+@[\w\\.\d-_]+\.\w{2,4}$/i;
  return reg.test(value.trim());
};

const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

const formatPhone = (formatValue) => {
  const firstPart = formatValue.slice(0, 3);
  const secPart = formatValue.slice(3, 6);
  const thirdPart = formatValue.slice(6, 8);
  const fourthPart = formatValue.slice(8, 10);
  return `+7 (${firstPart}) ${secPart}-${thirdPart}-${fourthPart}`;
};

const errorSwitch = (errorMessage, defaultNameLocale) => {
  switch (errorMessage) {
  case 'login-exists':
    return getFormattedMessage('error.loginExist', defaultNameLocale);
  case '{organization_invalid_name}':
    return getFormattedMessage('error.organizationInvalid', defaultNameLocale);
  default:
    return getFormattedMessage('error.default', defaultNameLocale);
  }
};

const shortDateFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

// eslint-disable-next-line no-shadow
const getDate = (date) => (date ? new Date(date).toLocaleString(i18n.locale.name, shortDateFormatOptions) : null);
const filterServicesByIsDisplayInElk = (services) => services.filter(({ isDisplayInElk = true }) => isDisplayInElk);

const filterServices = (services) => {
  const draftsStatuses = [
    PROCESS_STATUS_DRAFT,
    PROCESS_STATUS_DRAFT1,
    PROCESS_STATUS_SECOND,
    PROCESS_STATUS_THIRD,
    PROCESS_STATUS_FOURTH,
    PROCESS_STATUS_FIFTH,
    PROCESS_STATUS_SIXTH,
  ];
  const archiveStatuses = [PROCESS_STATUS_ARCHIVE_OR_CANCEL];
  const acceleratorStatuses = [PROCESS_STATUS_EXPORT_ACCELERATOR];

  const filterServicesByCondition = (list, condition) => list.filter(
    ({ processStatus }) => condition.includes(processStatus),
  );

  return {
    all: services, // все
    drafts: filterServicesByCondition(services, draftsStatuses), // в процессе
    accelerator: filterServicesByCondition(services, acceleratorStatuses), // аксселератор
    archive: filterServicesByCondition(services, archiveStatuses), // архивные
  };
};

function declOfNum(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
}

const ALLOWED_TAGS = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol',
  'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div', 'span',
  'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'img', 'details', 'summary'];

const NON_TEXT_TAGS = ['script', 'textarea', 'noscript'];

const HISTORY_BUTTON_STEP = ['1', 'reply', 'report_create', 'esc_request'];

export {
  date,
  getCookie,
  formatDate,
  formatBytes,
  validateEmail,
  filterServices,
  filterServicesByIsDisplayInElk,
  IS_DEVELOPMENT,
  uidmApi,
  mdmApi,
  ecmApi,
  notifyApi,
  bpmnApi,
  tasksApi,
  zvatApi,
  orgApi,
  digitalProfileApi,
  i18n,
  goToNextField,
  blurCurrentField,
  getWidthFromStep,
  errorSwitch,
  metricsConfig,
  dadataApi,
  encodeSocialParams,
  formatPhone,
  getDate,
  hooks,
  declOfNum,
  ALLOWED_TAGS,
  NON_TEXT_TAGS,
  HISTORY_BUTTON_STEP,
};
