import React, { memo } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import StarRatings from 'react-star-ratings';
import cn from 'classnames';

import { Typography } from 'elk-uikit';

import theme from '../../../../styles/theme';
import styles from '../../Raiting.module.scss';

const starSize = {
  default: '24px',
  small: '16px',
};

const starSpacing = {
  default: '3px',
  small: '1px',
};

const ReitingItems = ({ raitings, count }) => (
  <div className={styles.raitingItemWrapper}>
    {
      raitings.map(({
        raiting, description, size = 'default', star = '',
      }) => (
        <div key={`${raiting}-${description}`} className={styles.raitingBlock}>
          <Typography className={cn(styles.raitingDescription, { [styles[size]]: true })}>{description}</Typography>
          <div className={cn(styles.raitingStars, { [styles[size]]: true })}>
            <Typography
              className={cn(styles.raitingCount, { [styles[size]]: true })}
            >
              {raiting.toFixed(2)}
            </Typography>
            <StarRatings
              rating={raiting}
              numberOfStars={count}
              starRatedColor={theme.dodgerBlue}
              starEmptyColor={theme.boticelli}
              starDimension={starSize[star || size]}
              starSpacing={starSpacing[size]}
            />
          </div>
        </div>
      ))
    }
  </div>
);

ReitingItems.propTypes = {
  raitings: PropTypes.arrayOf({
    raiting: PropTypes.number,
    description: PropTypes.string,
    size: PropTypes.oneOf('default', 'small'),
  }).isRequired,
  count: PropTypes.number,
};

ReitingItems.defaultProps = {
  count: 5,
};

export default memo(ReitingItems);
