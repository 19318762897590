import constants from './constants';

const {
  API_URL_V1,
  API_URL_V2,
} = constants;

const generateMDMRoute = (path, isV2) => `${isV2 ? API_URL_V2 : API_URL_V1}${path}`;

const orAnd = (data) => ({
  and: [{
    or: [{
      and: [data],
    }],
  }],
});
const andAnd = (data) => ({
  and: [{
    and: [{
      and: [data],
    }],
  }],
});
export default {
  generateMDMRoute,
  orAnd,
  andAnd,
};
