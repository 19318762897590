import { reducerGenerator } from '../helpers';
import actions from '../actions/passwordRecovery';

const {
  handleChangePasswordRecoveryEmailInput,
  changePasswordRecoveryStep,
  handleSendPasswordRecoveryOtpCodeStatus,
  errorHandler,
  sendPasswordRecoveryOtpCodeSuccess,
  resetState,
  startPasswordRecoveryRequest,
  startPasswordRecoverySuccess,
  passwordRecoveryRequest,
  forcePasswordRecoveryRequest,
  passwordRecoverySuccess,
  sendAddressPasswordRecoverySuccess,
  sendAddressPasswordRecoveryError,
} = actions;

export const initialState = {
  email: '',
  step: 'FIRST',
  code: null,
  codeSending: false,
  emailSending: false,
  error: null,
  execution: '',
  form: null,
  loadingSendingPassword: false,
  defaultNameLocale: 'passwordRecovery',
};

const HANDLERS = {
  [handleChangePasswordRecoveryEmailInput]: (state, payload) => ({
    ...state,
    email: payload,
  }),
  [changePasswordRecoveryStep]: (state, payload) => ({
    ...state,
    step: payload,
  }),
  [handleSendPasswordRecoveryOtpCodeStatus]: (state, payload) => ({
    ...state,
    codeSending: payload,
  }),
  [errorHandler]: (state, payload) => ({
    ...state,
    error: payload,
  }),
  [sendPasswordRecoveryOtpCodeSuccess]: (state, { execution, form }) => ({
    ...state,
    step: 'THIRD',
    error: initialState.error,
    execution,
    form,
  }),
  [resetState]: () => ({
    ...initialState,
  }),
  [startPasswordRecoveryRequest]: (state) => ({
    ...state,
    emailSending: true,
  }),
  [startPasswordRecoverySuccess]: (state, payload) => ({
    ...state,
    execution: payload,
  }),
  [sendAddressPasswordRecoverySuccess]: (state, payload) => ({
    ...state,
    execution: payload,
    emailSending: false,
  }),
  [sendAddressPasswordRecoveryError]: (state) => ({
    ...state,
    emailSending: false,
  }),
  [passwordRecoveryRequest]: (state) => ({
    ...state,
    error: initialState.error,
    loadingSendingPassword: true,
  }),
  [forcePasswordRecoveryRequest]: (state) => ({
    ...state,
    loadingSendingPassword: true,
    error: initialState.error,
  }),
  [passwordRecoverySuccess]: (state, payload) => ({
    ...state,
    execution: payload,
    loadingSendingPassword: false,
  }),
  ...initialState,
};

export default reducerGenerator(initialState, HANDLERS);
