import {
  put,
  call,
  select,
  all, takeEvery,
} from 'redux-saga/effects';

import {
  getCookie,
  mdmApi,
} from '../../utils';
import configAdapter from '../../config/config';
import actions from '../actions/accreditationAccesses';

const {
  getAccessesEtpAccCreationNational,
  getAccessesEtpAccCreationNationalSuccess,
  getAccessesEtpAccCreationNationalError,
  getPartnerAccreditations,
  getPartnerAccreditationsSuccess,
  getPartnerAccreditationsError,
  getAccessesAffilates,
  getAccessesAffilatesSuccess,
  getAccessesAffilatesError,
} = actions;

const { orAnd } = mdmApi.utils;
const {
  CATALOGS,
  SEARCH,
  PARTNER_ACCREDITATIONS,
} = mdmApi.constants;

const headers = {
  Authorization: `Bearer sso_1.0_${getCookie('digital_mp_at')}`,
  Accept: 'application/json, text/plain, */*',
  'Accept-Encoding': 'gzip, deflate',
  Connection: 'keep-alive',
  'Content-Type': 'application/json',
};

export const HANDLERS = {
  * [getAccessesEtpAccCreationNational]() {
    try {
      const ETP_ACC_CREATION_NATIONAL = configAdapter('com.rooxteam.widgets.partner_services.etp_acc_creation_national') || '5ea06d20-c38c-46d2-a28b-ea830ade2fcc';
      const PAYMENT_DECISION = configAdapter('com.rooxteam.widgets.paymentAccDecision');

      const { currentOrgId } = yield select((state) => ({
        currentOrgId: state.auth.data.currentOrgId,
      }));

      const reqParams = () => ({
        method: 'POST',
        url: mdmApi.utils.generateMDMRoute(`${CATALOGS}${PARTNER_ACCREDITATIONS}/${SEARCH}`, false),
        headers,
        params: {
          showRefs: 1,
          showDetails: 0,
          size: 1,
          hideDepricated: true,
        },
        data: orAnd({
          partner: currentOrgId,
          'partnerService.uuid_like_ci': ETP_ACC_CREATION_NATIONAL,
          'partnerProfile.isHeadPartner': true,
          decision_in: PAYMENT_DECISION,
        }),
      });
      const { data } = yield call(mdmApi.adapter, reqParams());
      const isAccess = Boolean(data?.content?.length);
      yield put(getAccessesEtpAccCreationNationalSuccess(isAccess));
    } catch (error) {
      yield put(getAccessesEtpAccCreationNationalError(error));
    }
  },
  * [getAccessesAffilates]() {
    try {
      const ETP_ACC_CREATION_NATIONAL = configAdapter('com.rooxteam.widgets.partner_services.etp_acc_creation_national') || '5ea06d20-c38c-46d2-a28b-ea830ade2fcc';
      const AFFILATES_DECISION = configAdapter('com.rooxteam.widgets.partner_accreditation_decision');

      const { currentOrgId } = yield select((state) => ({
        currentOrgId: state.auth.data.currentOrgId,
      }));

      const reqParams = () => ({
        method: 'POST',
        url: mdmApi.utils.generateMDMRoute(`${CATALOGS}${PARTNER_ACCREDITATIONS}/${SEARCH}`, false),
        headers,
        params: {
          showRefs: 1,
          showDetails: 0,
          size: 1,
          hideDepricated: true,
        },
        data: orAnd({
          partner: currentOrgId,
          'partnerService.uuid_like_ci': ETP_ACC_CREATION_NATIONAL,
          'partnerProfile.isHeadPartner': true,
          decision_in: AFFILATES_DECISION,
        }),
      });
      const { data } = yield call(mdmApi.adapter, reqParams());
      const isAccess = Boolean(data?.content?.length);
      yield put(getAccessesAffilatesSuccess(isAccess));
    } catch (error) {
      yield put(getAccessesAffilatesError(error));
    }
  },
  * [getPartnerAccreditations]() {
    try {
      const { currentOrgId } = yield select((state) => ({
        currentOrgId: state.auth.data.currentOrgId,
      }));

      const reqParams = () => ({
        method: 'POST',
        url: mdmApi.utils.generateMDMRoute(`${CATALOGS}${PARTNER_ACCREDITATIONS}/${SEARCH}`, false),
        headers,
        params: {
          showRefs: 1,
          showDetails: 0,
          size: 1,
          hideDepricated: true,
        },
        data: orAnd({
          partner: currentOrgId,
        }),
      });

      const { data } = yield call(mdmApi.adapter, reqParams());
      const hasAccred = Boolean(data?.content?.length);
      yield put(getPartnerAccreditationsSuccess(hasAccred));
    } catch (error) {
      yield put(getPartnerAccreditationsError(error));
    }
  },
};

export default function* sagaReducer() {
  const sagas = Object
    .keys(HANDLERS)
    .reduce((acc, key) => {
      if (Object.prototype.hasOwnProperty.call(HANDLERS, key)) {
        acc.push(takeEvery(key, HANDLERS[key]));
      }
      return acc;
    }, []);
  yield all([...sagas]);
}
