import axios from 'axios';
import calculateChecksum from './calculateChecksum';
import deepFilterWithConfig from './filterTools';

class PostStatisticTransport {
  constructor(serverAddressKeyOverride, useFiltersOverride, config = {}) {
    this.serverAddressKey = serverAddressKeyOverride;
    this.useFilters = useFiltersOverride || false;
    this.config = config;
    this.statistic = null;
    this.params = null;
    this.content = null;
  }

  getReport() {
    return this.content;
  }

  getParameters() {
    return this.params ? this.params : {};
  }

  isRcmLicUrl() {
    const url = this.config[this.serverAddressKey || 'SERVER_ADDRESS'];
    return url === this.config.LIC_SERVER_ADDRESS;
  }

  filterReport(data, params) {
    const filterConfig = {
      OBFUSCATED_DATA: this.isRcmLicUrl() ? this.config.LIC_OBFUSCATED_DATA : this.config.OBFUSCATED_DATA,
    };
    const parameters = params;
    let content = data;

    if (this.useFilters) {
      content = deepFilterWithConfig(content, filterConfig);

      if (typeof content !== 'string' && content && typeof content.data === 'string') {
        if (content.error) {
          parameters[this.config.FILTERING_ERROR_PARAMETER] = content.error;
        }

        content = content.data;
      }

      parameters.YA_REPORT_CHECKSUM = calculateChecksum(content);
    }

    return { content, parameters };
  }

  static proccessServerAddress(address) {
    let serverAddress = address;

    if (serverAddress && serverAddress.substr(0, 2) === '//') {
      serverAddress = document.location.protocol + address;
    }

    return serverAddress;
  }

  sendReport(data, parameters, successCallback, errorCallback, forceContent) {
    const serverAddressKey = this.serverAddressKey || 'SERVER_ADDRESS';
    const url = PostStatisticTransport.proccessServerAddress(this.config[serverAddressKey]);
    const newParams = { ...parameters, ...this.getParameters() };
    const newData = forceContent ? data : this.getReport();
    const { content, parameters: params } = this.filterReport(newData, newParams);
    const encodeContent = encodeURIComponent(content);

    const generateParamsUrl = (obj) => Object.entries(obj)
      .map(([key, value]) => (`${key}=${encodeURIComponent(value)}`))
      .join('&');

    const encodeParams = generateParamsUrl(params);

    return axios({
      url: `${url}?content=${encodeContent}&${encodeParams}`,
      method: 'GET',
    })
      .then(() => {
        if (typeof successCallback === 'function') {
          successCallback();
        }
      }, () => {
        if (typeof errorCallback === 'function') {
          errorCallback();
        }
      });
  }

  sendSyncReport(...args) {
    const params = [...args];

    params.length = 4;
    params.push(true); // set async=true
    params.push(true); // set forceContent=true

    return this.sendReport(...params);
  }

  static validate() {
    return true;
  }
}

export default PostStatisticTransport;
