/* eslint-disable no-unused-vars */
import {
  put, call, select, all,
} from 'redux-saga/effects';
import get from 'lodash/get';

import { sagaGenerator } from '../../../../store/helpers';
import { getCookie, ecmApi } from '../../../../utils';
// import addNewDocumentTemplate from '../../../../store/sagaTemplates/documents';
import actions, { specActions } from '../actions';

const {
  getCompanyDocumentsFromForm,
  addNewDocumentFromForm,
  getAllDocumentsForSign,
  getOnlyHashesForSign,
  getDocumentForSign,
  signDocument,
  sendDocumentSign,
  checkDocumentSign,
  checkDocumentsSigns,
  checkDocumentContainerSign,
} = actions;

const { DOCUMENT_CONTROLLER } = ecmApi.constants;

const uniqueArray = (a) => [...new Set(a.map((o) => JSON.stringify(o)))].map((s) => JSON.parse(s));

// helpers
const getAuthHeader = () => {
  const token = getCookie('digital_mp_at');
  return `Bearer sso_1.0_${token}`;
};

const getDocumentRequestUrl = (endpoint, docData) => {
  const urlPath = `${DOCUMENT_CONTROLLER}/${docData.documentTypeIdentifier}/items/${docData.id}/${endpoint}`;
  return `${ecmApi.utils.generateECMRoute(urlPath)}`;
};

const HANDLERS = {
  * [getCompanyDocumentsFromForm]({
    payload: {
      type = null,
      value = null,
    },
  }, nsId) {
    try {
      const { currentOrgId = '' } = yield select((state) => state.auth.data);

      if (!currentOrgId) {
        yield put(specActions.errorHandlerServices(nsId, 'Not_found_current_org_id'));
        return;
      }
      const searchData = {
        fields: {
          'orgId.uuid': currentOrgId,
        },
      };

      if (value) searchData.fields.caption = value;
      if (type) {
        searchData.fields[
          'documentTypeIdentifier.documentTypeIdentifier'
        ] = type;
      }

      const size = 4;
      const Authorization = getAuthHeader();

      const { data } = yield call(ecmApi.adapter, {
        method: 'post',
        url: `${ecmApi.utils.generateECMRoute(
          `${DOCUMENT_CONTROLLER}/search?size=${size}`,
        )}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization,
        },
        data: searchData,
      });
      yield put(specActions.getCompanyDocumentsFromFormSuccess(nsId, data.content));
    } catch (error) {
      yield put(specActions.errorHandlerServices(nsId, error));
    }
  },
  * [addNewDocumentFromForm]({ payload: { cb, sourceDocumentUuid } }, nsId = '') {
    const ns = `ServiceProfile${nsId}`;
    try {
      const { documents } = yield select((state) => state[ns].taskContext);
      // const newDocument = yield call(addNewDocumentTemplate);
      const newDocument = {};
      const updatedDocuments = documents.map((document) => {
        if (document.uuid === sourceDocumentUuid) {
          return newDocument;
        }
        return document;
      });

      yield put(specActions.updateFormDocuments(nsId, updatedDocuments));
      setTimeout(cb, 1000);
    } catch (error) {
      yield put(specActions.errorHandlerServices(nsId, error));
    }
  },
  * [getAllDocumentsForSign](_, nsId = '') {
    const ns = `ServiceProfile${nsId}`;
    try {
      const allDocs = yield select((state) => state[ns].documentsSignStep.allDocs);
      const additionalFields = allDocs.map((doc) => doc.additionalFields);
      const uniqAdditionalFields = uniqueArray(additionalFields, 'ecmUuid');

      yield all(
        uniqAdditionalFields.map(({ ecmUuid, documentTypeIdentifier }) => (
          call(HANDLERS[getDocumentForSign], { payload: { id: ecmUuid, documentTypeIdentifier } }, nsId)
        )),
      );

      yield put(specActions.disableSignLoading(nsId));
      yield put(specActions.toggleCertificatesModal(nsId));
    } catch (error) {
      yield put(specActions.disableSignLoading(nsId));
      yield put(specActions.errorHandlerServices(nsId, error));
    }
  },
  // подписание хэша без аттрибутов (для > 30Мб)
  * [getOnlyHashesForSign]({ payload: doc = null }, nsId = '') {
    const ns = `ServiceProfile${nsId}`;
    try {
      const { certificate } = yield select((state) => state[ns]);
      const { currentDocInfo } = yield select((state) => ({
        currentDocInfo: get(state, [ns, 'documentsSignStep', 'currentDocInfo'], null),
      }));

      // get certificate's algorithm
      const publicKey = yield certificate.PublicKey();
      let algorithm = yield publicKey.Algorithm;
      algorithm = yield algorithm.Value;

      // get ECM-files hashes
      const { data: filesHashes } = yield call(ecmApi.adapter, {
        url: getDocumentRequestUrl('attach-hash', currentDocInfo || doc),
        headers: { Authorization: getAuthHeader() },
        params: {
          algorithmAlias: algorithm,
          format: 'HEX',
        },
      });

      // create final data object
      const signData = {
        files: filesHashes,
      };

      // save digests of document and files to the store and go next in signDocument
      yield put(specActions.getDocumentForSignSuccess(nsId, { signData }));
      yield call(HANDLERS[signDocument], {
        payload: {
          skipSignAttributes: true,
          signData,
          doc,
        },
      }, nsId);
    } catch (error) {
      yield put(specActions.disableSignLoading(nsId));
      yield put(specActions.errorHandlerServices(nsId, error));
    }
  },
  * [getDocumentForSign]({ payload: doc = null }, nsId = '') {
    const ns = `ServiceProfile${nsId}`;
    try {
      const { certificate } = yield select((state) => state[ns]);
      const { currentDocInfo } = yield select((state) => ({
        currentDocInfo: get(state, [ns, 'documentsSignStep', 'currentDocInfo'], null),
      }));

      let url = getDocumentRequestUrl('sign-data', currentDocInfo || doc);
      const headers = { Authorization: getAuthHeader() };

      // get ECM-document digest
      const { data: { signData } } = yield call(ecmApi.adapter, {
        url,
        headers,
      });

      // get certificate's algorithm
      const publicKey = yield certificate.PublicKey();
      let algorithm = yield publicKey.Algorithm;

      algorithm = yield algorithm.Value;
      // get ECM-files hashes
      url = getDocumentRequestUrl('attach-hash', currentDocInfo || doc);
      const { data: filesHashes } = yield call(ecmApi.adapter, {
        url,
        headers,
        params: {
          algorithmAlias: algorithm,
          format: 'HEX',
        },
      });

      // create final data object
      const finalSignData = {
        container: signData,
        files: filesHashes,
      };

      // save digests of document and files to the store and go next in signDocument
      yield put(specActions.getDocumentForSignSuccess(nsId, { signData: finalSignData }));
      yield call(HANDLERS[signDocument], { payload: { signData: finalSignData, doc } }, nsId);
    } catch (error) {
      yield put(specActions.disableSignLoading(nsId));
      yield put(specActions.errorHandlerServices(nsId, error));
    }
  },
  * [signDocument]({ payload }, nsId = '') {
    const ns = `ServiceProfile${nsId}`;
    try {
      const { certificate } = yield select((state) => state[ns]);
      const { signData, doc = null, skipSignAttributes = false } = payload;

      const signCades = function* signCadesGenerator(content) {
        const { cadesplugin } = window;
        const { CreateObject, CreateObjectAsync } = cadesplugin;

        let oSigner;
        let oSignedData;

        if (CreateObject) { // Fix for IE 11
          oSigner = yield CreateObject('CAdESCOM.CPSigner');
          oSigner.Certificate = certificate;

          oSignedData = CreateObject('CAdESCOM.CadesSignedData');
          oSignedData.ContentEncoding = cadesplugin.CADESCOM_BASE64_TO_BINARY;
          oSignedData.Content = content;
        } else {
          oSigner = yield CreateObjectAsync('CAdESCOM.CPSigner');
          yield oSigner.propset_Certificate(certificate);

          oSignedData = yield CreateObjectAsync('CAdESCOM.CadesSignedData');
          yield oSignedData.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);
          yield oSignedData.propset_Content(content);
        }

        let signature = yield oSignedData.SignCades(
          oSigner,
          cadesplugin.CADESCOM_CADES_BES,
          true,
        );
        signature = signature.replace(/\r\n/g, '');

        return signature;
      };

      const signHash = function* signHashGenerator(hash, file) {
        const { cadesplugin } = window;
        const { CreateObject, CreateObjectAsync } = cadesplugin;

        // define HashedData object from passed hash value
        let oHashedData;

        if (CreateObject) { // Fix for IE 11
          oHashedData = CreateObject('CAdESCOM.HashedData');
        } else {
          oHashedData = yield CreateObjectAsync('CAdESCOM.HashedData');
        }

        // available and valid algorithms
        const alg3411 = 'CADESCOM_HASH_ALGORITHM_CP_GOST_3411';
        const alg256 = 'CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_256';
        const alg512 = 'CADESCOM_HASH_ALGORITHM_CP_GOST_3411_2012_512';

        const algorithms = {
          [alg3411]: cadesplugin[alg3411],
          [alg256]: cadesplugin[alg256],
          [alg512]: cadesplugin[alg512],
        };

        if (CreateObject) { // Fix for IE 11
          yield oHashedData.Algorithm = algorithms[file.algorithm];
        } else {
          yield oHashedData.propset_Algorithm(algorithms[file.algorithm]);
        }

        yield oHashedData.SetHashValue(hash);

        // create signature
        let oSigner;
        let oSignedData;

        if (CreateObject) { // Fix for IE 11
          oSigner = CreateObject('CAdESCOM.CPSigner');
          oSigner.Certificate = certificate;
          oSignedData = CreateObject('CAdESCOM.CadesSignedData');
        } else {
          oSigner = yield CreateObjectAsync('CAdESCOM.CPSigner');
          yield oSigner.propset_Certificate(certificate);
          oSignedData = yield CreateObjectAsync('CAdESCOM.CadesSignedData');
        }

        let signature = yield oSignedData.SignHash(
          oHashedData,
          oSigner,
          cadesplugin.CADESCOM_CADES_BES,
        );
        signature = signature.replace(/\r\n/g, '');

        return {
          signature,
          ...file,
        };
      };

      let containerSignature;

      // sign ECM-document
      if (!skipSignAttributes) {
        containerSignature = yield* signCades(signData.container);
      }

      // sign ECM-files hashes
      let filesSignatures = signData.files.map((file) => call(signHash, file.hash, file));
      filesSignatures = yield all(filesSignatures);

      yield call(HANDLERS[sendDocumentSign], {
        payload: {
          signatures: {
            container: containerSignature,
            files: filesSignatures,
          },
          doc,
          skipSignAttributes,
        },
      }, nsId);
    } catch (error) {
      yield put(specActions.disableSignLoading(nsId));
      yield put(specActions.errorHandlerServices(nsId, error));
    }
  },
  * [sendDocumentSign]({ payload }, nsId = '') {
    const ns = `ServiceProfile${nsId}`;
    try {
      const { certificate } = yield select((state) => state[ns]);
      const { signatures, doc = null, skipSignAttributes = false } = payload;

      const {
        signData,
        currentDocInfo,
      } = yield select((state) => ({
        signData: get(state, [ns, 'documentsSignStep', 'signData'], ''),
        currentDocInfo: get(state, [ns, 'documentsSignStep', 'currentDocInfo'], null),
      }));

      // configuration
      let url;
      const headers = {
        Authorization: getAuthHeader(),
        'Content-Type': 'application/json',
      };

      if (!skipSignAttributes) {
        // configuration
        url = getDocumentRequestUrl('sign-data', currentDocInfo || doc);
        const data = {
          signData: signData.container,
          signDataExt: signatures.container,
        };

        // send ECM-container signature
        yield call(ecmApi.adapter, {
          url,
          method: 'post',
          headers,
          data,
        });
      }

      // send request for checking signature
      // temporarily commented due to malfunctioning
      // yield call(HANDLERS[checkDocumentSign]);

      // send ECM-files signatures
      const dateFrom = yield certificate.ValidFromDate;
      const dateTo = yield certificate.ValidToDate;
      const successfullySignedDetails = [];

      try {
        const cur = currentDocInfo || doc;
        const res = yield all(signatures.files.map((file) => {
          url = getDocumentRequestUrl(`file/${file.uuid}/sign`, cur);
          return call(ecmApi.adapter, {
            url,
            method: 'post',
            headers,
            data: [{
              active: true,
              createDate: new Date().toISOString(),
              dateFrom,
              dateTo,
              hash: file.hash,
              signData: file.signature,
              signAlgorithm: file.algorithm,
              signDescription: 'clientSign',
            }],
          });
        }));

        signatures.files.forEach((file, i) => res[i]?.data?.filter?.(
          ({ active }) => active,
        ).forEach(
          (sign) => successfullySignedDetails.push({
            documentId: cur.id,
            fileId: file.uuid,
            signId: sign.uuid,
            authorId: sign.authorId,
          }),
        ));
      } catch (error) {
        yield put(specActions.errorHandlerServices(nsId, error));
      }

      if (successfullySignedDetails.length === 0) {
        // по умолчанию всё равно считаем документ подписаным
        successfullySignedDetails.push({
          documentId: doc ? doc.id : currentDocInfo.id,
        });
      }

      yield put(specActions.checkDocumentSignSuccess(nsId, successfullySignedDetails));
      // данный экшен без обработчика
      yield put(specActions.sendDocumentSignSuccess(nsId));

      const allDocs = yield select((state) => state[ns].documentsSignStep.allDocs);

      if (!allDocs.length) {
        yield put(specActions.disableSignLoading(nsId));
        yield put(specActions.toggleCertificatesModal(nsId));
      }
    } catch (error) {
      yield put(specActions.disableSignLoading(nsId));
      yield put(specActions.errorHandlerServices(nsId, error));
    }
  },
  * [checkDocumentSign](_, nsId = '') {
    try {
      const { currentDocInfo } = yield select((state) => (
        get(state, `ServiceProfile${nsId}.documentsSignStep`, {})
      ));

      // configuration
      const url = getDocumentRequestUrl('sign-check', currentDocInfo);
      const headers = { Authorization: getAuthHeader() };

      /**
       * send check signature request
       * should return "validSign: true" in the data object
       */
      const { data } = yield call(ecmApi.adapter, {
        url,
        headers,
      });

      if (data.validSign) {
        yield put(specActions.checkDocumentSignSuccess(nsId, currentDocInfo.id));
      } else throw new Error(data.error);
    } catch (error) {
      yield put(specActions.disableSignLoading(nsId));
      yield put(specActions.errorHandlerServices(nsId, error));
    }
  },
  * [checkDocumentsSigns]({ payload: documentsWithSign }, nsId = '') {
    try {
      const documentsForSignsCalls = documentsWithSign.map((document) => {
        const {
          documentTypeIdentifier,
          ecmUuid,
        } = document.additionalFields;

        return put(specActions.checkDocumentContainerSign(nsId, {
          documentTypeIdentifier,
          ecmUuid,
        }));
      });

      yield all(documentsForSignsCalls);
    } catch (error) {
      yield put(specActions.errorHandlerServices(nsId, {
        hasError: true,
        message: error.message,
        errorFrom: 'checkDocumentsSigns',
      }));
    }
  },
  * [checkDocumentContainerSign]({ payload }, nsId = '') {
    try {
      const {
        documentTypeIdentifier,
        ecmUuid: uuid,
      } = payload;
      const url = ecmApi.utils.generateECMRoute(`/document/${documentTypeIdentifier}/items/${uuid}`);
      const { data = {} } = yield call(ecmApi.adapter, { url });

      if (data.signDataExt) {
        const checkFilesSignUrl = ecmApi.utils.generateECMRoute(
          `/document/${documentTypeIdentifier}/items/${uuid}/file`,
        );
        const { data: { content } } = yield call(ecmApi.adapter, { url: checkFilesSignUrl });
        const successfullySignedDetails = [];
        const isAllFilesSigned = content.every((file) => {
          if (file.signs) {
            const active = file.signs.filter((sign) => sign.active);
            active.forEach((sign) => successfullySignedDetails.push({
              documentId: uuid,
              fileId: file.uuid,
              signId: sign.uuid,
              authorId: sign.authorId,
            }));
            return active.length > 0;
          }
          return false;
        });

        if (isAllFilesSigned) {
          yield put(specActions.checkDocumentSignSuccess(nsId, successfullySignedDetails));
        }
      }
    } catch (error) {
      yield put(specActions.errorHandlerServices(nsId, {
        hasError: true,
        message: error.message,
        errorFrom: 'checkDocumentContainerSign',
      }));
    }
  },
};

export default sagaGenerator(HANDLERS);
