import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'elk-uikit';
import { i18n } from '../../utils';

import styles from './ErrorBlock.module.scss';

const { getFormattedMessage } = i18n;
const BLOCK_LOCALE_NAME = 'block';
const ERROR_LOCALE_NAME = 'error.innerServerError';

const ErrorBlock = ({ maxWidthError }) => (
  <div
    className={styles.errorBlock}
    style={{ maxWidth: `${maxWidthError}px` }}
  >
    <Typography
      variant="h5"
      className={styles.titleError}
    >
      {
        getFormattedMessage(
          `${ERROR_LOCALE_NAME}.title`,
          BLOCK_LOCALE_NAME,
        )
      }
    </Typography>
    <Typography
      variant="h5"
      className={styles.infoError}
    >
      {getFormattedMessage(`${ERROR_LOCALE_NAME}.text`, BLOCK_LOCALE_NAME)}
    </Typography>
  </div>
);

ErrorBlock.propTypes = {
  maxWidthError: PropTypes.number,
};

ErrorBlock.defaultProps = {
  maxWidthError: 800,
};

export default React.memo(ErrorBlock);
