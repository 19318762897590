import adapter from './adapter';
import constants from './constants';
import utils from './utils';

const zvatApi = {
  adapter,
  constants,
  utils,
};

export default zvatApi;
