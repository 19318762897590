import {
  call,
  put,
} from 'redux-saga/effects';

import { sagaGenerator } from '../helpers';
import actions from '../actions/services';
import chatActions from '../actions/chat';
import {
  filterServices,
  filterServicesByIsDisplayInElk,
  tasksApi,
} from '../../utils';

const {
  getServicesList,
  getServicesListSuccess,
  handleServicesError,
} = actions;

const HANDLERS = {
  * [getServicesList]() {
    console.log('action');
    try {
      yield put(chatActions.setChatFieldsServices({ id: '', name: '' }));

      const page = 0;
      const size = 100;
      const url = tasksApi.utils.processesRoute;

      const { data: { processes } } = yield call(tasksApi.adapter, {
        method: 'get',
        url,
        params: {
          page,
          size,
        },
      });
      const filteredData = filterServices(filterServicesByIsDisplayInElk(processes));
      yield put(getServicesListSuccess(filteredData));
    } catch (error) {
      yield put(handleServicesError(error));
    }
  },
};

export default sagaGenerator(HANDLERS);
