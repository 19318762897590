export default {
  AUTO_GENERATE_IID: true,
  SERVER_ADDRESS: `${window.location.origin}/pushreport/`,
  OPERATOR_ID: 'OCB_MSB',
  OBFUSCATED_DATA: ['password'],
  VIEW_EVENTS_ENABLED: true,
  BLOCK_VIEW_EVENTS_ENABLED: true,
  AUTO_GENERATE_UIID: true,
  ENABLED: true,
  IO_EVENTS_ENABLED: true,
  LIC_SERVER_ADDRESS: 'https://lic.rooxcloud.com/pushreport/',
  LIC_OBFUSCATED_DATA: ['userid', 'msisdn', '^pn$', 'authentication_token', 'up_IDToken2'],
  LIC_ENABLED: false,
};
