/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function SortAsc({
  width,
  height,
  fill,
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="8" width="14" height="2" fill={fill} />
      <rect x="0" y="4" width="10" height="2" fill={fill} />
      <rect x="0" y="0" width="7" height="2" fill={fill} />
    </svg>
  );
}

SortAsc.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

SortAsc.defaultProps = {
  width: 14,
  height: 11,
  fill: '#6177FF',
};

export default React.memo(SortAsc);
