import {
  all,
  takeEvery,
  debounce,
} from 'redux-saga/effects';

// const keysWhichDebounce = [
//   'UPDATE_COMPANY_DATA_REQUEST',
//   updateCompanyDataRequest
// ];

const sagaGenerator = (handlers) => function* sagaReducer() {
  const sagas = Object
    .keys(handlers)
    .reduce((acc, key) => {
      if (Object.prototype.hasOwnProperty.call(handlers, key)) {
        acc.push(
          key === 'UPDATE_COMPANY_DATA_REQUEST'
          || key === 'GET_COUNTRIES_REQUEST'
          || key === 'GET_ISSUER_DIRECTORY_REQUEST'
            ? debounce(1000, key, handlers[key])
            : takeEvery(key, handlers[key]),
        );
      }
      return acc;
    }, []);
  yield all([...sagas]);
};

export default sagaGenerator;
