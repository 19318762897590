import configAdapter from '../../../config/config';

const UIDM_URL = configAdapter('com.rooxteam.uidm.url') || 'http://uidm.uidm-dev.d.exportcenter.ru';

const CUSTOMER = '/customer-webapi-1.0';
const WEBAPI_OTP_SETTINGS = '/webapi-otp-settings';
const ORG = '/org';
const OTP = '/otp';

export default {
  CUSTOMER,
  ORG,
  UIDM_URL,
  WEBAPI_OTP_SETTINGS,
  OTP,
};
