import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'elk-uikit';
import { i18n } from '../../utils';

import styles from './ErrorNotAccess.module.scss';

const { getFormattedMessage } = i18n;
const BLOCK_LOCALE_NAME = 'block';
const ERROR_LOCALE_NAME = 'error.notAccess';

const ErrorNotAccess = ({ maxWidthError }) => (
  <div className={styles.errorWrapper}>
    <div
      className={styles.errorBlock}
      style={{ maxWidth: `${maxWidthError}px` }}
    >
      <Typography
        variant="h5"
        className={styles.titleError}
      >
        {getFormattedMessage(`${ERROR_LOCALE_NAME}.title`, BLOCK_LOCALE_NAME)}
      </Typography>
      <Typography
        variant="h5"
        className={styles.titleError}
      >
        {getFormattedMessage(`${ERROR_LOCALE_NAME}.text`, BLOCK_LOCALE_NAME)}
      </Typography>
      <Typography
        variant="h5"
        className={styles.titleError}
      >
        {getFormattedMessage(`${ERROR_LOCALE_NAME}.text.support`, BLOCK_LOCALE_NAME)}
      </Typography>
    </div>
  </div>
);

ErrorNotAccess.propTypes = {
  maxWidthError: PropTypes.number,
};

ErrorNotAccess.defaultProps = {
  maxWidthError: 800,
};

export default memo(ErrorNotAccess);
