import actions from '../actions/auth';

export const initialState = {
  authLoadTime: null,
  isAuthenticated: false,
  data: null,
  error: null,
  isOtpLoading: false,
  isOtpEnabled: false,
  otpFlowState: {},
  otpPhoneFlow: {},
  otpErrorText: null,
  isLoading: true,
  documentsSignStep: {
    isSignLoading: false,
    isCertificatesModalOpen: false,
  },
  certificate: {
    cert: null, // Сертификат будет лежать тут, это специальный объект и его не стоит переписывать или как-то изменять.
    info: null,
    getError: false,
    addError: false,
    deleteError: false,
    isLoading: false,
  },
};

const ROLES_ACCESS = ['exporter_promo', 'exporter', 'exporter_admin'];

const HANDLERS = {
  [actions.sendOtpToMobileRequest]: (state) => ({
    ...state,
    isOtpLoading: true,
    error: null,
    otpErrorText: null,
  }),
  [actions.sendOtpToMobileSuccess]: (state, payload) => ({
    ...state,
    otpFlowState: payload.otpFlowState,
    isOtpLoading: false,
    error: null,
    otpErrorText: null,
    nextOtpCodePeriod: payload.nextOtpCodeOperationPeriod,
  }),
  [actions.sendOtpToMobileFailure]: (state, payload) => ({
    ...state,
    error: payload,
    isOtpLoading: false,
    inviteSuccess: false,
    otpErrorText: 'error.default',
  }),
  [actions.refreshTokenFailure]: (state, payload) => ({
    ...state,
    error: payload,
  }),
  [actions.authToggleCertificatesModal]: (state) => {
    const isOpen = !state.documentsSignStep.isCertificatesModalOpen;
    return ({
      ...state,
      documentsSignStep: {
        ...state.documentsSignStep,
        isCertificatesModalOpen: isOpen,
      },
      certificate: isOpen ? null : state.certificate,
      error: {
        hasError: false,
        message: '',
        errorFrom: '',
      },
    });
  },
  [actions.authSaveCertificate]: (state, payload) => ({
    ...state,
    certificate: {
      ...state.certificate,
      cert: payload,
    },
    thumbprint: payload.Thumbprint,
  }),
  [actions.getTokenSuccess]: (state, payload) => {
    const data = payload && payload.data;
    // SET for user current org id and his roles of current org id
    const { currOrgId = '', organizations = [], roles = [] } = data;
    const currentOrgId = organizations[0] && organizations[0].mdm_id;
    const isExistOneOrg = Boolean(organizations && organizations.length);

    let rolesCurrentOrgId = null;
    let displayNameCurrentOrgId = null;
    let isExporter = false;
    let isHaveFullAccessToPages = false;

    // eslint-disable-next-line no-prototype-builtins
    if (isExistOneOrg && organizations[0].hasOwnProperty('roles')) {
      const org = organizations.find(({ id }) => id === currOrgId);
      rolesCurrentOrgId = isExistOneOrg && org ? org.roles : null;
      if (organizations[0].roles.includes('exporter')) isExporter = true;
    }

    // eslint-disable-next-line no-prototype-builtins
    if (isExistOneOrg && organizations[0].hasOwnProperty('displayName')) {
      displayNameCurrentOrgId = organizations[0].displayName;
    }
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty('currOrgId')) {
      displayNameCurrentOrgId = isExistOneOrg
        ? organizations.find(({ id }) => id === currOrgId).displayName
        : '';
    }
    if (rolesCurrentOrgId && rolesCurrentOrgId.length) {
      /*
        Полный доступ ко всем разделам имеет пользователь,
        не являющийся Неподтвержденным (exporter_promo роль).
        Неподтвержденный имеет доступ только к страницам Главная и Профиль
        Ввиду того, что регистрация работает плохо, и в ролях может что-нибудь да отсутсвовать
        или же отсуствовать вообще roles в организации,
        то условие на проверку существования роли exporter_promo может сработать некорректно,
        поэтому помимо такого условия добавлено условие на роль exporter и exporter_admin,
        которые могут иметь ко всем разделам
      */
      const isExporterPromo = rolesCurrentOrgId.includes('exporter_promo');
      const isExporterOrAdmin = rolesCurrentOrgId
        .findIndex((role) => ['exporter', 'exporter_admin'].includes(role)) !== -1;
      isHaveFullAccessToPages = isExporterOrAdmin || !isExporterPromo;
    }

    const accessRole = roles.length && ROLES_ACCESS.some((role) => roles.includes(role));

    return {
      ...state,
      error: false,
      isLoading: false,
      isAuthenticated: Boolean(Object.keys(data || {}).length),
      isExporter,
      authLoadTime: state.authLoadTime || Date.now(),
      data: {
        ...data,
        currentOrgId,
        rolesCurrentOrgId,
        displayNameCurrentOrgId,
        isHaveFullAccessToPages,
        accessRole,
      },
    };
  },
};

export default function authReducer(state = initialState, { type, payload }) {
  const handler = HANDLERS[type];
  return handler ? handler(state, payload) : state;
}
