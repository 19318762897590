/* eslint-disable */
import React, { useEffect, useState, memo } from 'react';
import { KrDropdown, Typography, KrTooltip } from 'elk-uikit';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Icons } from 'components';
import { ReitingItems, AwardsBlock } from './components';
import { Button } from '..';
import styles from './Raiting.module.scss';

const { ArrowDown } = Icons;

const Raiting = ({
 raitings, count, alwaysOpen, needDropDown, dropdownValue, dropdownVariants, setVariant, dropdownDisabled, award,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [raitingsBlocks, setBlocks] = useState({ main: [], second: [] });
  useEffect(() => {
    if (raitings && raitings.length) {
      const [mainReiting, ...secondReiting] = raitings;
      setBlocks({ main: [mainReiting], second: secondReiting });
    }
  }, [raitings]);

  const toggleRaiting = () => setOpen((prev) => !prev);
  return (
    <div className={styles.raitingWrapper}>
      <div className={styles.firstRatingWrapper}>
        {needDropDown && (
        <div className={styles.dropdownWrapper}>
          <KrDropdown
            label="Рейтинг по направлению"
            value={dropdownValue}
            variants={dropdownVariants}
            onSelect={(value, name, variant) => setVariant(variant)}
            disabled={dropdownDisabled}
          />
        </div>
        )}
        <AwardsBlock award={award} />
        <ReitingItems raitings={raitingsBlocks.main} count={count} />
      </div>
      {!alwaysOpen && (
        <Button
          onClick={toggleRaiting}
          className={styles.showMore}
        >
          {isOpen ? 'Свернуть' : 'Развернуть'}
          <div className={cn(styles.toggleButton, { [isOpen ? styles.open : styles.closed]: true })}>
            <ArrowDown fill="currentColor" />
          </div>
        </Button>
      )}
      <div className={cn(styles.seconRaitingWrapper,
        { [(isOpen || alwaysOpen) ? styles.open : styles.closed]: true, [styles.inline]: alwaysOpen })}
      >
        <ReitingItems raitings={raitingsBlocks.second} count={count} />
      </div>
    </div>
  );
};

Raiting.propTypes = {
  raitings: PropTypes.arrayOf({
    raiting: PropTypes.number,
    description: PropTypes.string,
    size: PropTypes.oneOf('default', 'small'),
  }),
  count: PropTypes.number,
  alwaysOpen: PropTypes.bool,
  needDropDown: PropTypes.bool,
  dropdownValue: PropTypes.string,
  dropdownVariants: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    key: PropTypes.string,
  })),
  setVariant: PropTypes.func,
  dropdownDisabled: PropTypes.bool,
  award: PropTypes.shape({
    awardQuantity: PropTypes.bool,
    awardRating: PropTypes.string,
    awardSpeed: PropTypes.bool,
  }),
};

Raiting.defaultProps = {
  raitings: [],
  count: 5,
  alwaysOpen: false,
  needDropDown: false,
  dropdownValue: '',
  dropdownVariants: [],
  setVariant: () => {},
  dropdownDisabled: false,
  award: {},
};

export default memo(Raiting);
