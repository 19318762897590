import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Icons,
} from 'elk-uikit';
import omit from 'lodash/omit';

import colours from '../../styles/theme';
import styles from './AddButton.module.scss';

const AddButton = (props) => {
  const { disabled, color, content } = props;
  const buttonRestProps = omit(props, ['color', 'content']);

  return (
    <div className={styles.addButtonWrapper}>
      <Button
        variant="text"
        color={colours[color]}
        icon={(
          <div className={styles.addIcon}>
            <Icons.Add fill={disabled ? colours.third : colours[color]} />
          </div>
        )}
        {...buttonRestProps}
      >
        <span style={{ color: disabled ? colours.third : colours[color] }}>{content}</span>
      </Button>
    </div>
  );
};

AddButton.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  content: PropTypes.string,
};

AddButton.defaultProps = {
  color: 'primary',
  disabled: false,
  content: 'добавить',
};

export default React.memo(AddButton);
