import { createActions } from 'redux-actions';

const requestTypes = [
  'GET_SERVICE_INFO',
  'GET_SERVICE_INFO_SUCCESS',

  'SEND_SERVICE_FORM',

  'CANCEL_FORM',
  'CANCEL_FORM_SUCCESS',

  'SAVE_FORM_CONTEXT',
  'SAVE_FORM_CONTEXT_SUCCESS',

  'ERROR_HANDLER_SERVICES',

  'GET_COMPANY_DOCUMENTS_FROM_FORM',
  'GET_COMPANY_DOCUMENTS_FROM_FORM_SUCCESS',

  'ADD_NEW_DOCUMENT_FROM_FORM',

  'SEND_SIGNED_DOCUMENTS_TO_CHECK',

  'SEND_ELECTRONIC_SIGN_DOCUMENT_REQUEST',
  'SEND_ELECTRONIC_SIGN_DOCUMENT_REQUEST_SUCCESS',

  'SEND_FORM_FILES',
  'SEND_FORM_FILE',

  'DELETE_FORM_FILE',
  'DELETE_FORM_FILE_SUCCESS',

  'UPDATE_FORM_STATUS',

  'REFRESH_PROCESS',
  'REFRESH_PROCESS_MULTITASK',
  // 'REFRESH_SUBFORMS',

  // digital signature
  'GET_ALL_DOCUMENTS_FOR_SIGN',
  'GET_DOCUMENT_FOR_SIGN',
  'GET_DOCUMENT_FOR_SIGN_SUCCESS',
  'GET_ONLY_HASHES_FOR_SIGN',

  'SEND_DOCUMENT_SIGN',
  'SEND_DOCUMENT_SIGN_SUCCESS',

  'CHECK_DOCUMENT_SIGN',
  'CHECK_DOCUMENT_SIGN_SUCCESS',

  'CHECK_DOCUMENTS_SIGNS',

  'CHECK_DOCUMENT_CONTAINER_SIGN',

  // secure connection
  'SEND_CERTIFICATE',
  'SEND_CERTIFICATE_SUCCESS',

  'SEND_DECRYPTED_APPROVE',
  'SEND_DECRYPTED_APPROVE_SUCCESS',

  'DOWNLOAD_FILE',

  'SET_PROMPT_DATA',
  'RESET_PROMPT_DATA',

  // get products
  'GET_PRODUCTS',
  'GET_PRODUCTS_SUCCESS',

  'GET_CATALOG',
  'GET_CATALOG_SUCCESS',
];

const syncTypes = [
  'RESET_SERVICE_STATE',
  'HANDLE_GET_SERVICE_INFO_LOADER',
  'HANDLE_SEND_SERVICE_FORM_STATUS',
  'SUBMIT_FORM_STEP',
  'GET_TASK_ATTACHMENTS',
  'UPDATE_FORM_DOCUMENTS',
  'ADD_FORM_DOCUMENTS_BUFFER',

  'ADD_PRODUCT_TO_FORM',
  'UPDATE_PRODUCTS_TO_FORM',
  'DELETE_PRODUCT_FROM_FORM',

  'HANDLE_SIGNED_DOCUMENTS',
  'HANDLE_SEND_ELECTRIC_SIGN_REQUEST_STATUS',

  'HANDLE_FORM_FILES',
  'HANDLE_FORM_FILES_BY_NAME',

  'HANDLE_FILES_UPLOADING_STATUSES',

  'HANDLE_UPLOADED_FILES_IDS',
  'REMOVE_UPLOADED_FILES_ID',

  'HANDLE_UPLOADING_FILES_ERRORS',

  'RELOAD_FILE',

  'SAVE_DOCS',
  'SAVE_CURRENT_DOC_INFO',
  'SIGN_DOCUMENT',
  'DISABLE_SIGN_LOADING',

  'HANDLE_DOCUMENTS_FOR_SIGN_COUNTER',

  'SAVE_CERTIFICATE',
  'TOGGLE_CERTIFICATES_MODAL',
  'UPDATE_PRODUCTS_INFO',
  'VALIDATE_EVENT_INFO',
  'UPDATE_EVENT_INFO_ERRORS',

  'REMOVE_SUBTASK',
];

const types = [...requestTypes, ...syncTypes];

export const specActions = Object.entries(createActions(...types))
  .reduce((acc, [key, handler]) => ({
    ...acc,
    [key]: (nsId, payload) => {
      const type = [handler.toString(), nsId].filter((a) => a).join('_');
      return payload ? { type, payload } : { type };
    },
  }), {});
export default createActions(...types);
