import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { i18n } from '../../utils';
import { getCertSimpleName } from '../../utils/helpers/helpers';
import styles from './CertificateSelectorInfo.module.scss';

const { getFormattedMessage } = i18n;
const defaultCertificateData = {
  issuerName: '',
  validFrom: '',
  isValid: false,
};

const getCertificateInfo = async (certificate) => {
  if (certificate && !isEmpty(certificate)) {
    try {
      const fullIssuerName = await certificate.IssuerName;
      const issuerName = getCertSimpleName(fullIssuerName);

      let validFrom = await certificate.ValidFromDate;
      validFrom = new Date(validFrom).toLocaleDateString();

      let isValid = await certificate.IsValid();
      isValid = await isValid.Result;

      return {
        issuerName,
        validFrom,
        isValid,
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return defaultCertificateData;
    }
  }

  return defaultCertificateData;
};

const CertificateSelectorInfo = ({ certificate }) => {
  const [certificateData, setCertificateData] = useState(
    defaultCertificateData,
  );

  useEffect(() => {
    if (certificate) {
      (async () => {
        const data = await getCertificateInfo(certificate);
        setCertificateData(data);
      })();
    }
  }, [certificate]);

  const info = useMemo(() => {
    let labels = ['issuer', 'validFrom', 'isValid', 'active', 'notActive'];
    labels = labels.reduce((acc, el) => {
      acc[el] = getFormattedMessage(`info.${el}`, 'certificates');
      return acc;
    }, {});

    return [
      {
        id: 1,
        title: labels.issuer,
        value: certificateData.issuerName || '',
      },
      {
        id: 2,
        title: labels.validFrom,
        value: certificateData.validFrom || '',
      },
      {
        id: 3,
        title: labels.isValid,
        value: certificateData.isValid ? labels.active : labels.notActive,
      },
    ];
  }, [
    certificateData.issuerName,
    certificateData.validFrom,
    certificateData.isValid,
  ]);

  return (
    <div className={styles.infoWrapper}>
      <div>{getFormattedMessage('info.title', 'certificates')}</div>
      {info.map((el) => (
        <div key={el.id}>
          {el.title}
          {': '}
          {el.value}
        </div>
      ))}
    </div>
  );
};

CertificateSelectorInfo.propTypes = {
  certificate: PropTypes.shape({}),
};

CertificateSelectorInfo.defaultProps = {
  certificate: null,
};

export default CertificateSelectorInfo;
