/* eslint-disable react/no-danger */
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icons } from 'elk-uikit';
import actions from './effects/actions';
import { defaultPage } from './effects/constants';
import styles from './Banner.module.scss';

const { Close } = Icons;

function Banner() {
  const dispatch = useDispatch();
  const { location, isShow, text } = useSelector((state) => ({
    location: state.router.location.pathname,
    isShow: state.Banner.isShow,
    text: state.Banner.text,
  }));
  const onClose = () => {
    dispatch(actions.closeBanner({ isShow: false }));
  };

  useEffect(() => {
    if (location) {
      dispatch(actions.getBannerInfo({ key: location.slice(1) || defaultPage }));
    }
  }, [location]);

  if (!isShow) return null;

  return (
    <div className={styles.banner}>
      <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />
      <div
        className={styles.closeIcon}
        onClick={onClose}
      >
        <Close
          width={15}
          height={15}
          fill="white"
        />
      </div>
    </div>
  );
}

export default memo(Banner);
