import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Title.module.scss';

const Title = ({ text = '', className }) => (
  <h4
    className={cn(
      styles.title,
      className,
    )}
  >
    {text}
  </h4>
);

Title.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
};

Title.defaultProps = {
  className: '',
};

export default React.memo(Title);
