import adapter from './adapter';
import npsAdapter from './npsAdapter';
import constants from './constants';
import utils from './utils';

const uidmApi = {
  npsAdapter,
  adapter,
  constants,
  utils,
};

export default uidmApi;
