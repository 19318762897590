import configAdapter from '../../../config/config';

const API_URL_V1 = '/api/v1';
const ZVAT_ROUTE = `${API_URL_V1}`;
const PROCESSES = '/processes';
const ZVAT_URL = configAdapter('com.rooxteam.widgets.zvat.api.url');
// const ZVAT_URL = 'http://zvat-kontur-adapter.integration-dev.d.exportcenter.ru';

export default {
  ZVAT_ROUTE,
  PROCESSES,
  ZVAT_URL,
};
