import { createActions } from 'redux-actions';

const syncTypes = [
  'GET_BANNER_INFO',
  'GET_BANNER_INFO_SUCCESS',
  'GET_BANNER_INFO_FAILURE',

  'CLOSE_BANNER',
];

export default createActions(...syncTypes);
