import { createActions } from 'redux-actions';

const requestTypes = [
  'GET_NOTIFICATIONS_REQUEST',
  'GET_NOTIFICATIONS_SUCCESS',
  'GET_NOTIFICATIONS_FAILURE',

  'GET_TARGETED_OFFERS_REQUEST',
  'GET_TARGETED_OFFERS_SUCCESS',
  'GET_TARGETED_OFFERS_FAILURE',

  'MARK_NOTIFICATION_AS_VIEWED',
  'MARK_NOTIFICATION_AS_VIEWED_SUCCESS',
  'MARK_NOTIFICATION_AS_VIEWED_FAILURE',

  'SET_EXPANDED',
  'SET_MARKED_NOTIFICATIONS_NUMBER',

  'MARK_ALL_NOTIFICATIONS_AS_VIEWED_SUCCESS',
  'MARK_ALL_NOTIFICATIONS_AS_VIEWED_FAILURE',

  'HANDLE_NOTIFICATIONS_LIST_VISIBLE',
];

const syncTypes = [
  'CHANGE_TABS_NOTIFICATIONS',
  'HANDLE_AUTOCOMPLETE_INPUT',
];

const types = [...requestTypes, ...syncTypes];

export default createActions(...types);
