import { createActions } from 'redux-actions';

const requestTypes = [
  'GET_REQUEST_START',
  'GET_REQUEST_FAILURE',
  'GET_REQUEST_SUCCESS',
  'SET_FILTER',
];

const types = [...requestTypes];

export default createActions(...types);
