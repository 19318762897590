import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Dot.module.scss';

const Index = ({ color, style }) => (
  <div
    className={cn(styles.wrapper, {
      [styles.red]: color === 'red',
      [styles.green]: color === 'green',
      [styles.blue]: color === 'blue',
      [styles.orange]: color === 'orange',
    })}
    style={style}
  />
);

Index.propTypes = {
  color: PropTypes.oneOf(['red', 'green', 'blue', 'orange']),
  style: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
};

Index.defaultProps = {
  color: 'red',
  style: {},
};

export default memo(Index);
