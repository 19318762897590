import constants from './constants';

const {
  API_URL_V1,
  PROCESSES_CONTROLLER,
  TASKS_URL,
} = constants;

const processesRoute = `${TASKS_URL}${API_URL_V1}${PROCESSES_CONTROLLER}`;
const refreshProcessesRoute = `${TASKS_URL}${API_URL_V1}${PROCESSES_CONTROLLER}/refresh`;

const generateTasksRoute = (path) => `${API_URL_V1}${path}`;
const generateGetProcessStagesUrl = (processInstanceId) => `${processesRoute}/${processInstanceId}/stages`;

export default {
  generateTasksRoute,
  generateGetProcessStagesUrl,
  processesRoute,
  refreshProcessesRoute,
};
