/* eslint-disable camelcase */
import React, { memo } from 'react';
import intersection from 'lodash/intersection';
import { useSelector } from 'react-redux';
import Tab from './Tab';

// import AccessHoc from '../../hocs/AccessHoc/AccessHoc';
import styles from './index.module.scss';

// const ALL_ROLES = ['partner', 'partner_director', 'partner_confirmed'];
const NOT_ALL_ROLES = ['partner', 'partner_director'];

const TabsBlock = () => {
  const {
    rolesCurrentOrgId,
    partnerAccreditationsCount,
    etp_acc_creation_national,
    isAffil,
  } = useSelector((state) => ({
    etp_acc_creation_national: state.accreditationAccesses.etp_acc_creation_national,
    isAffil: state.accreditationAccesses.isAffil,
    rolesCurrentOrgId: state.auth.data.rolesCurrentOrgId,
    partnerAccreditationsCount: state.user.partnerAccreditationsCount,
  }));

  const hasAccess = intersection(NOT_ALL_ROLES, rolesCurrentOrgId);

  return (
    <div className={styles.tabsBlock}>
      <Tab link="/requests" name="Мои услуги" />
      <Tab
        isSub
        subName="Аккредитации"
        subLinks={[
          { link: '/accreditation-requests', name: 'Заявки' },
          { link: '/accreditations', name: 'Действующие аккредитации' },
          { link: '/accreditation-document', name: 'Документы по аккредитации' },
          ...(etp_acc_creation_national ? [
            { link: '/invoices-payment', name: 'Счета на оплату' },
            { link: '/partner-access', name: 'Доступы' },
          ] : []),
        // { link: '/payment-access', name: 'Оформить доступ' },
        ]}
      />
      {isAffil && <Tab link="/affiliates" name="Аффилированные лица" />}
      <Tab link="/documents" name="Документы по услугам" />
      <Tab link="/statistics" name="Моя статистика" />
      <Tab link="/action-history" name="История действий" />
      <Tab link="/success-story" name="История успеха" />
      <Tab link="/reports" name="Отзывы и рейтинг" />
      {Boolean(hasAccess.length) && <Tab link="/users" name="Сотрудники" />}
      <Tab link="/user-profile" name="Профиль пользователя" />
      {!!partnerAccreditationsCount.count && <Tab link="/company" name="Профиль компании" />}
      <Tab link="/support" name="Поддержка" />
      {/* <Tab link="/communications" name="Коммуникации" /> */}
    </div>
  );
};

export default memo(TabsBlock);
