import {
  call, delay, put, select,
} from 'redux-saga/effects';
import { sagaGenerator } from 'store/helpers';
import { createMetrics } from 'react-metrics';
import get from 'lodash/get';
import { sendDataForm } from '../../ServiceForm/effects/sagas';
import actions, { specActions } from '../../../effects/actions';
import { getErrMsg } from '../../../utils/helpers';
import { checkErrorResponseCode } from '../../../effects/sagaHelpers';
import { bpmnApi, metricsConfig } from '../../../../../utils';

const {
  sendServiceForm,
  refreshProcess,
} = actions;

const metrics = createMetrics(metricsConfig);

let isSending = false;

const HANDLERS = {
  * [sendServiceForm]({
    payload: {
      taskId,
      camundaId,
      data,
      nextStep = 'NEXT',
      submitParent,
    },
  }, nsId) {
    const ns = `ServiceProfile${nsId}`;
    const { processInstanceId = '' } = yield select((state) => state[ns].taskContext.task);

    try {
      if (isSending) return;
      isSending = true;
      yield put(specActions.handleSendServiceFormStatus(nsId, true));
      const variables = yield select((state) => state[ns].taskContext.variables);

      const resp = yield call(sendDataForm, {
        taskId,
        camundaId,
        nextStep,
        variables,
        data,
      });

      const { taskId: nextTaskId } = resp;

      metrics.api.track('service_order', {
        status: 'success',
        taskId,
        processInstanceId,
        camundaId,
        nextStep,
        formWithSteps: false,
      });

      if (submitParent) { // submit main task
        yield put(actions.setPromptData({
          enabled: false,
          actionType: 'SUBMIT_ACTION',
          args: [submitParent],
        }));
      }

      yield put(specActions.refreshProcess(nsId, {
        nextTaskId,
        isFinish: nextStep === 'FINISH',
      }));
      // yield put(specActions.handleSendServiceFormStatus(nsId, false));
      isSending = false;
      yield put(specActions.resetPromptData(nsId));
    } catch (error) {
      isSending = false;
      yield put(specActions.resetPromptData(nsId));
      if (checkErrorResponseCode(error)) {
        yield put(specActions.errorHandlerServices(nsId, {
          hasError: true,
          message: getErrMsg(error),
          errorFrom: 'finishServiceForm',
        }));
      }
      yield put(specActions.handleSendServiceFormStatus(nsId, false));
      metrics.api.track('service_order', {
        status: 'failure',
        taskId,
        processInstanceId,
        camundaId,
        nextStep,
        formWithSteps: false,
      });
    }
  },
  * [refreshProcess]({ payload: { nextTaskId, isFinish } }, nsId) {
    function* removeSubtask(formKey) {
      yield put(actions.removeSubtask(formKey));
    }
    if (isFinish) {
      yield call(removeSubtask, nsId);
      yield put(specActions.handleSendServiceFormStatus(nsId, false));
      return;
    }

    function* updateSubForm(taskId, camundaId) {
      yield put(specActions.getServiceInfo(nsId, {
        taskId,
        camundaId,
      }));
    }

    const DEFAULT_DELAY = 2500;
    const DEFAULT_TRIES = 16;

    const ns = `ServiceProfile${nsId}`;
    const {
      processInstanceId,
      tries,
      refreshDelay,
      camundaId,
    } = yield select((state) => ({
      processInstanceId: get(state, `${ns}.taskContext.task.processInstanceId`, ''),
      camundaId: get(state, `${ns}.taskContext.task.camundaId`, ''),
      tries: get(state, `${ns}.taskContext.uiSchema.formRefresh.tries`, DEFAULT_TRIES),
      refreshDelay: get(state, `${ns}.taskContext.uiSchema.formRefresh.delay`, DEFAULT_DELAY),
    }));

    if (nextTaskId) {
      yield call(updateSubForm, nextTaskId, camundaId);
      yield put(specActions.handleSendServiceFormStatus(nsId, false));
      return;
    }

    let finished = false;
    let counterOfCalls = 0;

    try {
      const url = bpmnApi.constants.BPMN_TASKS_API;

      while (!finished) {
        const { data } = yield call(bpmnApi.adapter, {
          method: 'get',
          url,
          headers: {
            camundaId,
          },
          params: {
            processInstanceId,
            size: 50,
          },
        });
        const task = get(data, 'content', []).find(({ formKey }) => formKey === nsId);
        const taskId = get(task, 'id', null);

        if (taskId) {
          finished = true;
          yield call(updateSubForm, taskId, camundaId);
        }

        counterOfCalls += 1;
        if (counterOfCalls >= tries) {
          finished = true;
          yield call(removeSubtask, nsId);
        }
        yield delay(refreshDelay);
      }
    } catch (error) {
      finished = true;
      yield put(specActions.errorHandlerServices(nsId, {
        hasError: true,
        message: getErrMsg(error),
        errorFrom: 'refreshProcess',
      }));
    }
    yield put(specActions.handleSendServiceFormStatus(nsId, false));
  },
};

export default sagaGenerator(HANDLERS);
