import { call } from 'redux-saga/effects';
import axios from 'axios';
import get from 'lodash/get';

import constants from './constants';
import i18n from '../../i18nConfig';

const { locale: { acceptedLanguages } } = i18n;
const { ZVAT_URL } = constants;

function* zvatAdapter(options) {
  // options.headers will rewrite Accept ot Content-Type if there are some
  const headers = {
    'Accept-Language': acceptedLanguages,
    'Content-Type': 'application/octet-stream',
    ...get(options, 'headers', {}),
  };

  // use all options from request including data, method etc
  const reqOptions = {
    baseURL: `${ZVAT_URL}/kontur-adapter/api/v1/auth`,
    ...options,
    headers,
  };

  return yield call(axios, reqOptions);
}

export default zvatAdapter;
