import configAdapter from '../../../config/config';

const API_URL_V2 = '/api/v2';
const API_URL_V1 = '/api/v1';
const API_URL_V0 = '/api/v0';

const DELETE = 'DELETE';
const POST = 'POST';
const GET = 'GET';
const PUT = 'PUT';

const NOTIFICATIONS = '/current-user-notifications';
const OFFERS = '/targeted-offer';
const MARK_AS_READ = '/mark-notification-as-read';
const NOTIFICATIONS_URL = configAdapter('com.rooxteam.widgets.notify.api.url');
const OFFERS_URL = configAdapter('com.rooxteam.widgets.offers.api.url');

export default {
  API_URL_V2,
  API_URL_V1,
  API_URL_V0,
  MARK_AS_READ,
  NOTIFICATIONS,
  OFFERS,
  NOTIFICATIONS_URL,
  OFFERS_URL,
  DELETE,
  POST,
  GET,
  PUT,
};
