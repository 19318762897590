/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import theme from '../../styles/theme';

function TopLab({
  width,
  height,
  fill,
}) {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 1.45455H6V5.65663C6 6.10825 5.89485 6.55367 5.69288 6.95761L1.45642 14.5455H12.7494L8.30712 6.95761C8.10515 6.55367 8 6.10825 8 5.65663V1.45455ZM6 0C5.19668 0 4.54545 0.651222 4.54545 1.45455V5.65663C4.54545 5.88244 4.49288 6.10515 4.39189 6.30712L0.155437 13.895C-0.328127 14.8621 0.375139 16 1.45642 16H12.7494C13.8307 16 14.534 14.8621 14.0504 13.895L9.60811 6.30712C9.50712 6.10515 9.45455 5.88244 9.45455 5.65663V1.45455C9.45455 0.651222 8.80332 0 8 0H6Z" fill={fill} />
      <path d="M10.2871 9.45455C10.7938 9.45455 11.264 9.71825 11.5283 10.1506L13.7505 13.787C14.3428 14.7562 13.6453 16 12.5094 16H1.69643C0.560534 16 -0.13702 14.7562 0.455294 13.787L2.67752 10.1506C2.94174 9.71825 3.41194 9.45455 3.91865 9.45455H10.2871Z" fill={fill} />
      <path d="M3.90907 0.727273C3.90907 0.325611 4.23468 0 4.63634 0H9.36361C9.76527 0 10.0909 0.325611 10.0909 0.727273C10.0909 1.12893 9.76527 1.45455 9.36361 1.45455H4.63634C4.23468 1.45455 3.90907 1.12893 3.90907 0.727273Z" fill={fill} />
    </svg>
  );
}

TopLab.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

TopLab.defaultProps = {
  width: 16,
  height: 20,
  fill: theme.dodgerBlue,
};

export default React.memo(TopLab);
