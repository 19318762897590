const generateLetter = (firstName = '', lastName = '') => {
  const firstTrimmed = firstName.trim();
  const lastTrimmed = lastName.trim();
  const fullName = `${firstTrimmed}${lastTrimmed ? ` ${lastTrimmed}` : ''}`;
  const greeting = `Здравствуйте${fullName ? `, ${fullName}!` : '!'} Приглашаем вас.`;
  // eslint-disable-next-line max-len
  const additional = '«Шерстяное одеяло, что ему недавно дали в благотворительном фонде, удобно обнимало его плечи, а ботинки, которые он сегодня нашел в мусорном баке, абсолютно не жали. Уличные огни так приятно согревали душу после всей этой холодящей темноты... Изгиб скамьи в парке казался таким знакомым его натруженной старой спине. «Спасибо тебе, Господи, — подумал он, — жизнь просто восхитительна!»';
  return `${greeting} \n\n ${additional}`;
};

const filterUsers = (newUser, cb) => {
  // дефолтное значение для approved поставлено,
  // чтобы mdm пользователей можно было записать в активные
  const { blocks = null, approved = true } = newUser;
  const randomDate1 = new Date(+(new Date()) - Math.floor(Math.random() * 10000000000));
  const randomDate2 = new Date(+(new Date()) - Math.floor(Math.random() * 10000000000));

  const pushActiveOrInvited = (user) => {
    const selectedUser = user;
    if (approved) {
      const {
        id = '',
        createdDate = null,
      } = selectedUser;
      // random (дата последнего входа)
      selectedUser.dateLast = randomDate1;
      // random (дата создания)
      selectedUser.dateInvite = id ? createdDate : randomDate2;
      cb(user, 'active');
    } else {
      const {
        firstName,
        lastName,
        id = '',
        createdDate = null,
      } = selectedUser;
      selectedUser.letter = generateLetter(firstName, lastName);
      // random (дата приглашения) для mdm пользователя
      // значение createdDate для uidm пользователя
      selectedUser.dateInvite = id ? createdDate : randomDate2;
      cb(user, 'invited');
    }
  };

  if (blocks) {
    const isExistBlocks = blocks.length > 0;
    if (!isExistBlocks) {
      pushActiveOrInvited(newUser);
    } else {
      const nowDate = new Date();
      const isSomeBlocksPastDue = blocks.some(({ blockedTo }) => new Date(blockedTo) > nowDate);
      if (isSomeBlocksPastDue) {
        const selectedUser = newUser;
        // random (дата последнего входа)
        selectedUser.dateLast = randomDate1;
        // random (дата создания)
        selectedUser.dateInvite = selectedUser.id ? selectedUser.createdDate : randomDate2;
        selectedUser.isBlockUser = true;
        cb(selectedUser, 'blocked');
        return;
      }
      pushActiveOrInvited(newUser);
    }
  } else {
    pushActiveOrInvited(newUser);
  }
};

const getFIO = (user) => {
  const {
    lastName = '',
    firstName = '',
    middleName = '',
  } = user;
  return `${lastName ? `${lastName} ` : ''}${firstName ? `${firstName} ` : ''}${middleName}`;
};

const sortByFIO = (prev, next) => {
  const fioPrev = getFIO(prev).toLowerCase().trim();
  const fioNext = getFIO(next).toLowerCase().trim();
  return fioPrev > fioNext ? 1 : -1;
};

const sortUsers = (users) => {
  // todo: еще будет тип пользователя, который является Контактным лицом
  // он должен быть в начале списка, но пока его нет

  // сначала отобразить всех админов в алфавитном порядке по ФИО
  const admins = users
    .filter((user) => user.roleUser === 'exporter_admin')
    .sort(sortByFIO);
  // далее всех остальных пользователей в алфавитном порядке
  const others = users
    .filter((user) => user.roleUser !== 'exporter_admin')
    .sort(sortByFIO);
  return [...admins, ...others];
};

const getUserList = (usersMdm, usersUidm) => {
  const combinedUsersList = {
    active: [],
    blocked: [],
    invited: [],
  };
  const listIdUidmUsersThatExistInMdm = [];

  // смотрим, есть ли пользователь от mdm в uidm
  // если есть - берем значение ролей от uidm и
  // ставим для пользователя (совместив информацию как от mdm, так и от uidm)
  // если нет - берем mdm пользователя и ставим прочерк в роли
  // заливаем в общий список каждого пользователя
  // еще учитывать надо, чтобы дважды не отображать одного и того же пользователя
  // примечание: если пользователь есть только в одной системе - никаких действий с ним делать нельзя
  // имелось ввиду наверно блокировка / назначение админом / снятие роли админа
  usersMdm.forEach((userMdm) => {
    let newUser = userMdm;
    const findUserInUidm = usersUidm.find((userUidm) => userUidm.id.includes(userMdm.uuid));
    if (findUserInUidm) {
      listIdUidmUsersThatExistInMdm.push(findUserInUidm.id);

      const { organization: { roles } } = findUserInUidm;
      let roleUser = '';

      if (roles) {
        // установить роль для пользователя (roleUser)
        // exporter_promo / exporter / exporter_admin
        // если подобных ролей не будет (вдруг), то по умолчанию пустая строка останется
        // тогда для пользователя необходимо показать роль с надписью "Роль неизвестна"

        const setRoleGeneral = (listRoles, type, cb) => {
          if (listRoles.includes(type)) {
            cb(type);
          }
        };

        // сделано так, потому что неизвестно в каком порядке придет
        // список ролей и может быть такое, что и exporter и exporter_admin вместе будут
        // и чтобы корректно установить роль
        // сделала так
        const cbSettingRole = (value) => { roleUser = value; };
        setRoleGeneral(roles, 'exporter_promo', cbSettingRole);
        setRoleGeneral(roles, 'exporter', cbSettingRole);
        setRoleGeneral(roles, 'exporter_admin', cbSettingRole);
      }
      // назвала так, дабы избежать перезаписи поля role
      // (если такое придет от апи (н-р, mdm - но вроде нет) - у uidm оно есть, но null)
      newUser.roleUser = roleUser;
      // объединить данные пользователя от mdm (уже с записью роли в виде строки) и uidm
      newUser = {
        ...findUserInUidm,
        ...newUser,
      };
    } else {
      newUser.roleUser = '-';
    }
    filterUsers(newUser, (item, type) => combinedUsersList[type].push(item));
  });

  usersUidm.forEach((userUidm) => {
    const newUser = userUidm;
    const findUserInMdm = listIdUidmUsersThatExistInMdm.includes(userUidm.id);
    // если был бы найден, то уже вверху при проходе usersMdm, уже пользователь запушился в общий список
    // поэтому заливаем uidm пользователя в общий список если не найден
    // и ставим ему роль как Системный пользователь (system)
    if (!findUserInMdm) {
      newUser.roleUser = 'system';
      filterUsers(newUser, (item, type) => combinedUsersList[type].push(item));
    }
  });
  Object.keys(combinedUsersList).forEach((type) => {
    combinedUsersList[type] = sortUsers(combinedUsersList[type]);
  });
  return combinedUsersList;
};

const getRoleBadgeUser = (roleUser) => {
  let labelRole = '---';
  let fontSize = null;
  switch (roleUser) {
    case 'exporter_promo':
      labelRole = 'Н';
      break;
    case 'exporter':
      labelRole = 'П';
      break;
    case 'exporter_admin':
      labelRole = 'А';
      break;
    case 'system':
      labelRole = '--';
      break;
    case '-':
      labelRole = '-';
      break;
    default:
      labelRole = '---';
      fontSize = '9px';
      break;
  }
  return {
    label: labelRole,
    ...(fontSize ? { fontSize } : {}),
  };
};

export default {
  getUserList,
  getFIO,
  getRoleBadgeUser,
};
