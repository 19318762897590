import actions from './actions';

const {
  getAccreditationHistory,
  getAccreditationHistorySuccess,
  getAccreditationHistoryFailure,
  setDraftStatus,
  setDraftStatusSuccess,
  setDraftStatusFailure,
  getAttachmentsSuccess,
} = actions;

export const initialState = {
  isLoading: false,
  isDraftRequestSending: false,
  json: null,
};

const HANDLERS = {
  [getAttachmentsSuccess]: (state, payload) => ({
    ...state,
    attachments: payload,
  }),
  [getAccreditationHistory]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [getAccreditationHistorySuccess]: (state, payload) => ({
    ...state,
    isLoading: false,
    json: payload,
  }),
  [getAccreditationHistoryFailure]: (state) => ({
    ...state,
    isLoading: false,
    json: null,
  }),
  [setDraftStatus]: (state) => ({
    ...state,
    isDraftRequestSending: true,
  }),
  [setDraftStatusSuccess]: (state) => ({
    ...state,
    isDraftRequestSending: false,
  }),
  [setDraftStatusFailure]: (state) => ({
    ...state,
    isDraftRequestSending: false,
  }),
  ...initialState,
};

export default function replyCardReducer(state = initialState, { type, payload }) {
  const handler = HANDLERS[type];
  return handler ? handler(state, payload) : state;
}
