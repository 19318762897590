import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './index.module.scss';

const getWidthFromStep = (currentStep, maxSteps) => {
  if (currentStep >= maxSteps) return 100;
  return ((100 * currentStep) / maxSteps);
};

const ProgressBar = ({
  currentStep,
  maxSteps,
  variant,
  additionalPercentText,
  lightMode,
  error,
  locales: {
    from,
    step,
    stepsLeft,
  },
}) => {
  const statusStyle = useMemo(() => ({
    width: `${getWidthFromStep(currentStep, maxSteps)}%`,
  }), [currentStep, maxSteps]);

  const getStatusTextByVariant = useCallback(() => {
    const variants = {
      step: (
        <span className={styles.statusText}>
          {step}
          {' '}
          {currentStep}
          {' '}
          {from}
          {' '}
          {maxSteps}
        </span>
      ),
      percent: (
        <span
          className={cn(
            styles.statusText,
            styles.statusTextPercent,
            {
              [styles.lightModeText]: lightMode,
            },
          )}
        >
          {`${additionalPercentText}${currentStep} %`}
        </span>
      ),
      quantity: (
        <div className={styles.quantity}>
          <span className={cn(styles.statusText, styles.fontWeightBold)}>
            {stepsLeft}
            {' '}
            {maxSteps - currentStep}
          </span>
          <span className={cn(styles.statusText, styles.secondaryText)}>
            {from}
            {' '}
            {maxSteps}
          </span>
        </div>
      ),
      none: null,
    };

    return variants[variant] || null;
  }, [step, currentStep, from, maxSteps, lightMode, additionalPercentText, stepsLeft, variant]);

  const statusText = useMemo(() => {
    if (!variant) return null;
    return getStatusTextByVariant();
  }, [getStatusTextByVariant, variant]);

  return (
    <div className={styles.container}>
      {statusText}
      <div
        className={cn(
          styles.backLine,
          {
            [styles.lightModeBackLine]: lightMode,
          },
        )}
      >
        <div
          style={statusStyle}
          className={cn(styles.frontLine, {
            [styles.normal]: !error,
            [styles.error]: error,
          })}
        />
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  maxSteps: PropTypes.number,
  currentStep: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  variant: PropTypes.oneOf(['step', 'percent', 'quantity', 'none']),
  additionalPercentText: PropTypes.string,
  lightMode: PropTypes.bool,
  error: PropTypes.bool,
  locales: PropTypes.shape({
    step: PropTypes.string,
    from: PropTypes.string,
    stepsLeft: PropTypes.string,
  }),
};

ProgressBar.defaultProps = {
  maxSteps: '',
  currentStep: '',
  additionalPercentText: '',
  variant: null,
  lightMode: false,
  error: false,
  locales: {
    step: 'Шаг',
    from: 'из',
    stepsLeft: 'Осталось',
  },
};

export default React.memo(ProgressBar);
