import { call, put, select } from 'redux-saga/effects';
import { getCookie, mdmApi } from '../../utils';
import { sagaGenerator } from '../helpers';
import actions from '../actions/partnerServicesFilters';
import { catalogsApi } from '../constants/partnerServicesFilters';

const {
  getPartnerServicesFiltersRequest,
  getPartnerServicesFiltersSuccess,
  getPartnerServicesFiltersFailure,
} = actions;

const headers = {
  Authorization: `Bearer sso_1.0_${getCookie('digital_mp_at')}`,
};

const HANDLERS = {
  * [getPartnerServicesFiltersRequest]({ payload }) {
    const { name } = payload;
    const { currentOrgId } = yield select((state) => state.auth.data);
    const dataObj = { partner: currentOrgId };
    try {
      const {
        data: { content },
      } = yield call(mdmApi.adapter, {
        headers,
        method: mdmApi.constants.POST,
        url: catalogsApi[name].url,
        data: dataObj,
        params: catalogsApi[name].params() || {},
      });
      const value = catalogsApi[name].getValue(content);
      yield put(getPartnerServicesFiltersSuccess({ name, value }));
    } catch (err) {
      console.log(err);
      yield put(getPartnerServicesFiltersFailure({ name }));
    }
  },
};

export default sagaGenerator(HANDLERS);
