import reducerGenerator from '../helpers/reducerGenerator';
import actions from '../actions/errorResponse';

const initialState = {
  errorStatus: false,
};

const HANDLERS = {
  [actions.setError]: (state) => ({
    ...state,
    errorStatus: true,
  }),
  [actions.unmountError]: (state) => ({
    ...state,
    errorStatus: false,
  }),
};

export default reducerGenerator(initialState, HANDLERS);
