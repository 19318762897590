import React, {
  memo, useState, useEffect, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { Icons } from '../..';
import theme from '../../../styles/theme';
import styles from './index.module.scss';

const { ArrowDown } = Icons;

const Tab = ({
 link, isSub, subLinks, subName, name,
}) => {
  const [isActive, setIsActive] = useState('');
  const [subActive, setSubActive] = useState(false);
  const location = useLocation();

  const subOnClickHandler = useCallback(() => {
    setSubActive(!subActive);
  }, [subActive]);

  useEffect(() => {
    if (location?.pathname) {
      setIsActive(location.pathname);
      if (isSub) {
        const sublinks = subLinks.map((l) => l.link.slice(1));
        const pathname = location.pathname.split('/');
        let checkIsActive = false;
        pathname.forEach((path) => {
          if (sublinks.includes(path)) {
            checkIsActive = true;
            setIsActive(`/${path}`);
          }
        });
        setSubActive(checkIsActive);
      }
    }
  }, [location.pathname]);

  const isActiveTab = useCallback(() => {
    if (name === 'Мои услуги'
    && (location.pathname === ''
    || location.pathname === '/'
    || location.pathname === link
    )) {
      return true;
    }
    return isActive === link;
  }, [location.pathname, isActive]);

  return (isSub ? (
    <div className={styles.sub}>
      <div
        onClick={subOnClickHandler}
        className={`${styles.container}`}
      >
        {subName}
        <ArrowDown fill={theme.white} transform={subActive ? 'rotate(180, 6, 3)' : ''} />
      </div>
      {subLinks && subActive && subLinks.map((l) => (
        <Link key={l.name} to={l.link}>
          <div className={`${styles.container} ${isSub && styles.isSub} ${isActive === l.link && styles.isActive}`}>
            {l.name}
          </div>
        </Link>
      ))}
    </div>
  ) : (
    <Link to={link}>
      <div className={`${styles.container} ${isActiveTab() && styles.active}`}>
        {name}
      </div>
    </Link>
  ));
};

Tab.propTypes = {
  link: PropTypes.string,
  isSub: PropTypes.bool,
  subLinks: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
  })),
  subName: PropTypes.string,
  name: PropTypes.string,
};

Tab.defaultProps = {
  link: '',
  isSub: false,
  subLinks: [],
  subName: '',
  name: '',
};

export default memo(Tab);
