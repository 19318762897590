import { createActions } from 'redux-actions';

import * as constants from '../constants/replies';

export const {
  showChat,
  setFiles,
} = createActions(
  constants.SHOW_CHAT,
  constants.SET_FILES,
);
