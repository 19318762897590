// import { reducerGenerator } from '../helpers';
import { reducerGenerator } from '../helpers';
import actions from '../actions/utils';

const MAX_WIDTH = 1200;
const MAX_WIDTH_WHEN_AUTH = 1550;
const MOBILE_BREAKPOINT = 768;

const initialState = {
  width: MAX_WIDTH,
  isShowMenu: false,
  isLessMaxWidth: false,
  loadingPage: true,
  hideBannerBetaVersion: true,
  isHideLeftMenu: false,
  heightFooter: null,
  modalWindowPosition: '',
};

const HANDLERS = {
  [actions.toggleMenu]: (state) => ({
    ...state,
    isShowMenu: !state.isShowMenu,
  }),
  [actions.handleOnResize]: (state, { width, isAuth }) => {
    const maxWidth = isAuth ? MAX_WIDTH_WHEN_AUTH : MAX_WIDTH;
    return {
      ...state,
      isLessMaxWidth: width <= maxWidth,
      isShowMenu: width > maxWidth || (state.isShowMenu && state.isLessMaxWidth),
      isMobile: width <= MOBILE_BREAKPOINT,
      width,
    };
  },
  [actions.setReadyPage]: (state) => ({
    ...state,
    loadingPage: false,
  }),
  [actions.setHideBannerBetaVersion]: (state, payload) => ({
    ...state,
    hideBannerBetaVersion: payload,
  }),
  [actions.setHeightFooter]: (state, payload) => ({
    ...state,
    heightFooter: payload,
  }),
  [actions.setModalWindowPosition]: (state, payload) => ({
    ...state,
    modalWindowPosition: payload,
  }),
};

export default reducerGenerator(initialState, HANDLERS);
