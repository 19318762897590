/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { KrDropdown } from 'elk-uikit';
import actions from 'store/actions/partnerServicesFilters';
import { filtersNames } from 'store/constants/partnerServicesFilters';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';

const { directions, partnerService } = filtersNames;

const PartnerServicesFilters = ({
  selectedFilters,
  setValueFilter,
  directionStyles,
  serviceStyles,
}) => {
  const dispatch = useDispatch();
  const [servicesList, setServicesList] = useState([]);
  const { filters, isLoading } = useSelector((state) => state.partnerServicesFilters);

  useEffect(() => {
    dispatch(actions.getPartnerServicesFiltersRequest({ name: directions, value: '' }));
    dispatch(actions.getPartnerServicesFiltersRequest({ name: partnerService, value: '' }));
    return () => {
      setValueFilter('', partnerService);
      setValueFilter('', directions);
    };
  }, [dispatch]);

  const setValueServiceFilter = (value, name) => {
    if (name === directions) {
      const selectedDirection = filters[directions].data?.find(
        ({ value: directionValue }) => directionValue === value,
      );
      const list = selectedDirection?.id
        ? filters[partnerService].data.filter(
            (serv) => get(serv, 'parentId') === selectedDirection?.id,
          )
        : filters[partnerService].data;
      setServicesList(list);
      setValueFilter({ name: value, id: selectedDirection?.id }, name);
    }
    if (name === partnerService) {
      const selectedService = filters[partnerService].data?.find(
        ({ value: serviceValue }) => serviceValue === value,
      );
      setValueFilter({ name: value, id: selectedService?.id }, name);
    }
  };

  return (
    <>
      <div className={directionStyles}>
        <KrDropdown
          placeholder="Направления"
          variants={filters[directions].data}
          value={selectedFilters[directions]?.value?.name}
          onSelect={(value) => setValueServiceFilter(value, directions)}
          disabled={isLoading}
          isLoading={isLoading}
          onChange={(value) => setValueServiceFilter(value, directions)}
          clearable
        />
      </div>
      <div className={serviceStyles}>
        <KrDropdown
          placeholder="Услуги"
          variants={
            servicesList.length && selectedFilters[directions]?.value?.name
              ? servicesList
              : filters[partnerService]?.data
          }
          value={selectedFilters[partnerService]?.value?.name}
          onSelect={(value) => setValueServiceFilter(value, partnerService)}
          onChange={(value) => setValueServiceFilter(value, partnerService)}
          clearable
          disabled={isLoading}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default PartnerServicesFilters;
