import {
  all,
} from 'redux-saga/effects';

import user from './user';
import { sagas as serviceProfile } from '../../pages/ServiceProfile/effects';
import serviceSaga from '../../pages/Services/effects/sagas';
import serviceList from './services';
import accreditationAccesses from './accreditationAccesses';
import partnerServicesFilters from './partnerServicesFilters';
// import searchReports from './reports';

export default function* staticSagas() {
  yield all([
    user(),
    serviceProfile(''),
    serviceSaga(),
    serviceList(),
    accreditationAccesses(),
    partnerServicesFilters(),
    // searchReports(),
  ]);
}
