import actions from '../actions/requests';

export const initialState = {
  activeTab: 'rating_report',
  date: '',
  requestsList: [],
  isLoading: true,
  errorReport: null,
  filters: {
    startDate: '',
    endDate: '',
    thread: '',
    platform: '',
    service: '',
    country: '',
  },
};

const HANDLERS = {
  [actions.getRequestStart]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [actions.getRequestSuccess]: (state, payload) => ({
    ...state,
    requestsList: payload,
    isLoading: false,
  }),
  [actions.getRequestFailure]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [actions.setFilter]: (state, payload) => ({
    ...state,
    filters: payload,
  }),
};

export default function search(state = initialState, { type, payload }) {
  const handler = HANDLERS[type];

  return handler ? handler(state, payload) : state;
}
