import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import configAdapter from '../config/config';
import textLocales from '../locales';

const getAllowedLocales = (locales, jsonDefaultLocale) => locales
  .reduce((obj, { name, label, acceptedLanguages }) => {
    let json = textLocales[name];
    // на случай, если не будет json файла для заданного языка, взять json дефолтного
    if (!json) json = jsonDefaultLocale;
    return {
      ...obj,
      [name]: {
        label,
        acceptedLanguages,
        messages: json,
      },
    };
  }, {});

const DEFAULT_LANGUAGE = 'ru';
const LOCALE_NAME_CONFIG = 'com.rooxteam.widgets.locale';
const DEFAULT_LOCALE = configAdapter(`${LOCALE_NAME_CONFIG}.default`);
const DEFAULT_LOCALE_JSON = textLocales[DEFAULT_LOCALE];
const ALLOWED_LOCALES = configAdapter(`${LOCALE_NAME_CONFIG}.allowed`);

const PARSED_ALLOWED_LOCALES = ALLOWED_LOCALES
  ? JSON.parse(ALLOWED_LOCALES.replace(/'/g, '"'))
  : [];
const CURRENT_LOCALES = getAllowedLocales(PARSED_ALLOWED_LOCALES, DEFAULT_LOCALE_JSON);
const CURRENT_LOCALES_LIST = Object.keys(CURRENT_LOCALES);

const getCurrentLocale = () => {
  const {
    location: { pathname },
    locale_config: localeConfig,
  } = window;

  const systemLanguage = navigator.language.split(/[-_]/)[0];
  const isSystemLanguageIncludeCurrentLocales = CURRENT_LOCALES_LIST.includes(systemLanguage);
  const existedSystemLanguage = DEFAULT_LANGUAGE || (isSystemLanguageIncludeCurrentLocales && systemLanguage);

  const urlLanguage = pathname.split('/')[1];
  const isUrlLanguageIncludeCurrentLocales = urlLanguage && CURRENT_LOCALES_LIST.includes(urlLanguage);
  const existedUrlLanguage = isUrlLanguageIncludeCurrentLocales && urlLanguage;

  let locale = existedUrlLanguage || existedSystemLanguage || DEFAULT_LOCALE;
  const {
    locale: langReactSnap,
    isReactSnap,
  } = localeConfig;
  if (isReactSnap && CURRENT_LOCALES_LIST.includes(langReactSnap)) {
    locale = langReactSnap;
  }
  return {
    name: locale,
    ...CURRENT_LOCALES[locale],
  };
};

const defineLanguage = (newLocale) => {
  const { location: { pathname, href, origin } } = window;
  const splittedPaths = pathname.split('/').filter((i) => i);
  let pathWithLanguage = '';
  if (!splittedPaths.length) {
    pathWithLanguage = `${href}${newLocale}`;
  } else if (CURRENT_LOCALES_LIST.includes(splittedPaths[0])) {
    pathWithLanguage = `${origin}/${newLocale}${
      splittedPaths.length > 1 ? '/' : ''}${splittedPaths.slice(1).join('/')}`;
  }
  return pathWithLanguage;
};

const redirectWithNewLanguage = (locale) => {
  const pathWithLanguage = defineLanguage(locale);
  window.location.href = pathWithLanguage;
};

const getDefaultLocalesByElem = (name = '') => {
  const defaultLocalesElem = Object.keys(DEFAULT_LOCALE_JSON)
    .filter((key) => (key.split('.')[0] === name))
    .reduce((obj, key) => ({
      ...obj,
      [key]: DEFAULT_LOCALE_JSON[key],
    }), {});
  if (!defaultLocalesElem) return null;
  return defaultLocalesElem;
};

const getLocaleByName = (name, localeName) => {
  if (!name || !localeName) return '';
  const fullName = `${localeName}.${name}`;
  const defaultLocales = getDefaultLocalesByElem(localeName);
  if (!defaultLocales) return '';
  const defaultMessage = defaultLocales[fullName];
  if (!defaultMessage) return '';
  return defaultMessage;
};

const getFormattedMessage = (name, localeName, values = {}) => {
  const fullName = `${localeName}.${name}`;
  const defaultMessage = getLocaleByName(name, localeName);
  if (!defaultMessage) return '';
  return (
    <FormattedMessage
      id={fullName}
      defaultMessage={defaultMessage}
      values={values}
    />
  );
};

const TextFormattedMessage = ({ name, localeName, values }) => {
  const { formatMessage } = useIntl();
  const fullName = `${localeName}.${name}`;
  const defaultMessage = getLocaleByName(name, localeName);
  if (!defaultMessage) return '';
  return formatMessage({
    id: fullName,
    defaultMessage,
  }, values);
};

const getFormattedMessageForVariable = (name, localeName, values = {}) => (
  TextFormattedMessage({
    name,
    localeName,
    values,
  })
);

const setDocSettingsAccordingToCurLocale = (curLocale) => {
  const docLocale = 'html';
  const json = CURRENT_LOCALES[curLocale].messages;
  const titleCur = json[`${docLocale}.title`];
  const titleDef = DEFAULT_LOCALE_JSON[`${docLocale}.title`];
  document.documentElement.lang = curLocale;
  document.title = titleCur || titleDef;
};

const LOCALE = getCurrentLocale();
setDocSettingsAccordingToCurLocale(LOCALE.name);

// eslint-disable-next-line max-len
const getFormatedText = (name = '', localeName = '') => textLocales[getCurrentLocale().name][`${localeName}.${name}`] || '';

const i18nConfig = {
  locale: LOCALE,
  locales: PARSED_ALLOWED_LOCALES,
  redirectWithNewLanguage,
  getFormattedMessage,
  getFormattedMessageForVariable,
  getFormatedText,
};

export default i18nConfig;
