/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function Delete({
  width,
  height,
  fill,
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="#DB4444" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.3333 6.66666H15.8539L15.6348 5.78972C15.372 4.73778 14.4268 4 13.3427 4H10.6573C9.57316 4 8.62794 4.73778 8.36519 5.78978L8.14606 6.66666H4.66666C4.29847 6.66666 4 6.96516 4 7.33334C4 7.70153 4.29847 8 4.66666 8H5.36941L5.93109 18.1103C5.99009 19.1704 6.867 20 7.92872 20H16.0714C17.1331 20 18.01 19.1704 18.069 18.1104L18.6307 8H19.3333C19.7015 8 20 7.70153 20 7.33334C20 6.96516 19.7015 6.66666 19.3333 6.66666ZM9.65878 6.11294C9.77325 5.65469 10.185 5.33331 10.6573 5.33331H13.3427C13.8151 5.33331 14.2268 5.65469 14.3412 6.11287L14.4796 6.66666H9.52041L9.65878 6.11294ZM16.7378 18.0363C16.7181 18.3898 16.4255 18.6667 16.0714 18.6667H7.92872C7.57466 18.6667 7.28203 18.3898 7.26234 18.0363L6.70481 8H17.2953L16.7378 18.0363Z" fill={fill} />
      <path d="M9.33365 9.3335C8.96546 9.3335 8.66699 9.63197 8.66699 10.0002V16.6668C8.66699 17.035 8.96546 17.3335 9.33365 17.3335C9.70184 17.3335 10.0003 17.035 10.0003 16.6668V10.0002C10.0003 9.63197 9.70184 9.3335 9.33365 9.3335Z" fill={fill} />
      <path d="M12.0001 9.3335C11.632 9.3335 11.3335 9.63197 11.3335 10.0002V16.6668C11.3335 17.035 11.632 17.3335 12.0001 17.3335C12.3683 17.3335 12.6668 17.035 12.6668 16.6668V10.0002C12.6668 9.63197 12.3683 9.3335 12.0001 9.3335Z" fill={fill} />
      <path d="M14.6667 9.3335C14.2985 9.3335 14 9.63197 14 10.0002V16.6668C14 17.035 14.2985 17.3335 14.6667 17.3335C15.0348 17.3335 15.3333 17.035 15.3333 16.6668V10.0002C15.3333 9.63197 15.0348 9.3335 14.6667 9.3335Z" fill={fill} />
    </svg>
  );
}

Delete.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fill: PropTypes.string,
};

Delete.defaultProps = {
  width: 25,
  height: 25,
  fill: '#DB4444',
};

export default React.memo(Delete);
