import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { KrButton as ButtonLink, KrLoader } from 'elk-uikit';
import styles from './index.module.scss';

const Button = ({
  color, children, onClick, className, isLoading, disabled, outline,
}) => (outline ? (
  <ButtonLink
    wrapperClasses={{
        button: `${styles.button} ${className} ${styles[`outline${color}`]} ${disabled && styles.disabled}`,
      }}
    onClick={onClick}
  >
    {isLoading ? (
      <KrLoader />
    ) : children}
  </ButtonLink>
) : (
  <ButtonLink
    wrapperClasses={{
        button: `${styles.button} ${className} ${styles[color]} ${disabled && styles.disabled}`,
      }}
    onClick={onClick}
  >
    {isLoading ? (
      <KrLoader />
    ) : children}
  </ButtonLink>
  ));

Button.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
};

Button.defaultProps = {
  color: '',
  children: '',
  className: '',
  onClick: () => {},
  isLoading: false,
  disabled: false,
  outline: false,
};

export default memo(Button);
