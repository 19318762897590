import { call } from 'redux-saga/effects';
import axios from 'axios';
import get from 'lodash/get';

import constants from './constants';

const { MDM_URL } = constants;

const mdmAdapter = function* mdmAdapterFunction(options) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...get(options, 'headers', {}),
  };

  const reqOptions = {
    baseURL: MDM_URL,
    ...options,
    headers,
  };

  return yield call(axios, reqOptions);
};

export default mdmAdapter;
