import { createActions } from 'redux-actions';

import * as constants from '../constants/chat';

const requestTypes = [
  constants.SHOW_CHAT,
  constants.CHAT_REQUEST,
];

const types = [...requestTypes];

export default createActions(...types);
