import configAdapter from './config';

const getValuesFromConfigAdapter = (fields, url) => fields
  .reduce((obj, field) => ({
    ...obj,
    [field]: configAdapter(`${url}.${field}`),
  }), {});

export default {
  getValuesFromConfigAdapter,
};
