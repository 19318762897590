/* eslint-disable */
(function(f) {
  if (typeof exports === 'object' && typeof module !== 'undefined') {
    module.exports = f();
  } else if (typeof define === 'function' && define.amd) {
    define([], f);
  } else {
    var g;
    if (typeof window !== 'undefined') {
      g = window;
    } else if (typeof global !== 'undefined') {
      g = global;
    } else if (typeof self !== 'undefined') {
      g = self;
    } else {
      g = this;
    }
    g.sbjs = f();
  }
})(function() {
  var define, module, exports;
  return (function e(t, n, r) {
    function s(o, u) {
      if (!n[o]) {
        if (!t[o]) {
          var a = typeof require == 'function' && require;
          if (!u && a) return a(o, !0);
          if (i) return i(o, !0);
          var f = new Error("Cannot find module '" + o + "'");
          throw ((f.code = 'MODULE_NOT_FOUND'), f);
        }
        var l = (n[o] = { exports: {} });
        t[o][0].call(
          l.exports,
          function(e) {
            var n = t[o][1][e];
            return s(n ? n : e);
          },
          l,
          l.exports,
          e,
          t,
          n,
          r
        );
      }
      return n[o].exports;
    }
    var i = typeof require == 'function' && require;
    for (var o = 0; o < r.length; o++) s(r[o]);
    return s;
  })(
    {
      1: [
        function(_dereq_, module, exports) {
          'use strict';

          var terms = _dereq_('./terms'),
            utils = _dereq_('./helpers/utils');

          var data = {
            containers: {
              current: 'sbjs_current',
              current_extra: 'sbjs_current_add',
              first: 'sbjs_first',
              first_extra: 'sbjs_first_add',
              session: 'sbjs_session',
              udata: 'sbjs_udata',
              promocode: 'sbjs_promo',
            },

            service: {
              migrations: 'sbjs_migrations',
            },

            delimiter: '|||',

            aliases: {
              main: {
                type: 'typ',
                source: 'src',
                medium: 'mdm',
                campaign: 'cmp',
                content: 'cnt',
                term: 'trm',
              },

              extra: {
                fire_date: 'fd',
                entrance_point: 'ep',
                referer: 'rf',
              },

              session: {
                pages_seen: 'pgs',
                current_page: 'cpg',
              },

              udata: {
                visits: 'vst',
                ip: 'uip',
                agent: 'uag',
              },

              promo: 'code',
            },

            pack: {
              main: function(sbjs) {
                return (
                  data.aliases.main.type +
                  '=' +
                  sbjs.type +
                  data.delimiter +
                  data.aliases.main.source +
                  '=' +
                  sbjs.source +
                  data.delimiter +
                  data.aliases.main.medium +
                  '=' +
                  sbjs.medium +
                  data.delimiter +
                  data.aliases.main.campaign +
                  '=' +
                  sbjs.campaign +
                  data.delimiter +
                  data.aliases.main.content +
                  '=' +
                  sbjs.content +
                  data.delimiter +
                  data.aliases.main.term +
                  '=' +
                  sbjs.term
                );
              },

              extra: function(timezone_offset) {
                return (
                  data.aliases.extra.fire_date +
                  '=' +
                  utils.setDate(new Date(), timezone_offset) +
                  data.delimiter +
                  data.aliases.extra.entrance_point +
                  '=' +
                  document.location.href +
                  data.delimiter +
                  data.aliases.extra.referer +
                  '=' +
                  (document.referrer || terms.none)
                );
              },

              user: function(visits, user_ip) {
                return (
                  data.aliases.udata.visits +
                  '=' +
                  visits +
                  data.delimiter +
                  data.aliases.udata.ip +
                  '=' +
                  user_ip +
                  data.delimiter +
                  data.aliases.udata.agent +
                  '=' +
                  navigator.userAgent
                );
              },

              session: function(pages) {
                return (
                  data.aliases.session.pages_seen +
                  '=' +
                  pages +
                  data.delimiter +
                  data.aliases.session.current_page +
                  '=' +
                  document.location.href
                );
              },

              promo: function(promo) {
                return (
                  data.aliases.promo + '=' + utils.setLeadingZeroToInt(utils.randomInt(promo.min, promo.max), promo.max.toString().length)
                );
              },
            },
          };

          module.exports = data;
        },
        { './helpers/utils': 4, './terms': 9 },
      ],
      2: [
        function(_dereq_, module, exports) {
          'use strict';

          var delimiter = _dereq_('../data').delimiter;

          module.exports = {
            encodeData: function(s) {
              return s;
            },

            decodeData: function(s) {
              return s;
            },

            set: function(name, value, minutes) {
              var expires;

              if (minutes) {
                var date = new Date();
                date.setTime(date.getTime() + minutes * 60 * 1000);
                expires = date.getTime();
              } else {
                expires = null;
              }

              var ls = window.localStorage;
              if (value === null) {
                ls.removeItem(name);
              } else {
                ls.setItem(name, JSON.stringify({ value: value, expires: expires }));
              }
            },

            get: function(name) {
              var ls = window.localStorage,
                data = ls.getItem(name);

              if (data) {
                data = JSON.parse(data);
                if (data.expires === null || new Date(data.expires).getTime() > Date.now()) {
                  return data.value;
                } else {
                  ls.removeItem(name);
                }
              }
              return null;
            },

            destroy: function(name) {
              var ls = window.localStorage;
              ls.removeItem(name);
            },

            parse: function(yummy) {
              var cookies = [],
                data = {};

              if (typeof yummy === 'string') {
                cookies.push(yummy);
              } else {
                for (var prop in yummy) {
                  if (yummy.hasOwnProperty(prop)) {
                    cookies.push(yummy[prop]);
                  }
                }
              }

              for (var i1 = 0; i1 < cookies.length; i1++) {
                var cookie_array;
                data[this.unsbjs(cookies[i1])] = {};
                if (this.get(cookies[i1])) {
                  cookie_array = this.get(cookies[i1]).split(delimiter);
                } else {
                  cookie_array = [];
                }
                for (var i2 = 0; i2 < cookie_array.length; i2++) {
                  var tmp_array = cookie_array[i2].split('='),
                    result_array = tmp_array.splice(0, 1);
                  result_array.push(tmp_array.join('='));
                  data[this.unsbjs(cookies[i1])][result_array[0]] = this.decodeData(result_array[1]);
                }
              }

              return data;
            },

            unsbjs: function(string) {
              return string.replace('sbjs_', '');
            },
          };
        },
        { '../data': 1 },
      ],
      3: [
        function(_dereq_, module, exports) {
          'use strict';

          module.exports = {
            parse: function(str) {
              var o = this.parseOptions,
                m = o.parser[o.strictMode ? 'strict' : 'loose'].exec(str),
                uri = {},
                i = 14;

              while (i--) {
                uri[o.key[i]] = m[i] || '';
              }

              uri[o.q.name] = {};
              uri[o.key[12]].replace(o.q.parser, function($0, $1, $2) {
                if ($1) {
                  uri[o.q.name][$1] = $2;
                }
              });

              return uri;
            },

            parseOptions: {
              strictMode: false,
              key: [
                'source',
                'protocol',
                'authority',
                'userInfo',
                'user',
                'password',
                'host',
                'port',
                'relative',
                'path',
                'directory',
                'file',
                'query',
                'anchor',
              ],
              q: {
                name: 'queryKey',
                parser: /(?:^|&)([^&=]*)=?([^&]*)/g,
              },
              parser: {
                strict: /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
                loose: /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/,
              },
            },

            getParam: function(custom_params) {
              var query_string = {},
                query = custom_params ? custom_params : window.location.search.substring(1),
                vars = query.split('&');

              for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=');
                if (typeof query_string[pair[0]] === 'undefined') {
                  query_string[pair[0]] = pair[1];
                } else if (typeof query_string[pair[0]] === 'string') {
                  var arr = [query_string[pair[0]], pair[1]];
                  query_string[pair[0]] = arr;
                } else {
                  query_string[pair[0]].push(pair[1]);
                }
              }
              return query_string;
            },

            getHost: function(request) {
              return this.parse(request).host.replace('www.', '');
            },
          };
        },
        {},
      ],
      4: [
        function(_dereq_, module, exports) {
          'use strict';

          module.exports = {
            escapeRegexp: function(string) {
              return string.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
            },

            setDate: function(date, offset) {
              var utc_offset = date.getTimezoneOffset() / 60,
                now_hours = date.getHours(),
                custom_offset = offset || offset === 0 ? offset : -utc_offset;

              date.setHours(now_hours + utc_offset + custom_offset);

              var year = date.getFullYear(),
                month = this.setLeadingZeroToInt(date.getMonth() + 1, 2),
                day = this.setLeadingZeroToInt(date.getDate(), 2),
                hour = this.setLeadingZeroToInt(date.getHours(), 2),
                minute = this.setLeadingZeroToInt(date.getMinutes(), 2),
                second = this.setLeadingZeroToInt(date.getSeconds(), 2);

              return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
            },

            setLeadingZeroToInt: function(num, size) {
              var s = num + '';
              while (s.length < size) {
                s = '0' + s;
              }
              return s;
            },

            randomInt: function(min, max) {
              return Math.floor(Math.random() * (max - min + 1)) + min;
            },
          };
        },
        {},
      ],
      5: [
        function(_dereq_, module, exports) {
          'use strict';

          var data = _dereq_('./data'),
            terms = _dereq_('./terms'),
            cookies = _dereq_('./helpers/cookies'),
            uri = _dereq_('./helpers/uri'),
            utils = _dereq_('./helpers/utils'),
            params = _dereq_('./params'),
            migrations = _dereq_('./migrations');

          module.exports = function(prefs) {
            var p = params.fetch(prefs);
            var get_param = uri.getParam();
            var domain = p.domain.host,
              isolate = p.domain.isolate,
              lifetime = p.lifetime;

            migrations.go(lifetime, domain, isolate);

            var __sbjs_type, __sbjs_source, __sbjs_medium, __sbjs_campaign, __sbjs_content, __sbjs_term;

            function mainData() {
              var sbjs_data;
              if (
                typeof get_param.utm_source !== 'undefined' ||
                typeof get_param.utm_medium !== 'undefined' ||
                typeof get_param.utm_campaign !== 'undefined' ||
                typeof get_param.utm_content !== 'undefined' ||
                typeof get_param.utm_term !== 'undefined' ||
                typeof get_param.gclid !== 'undefined' ||
                typeof get_param.yclid !== 'undefined' ||
                typeof get_param[p.campaign_param] !== 'undefined'
              ) {
                setFirstAndCurrentExtraData();
                sbjs_data = getData(terms.traffic.utm);
              } else if (checkReferer(terms.traffic.organic)) {
                setFirstAndCurrentExtraData();
                sbjs_data = getData(terms.traffic.organic);
              } else if (!cookies.get(data.containers.session) && checkReferer(terms.traffic.referral)) {
                setFirstAndCurrentExtraData();
                sbjs_data = getData(terms.traffic.referral);
              } else if (!cookies.get(data.containers.first) && !cookies.get(data.containers.current)) {
                setFirstAndCurrentExtraData();
                sbjs_data = getData(terms.traffic.typein);
              } else {
                return cookies.get(data.containers.current);
              }

              return sbjs_data;
            }

            function getData(type) {
              switch (type) {
                case terms.traffic.utm:
                  __sbjs_type = terms.traffic.utm;

                  if (typeof get_param.utm_source !== 'undefined') {
                    __sbjs_source = get_param.utm_source;
                  } else if (typeof get_param.gclid !== 'undefined') {
                    __sbjs_source = 'google';
                  } else if (typeof get_param.yclid !== 'undefined') {
                    __sbjs_source = 'yandex';
                  } else {
                    __sbjs_source = terms.none;
                  }

                  if (typeof get_param.utm_medium !== 'undefined') {
                    __sbjs_medium = get_param.utm_medium;
                  } else if (typeof get_param.gclid !== 'undefined') {
                    __sbjs_medium = 'cpc';
                  } else if (typeof get_param.yclid !== 'undefined') {
                    __sbjs_medium = 'cpc';
                  } else {
                    __sbjs_medium = terms.none;
                  }

                  if (typeof get_param.utm_campaign !== 'undefined') {
                    __sbjs_campaign = get_param.utm_campaign;
                  } else if (typeof get_param[p.campaign_param] !== 'undefined') {
                    __sbjs_campaign = get_param[p.campaign_param];
                  } else if (typeof get_param.gclid !== 'undefined') {
                    __sbjs_campaign = 'google_cpc';
                  } else if (typeof get_param.yclid !== 'undefined') {
                    __sbjs_campaign = 'yandex_cpc';
                  } else {
                    __sbjs_campaign = terms.none;
                  }

                  __sbjs_content = get_param.utm_content || terms.none;
                  __sbjs_term = getUtmTerm() || terms.none;
                  break;

                case terms.traffic.organic:
                  __sbjs_type = terms.traffic.organic;
                  __sbjs_source = __sbjs_source || uri.getHost(document.referrer);
                  __sbjs_medium = terms.referer.organic;
                  __sbjs_campaign = terms.none;
                  __sbjs_content = terms.none;
                  __sbjs_term = terms.none;
                  break;

                case terms.traffic.referral:
                  __sbjs_type = terms.traffic.referral;
                  __sbjs_source = __sbjs_source || uri.getHost(document.referrer);
                  __sbjs_medium = __sbjs_medium || terms.referer.referral;
                  __sbjs_campaign = terms.none;
                  __sbjs_content = uri.parse(document.referrer).path;
                  __sbjs_term = terms.none;
                  break;

                case terms.traffic.typein:
                  __sbjs_type = terms.traffic.typein;
                  __sbjs_source = p.typein_attributes.source;
                  __sbjs_medium = p.typein_attributes.medium;
                  __sbjs_campaign = terms.none;
                  __sbjs_content = terms.none;
                  __sbjs_term = terms.none;
                  break;

                default:
                  __sbjs_type = terms.oops;
                  __sbjs_source = terms.oops;
                  __sbjs_medium = terms.oops;
                  __sbjs_campaign = terms.oops;
                  __sbjs_content = terms.oops;
                  __sbjs_term = terms.oops;
              }
              var sbjs_data = {
                type: __sbjs_type,
                source: __sbjs_source,
                medium: __sbjs_medium,
                campaign: __sbjs_campaign,
                content: __sbjs_content,
                term: __sbjs_term,
              };

              return data.pack.main(sbjs_data);
            }

            function getUtmTerm() {
              var referer = document.referrer;
              if (get_param.utm_term) {
                return get_param.utm_term;
              } else if (referer && uri.parse(referer).host && uri.parse(referer).host.match(/^(?:.*\.)?yandex\..{2,9}$/i)) {
                try {
                  return uri.getParam(uri.parse(document.referrer).query).text;
                } catch (err) {
                  return false;
                }
              } else {
                return false;
              }
            }

            function checkReferer(type) {
              var referer = document.referrer;
              switch (type) {
                case terms.traffic.organic:
                  return !!referer && checkRefererHost(referer) && isOrganic(referer);
                case terms.traffic.referral:
                  return !!referer && checkRefererHost(referer) && isReferral(referer);
                default:
                  return false;
              }
            }

            function checkRefererHost(referer) {
              if (p.domain) {
                if (!isolate) {
                  var host_regex = new RegExp('^(?:.*\\.)?' + utils.escapeRegexp(domain) + '$', 'i');
                  return !uri.getHost(referer).match(host_regex);
                } else {
                  return uri.getHost(referer) !== uri.getHost(domain);
                }
              } else {
                return uri.getHost(referer) !== uri.getHost(document.location.href);
              }
            }

            function isOrganic(referer) {
              var y_host = 'yandex',
                y_param = 'text',
                g_host = 'google';

              var y_host_regex = new RegExp('^(?:.*\\.)?' + utils.escapeRegexp(y_host) + '\\..{2,9}$'),
                y_param_regex = new RegExp('.*' + utils.escapeRegexp(y_param) + '=.*'),
                g_host_regex = new RegExp('^(?:www\\.)?' + utils.escapeRegexp(g_host) + '\\..{2,9}$');

              if (
                !!uri.parse(referer).query &&
                !!uri.parse(referer).host.match(y_host_regex) &&
                !!uri.parse(referer).query.match(y_param_regex)
              ) {
                __sbjs_source = y_host;
                return true;
              } else if (!!uri.parse(referer).host.match(g_host_regex)) {
                __sbjs_source = g_host;
                return true;
              } else if (!!uri.parse(referer).query) {
                for (var i = 0; i < p.organics.length; i++) {
                  if (
                    uri.parse(referer).host.match(new RegExp('^(?:.*\\.)?' + utils.escapeRegexp(p.organics[i].host) + '$', 'i')) &&
                    uri.parse(referer).query.match(new RegExp('.*' + utils.escapeRegexp(p.organics[i].param) + '=.*', 'i'))
                  ) {
                    __sbjs_source = p.organics[i].display || p.organics[i].host;
                    return true;
                  }
                  if (i + 1 === p.organics.length) {
                    return false;
                  }
                }
              } else {
                return false;
              }
            }

            function isReferral(referer) {
              if (p.referrals.length > 0) {
                for (var i = 0; i < p.referrals.length; i++) {
                  if (uri.parse(referer).host.match(new RegExp('^(?:.*\\.)?' + utils.escapeRegexp(p.referrals[i].host) + '$', 'i'))) {
                    __sbjs_source = p.referrals[i].display || p.referrals[i].host;
                    __sbjs_medium = p.referrals[i].medium || terms.referer.referral;
                    return true;
                  }
                  if (i + 1 === p.referrals.length) {
                    __sbjs_source = uri.getHost(referer);
                    return true;
                  }
                }
              } else {
                __sbjs_source = uri.getHost(referer);
                return true;
              }
            }

            function setFirstAndCurrentExtraData() {
              cookies.set(data.containers.current_extra, data.pack.extra(p.timezone_offset), lifetime, domain, isolate);
              if (!cookies.get(data.containers.first_extra)) {
                cookies.set(data.containers.first_extra, data.pack.extra(p.timezone_offset), lifetime, domain, isolate);
              }
            }

            (function setData() {
              // Main data
              cookies.set(data.containers.current, mainData(), lifetime, domain, isolate);
              if (!cookies.get(data.containers.first)) {
                cookies.set(data.containers.first, cookies.get(data.containers.current), lifetime, domain, isolate);
              }

              // User data
              var visits, udata;
              if (!cookies.get(data.containers.udata)) {
                visits = 1;
                udata = data.pack.user(visits, p.user_ip);
              } else {
                visits =
                  parseInt(cookies.parse(data.containers.udata)[cookies.unsbjs(data.containers.udata)][data.aliases.udata.visits]) || 1;
                visits = cookies.get(data.containers.session) ? visits : visits + 1;
                udata = data.pack.user(visits, p.user_ip);
              }
              cookies.set(data.containers.udata, udata, lifetime, domain, isolate);

              // Session
              var pages_count;
              if (!cookies.get(data.containers.session)) {
                pages_count = 1;
              } else {
                pages_count =
                  parseInt(
                    cookies.parse(data.containers.session)[cookies.unsbjs(data.containers.session)][data.aliases.session.pages_seen]
                  ) || 1;
                pages_count += 1;
              }
              cookies.set(data.containers.session, data.pack.session(pages_count), p.session_length, domain, isolate);

              // Promocode
              if (p.promocode && !cookies.get(data.containers.promocode)) {
                cookies.set(data.containers.promocode, data.pack.promo(p.promocode), lifetime, domain, isolate);
              }
            })();

            return cookies.parse(data.containers);
          };
        },
        { './data': 1, './helpers/cookies': 2, './helpers/uri': 3, './helpers/utils': 4, './migrations': 6, './params': 7, './terms': 9 },
      ],
      6: [
        function(_dereq_, module, exports) {
          'use strict';

          var data = _dereq_('./data'),
            cookies = _dereq_('./helpers/cookies');

          module.exports = {
            go: function(lifetime, domain, isolate) {
              var migrate = this.migrations,
                _with = { l: lifetime, d: domain, i: isolate };

              var i;

              if (!cookies.get(data.containers.first) && !cookies.get(data.service.migrations)) {
                var mids = [];
                for (i = 0; i < migrate.length; i++) {
                  mids.push(migrate[i].id);
                }

                var advance = '';
                for (i = 0; i < mids.length; i++) {
                  advance += mids[i] + '=1';
                  if (i < mids.length - 1) {
                    advance += data.delimiter;
                  }
                }
                cookies.set(data.service.migrations, advance, _with.l, _with.d, _with.i);
              } else if (!cookies.get(data.service.migrations)) {
                // We have only one migration for now, so just
                for (i = 0; i < migrate.length; i++) {
                  migrate[i].go(migrate[i].id, _with);
                }
              }
            },

            migrations: [
              {
                id: '1418474375998',
                version: '1.0.0-beta',
                go: function(mid, _with) {
                  var success = mid + '=1',
                    fail = mid + '=0';

                  var safeReplace = function($0, $1, $2) {
                    return $1 || $2 ? $0 : data.delimiter;
                  };

                  try {
                    // Switch delimiter and renew cookies
                    var _in = [];
                    for (var prop in data.containers) {
                      if (data.containers.hasOwnProperty(prop)) {
                        _in.push(data.containers[prop]);
                      }
                    }

                    for (var i = 0; i < _in.length; i++) {
                      if (cookies.get(_in[i])) {
                        var buffer = cookies.get(_in[i]).replace(/(\|)?\|(\|)?/g, safeReplace);
                        cookies.destroy(_in[i], _with.d, _with.i);
                        cookies.destroy(_in[i], _with.d, !_with.i);
                        cookies.set(_in[i], buffer, _with.l, _with.d, _with.i);
                      }
                    }

                    // Update `session`
                    if (cookies.get(data.containers.session)) {
                      cookies.set(data.containers.session, data.pack.session(0), _with.l, _with.d, _with.i);
                    }

                    // Yay!
                    cookies.set(data.service.migrations, success, _with.l, _with.d, _with.i);
                  } catch (err) {
                    // Oops
                    cookies.set(data.service.migrations, fail, _with.l, _with.d, _with.i);
                  }
                },
              },
            ],
          };
        },
        { './data': 1, './helpers/cookies': 2 },
      ],
      7: [
        function(_dereq_, module, exports) {
          'use strict';

          var terms = _dereq_('./terms'),
            uri = _dereq_('./helpers/uri');

          module.exports = {
            fetch: function(prefs) {
              var user = prefs || {},
                params = {};

              // Set `lifetime of the cookie` in months
              params.lifetime = this.validate.checkFloat(user.lifetime) || 6;
              params.lifetime = parseInt(params.lifetime * 30 * 24 * 60);

              // Set `session length` in minutes
              params.session_length = this.validate.checkInt(user.session_length) || 30;

              // Set `timezone offset` in hours
              params.timezone_offset = this.validate.checkInt(user.timezone_offset);

              // Set `campaign param` for AdWords links
              params.campaign_param = user.campaign_param || false;

              // Set `user ip`
              params.user_ip = user.user_ip || terms.none;

              // Set `promocode`
              if (user.promocode) {
                params.promocode = {};
                params.promocode.min = parseInt(user.promocode.min) || 100000;
                params.promocode.max = parseInt(user.promocode.max) || 999999;
              } else {
                params.promocode = false;
              }

              // Set `typein attributes`
              if (user.typein_attributes && user.typein_attributes.source && user.typein_attributes.medium) {
                params.typein_attributes = {};
                params.typein_attributes.source = user.typein_attributes.source;
                params.typein_attributes.medium = user.typein_attributes.medium;
              } else {
                params.typein_attributes = { source: '(direct)', medium: '(none)' };
              }

              // Set `domain`
              if (user.domain && this.validate.isString(user.domain)) {
                params.domain = { host: user.domain, isolate: false };
              } else if (user.domain && user.domain.host) {
                params.domain = user.domain;
              } else {
                params.domain = { host: uri.getHost(document.location.hostname), isolate: false };
              }

              // Set `referral sources`
              params.referrals = [];

              if (user.referrals && user.referrals.length > 0) {
                for (var ir = 0; ir < user.referrals.length; ir++) {
                  if (user.referrals[ir].host) {
                    params.referrals.push(user.referrals[ir]);
                  }
                }
              }

              // Set `organic sources`
              params.organics = [];

              if (user.organics && user.organics.length > 0) {
                for (var io = 0; io < user.organics.length; io++) {
                  if (user.organics[io].host && user.organics[io].param) {
                    params.organics.push(user.organics[io]);
                  }
                }
              }

              params.organics.push({ host: 'bing.com', param: 'q', display: 'bing' });
              params.organics.push({ host: 'yahoo.com', param: 'p', display: 'yahoo' });
              params.organics.push({ host: 'about.com', param: 'q', display: 'about' });
              params.organics.push({ host: 'aol.com', param: 'q', display: 'aol' });
              params.organics.push({ host: 'ask.com', param: 'q', display: 'ask' });
              params.organics.push({ host: 'globososo.com', param: 'q', display: 'globo' });
              params.organics.push({ host: 'go.mail.ru', param: 'q', display: 'go.mail.ru' });
              params.organics.push({ host: 'rambler.ru', param: 'query', display: 'rambler' });
              params.organics.push({ host: 'tut.by', param: 'query', display: 'tut.by' });

              params.referrals.push({ host: 't.co', display: 'twitter.com' });
              params.referrals.push({ host: 'plus.url.google.com', display: 'plus.google.com' });

              return params;
            },

            validate: {
              checkFloat: function(v) {
                return v && this.isNumeric(parseFloat(v)) ? parseFloat(v) : false;
              },

              checkInt: function(v) {
                return v && this.isNumeric(parseInt(v)) ? parseInt(v) : false;
              },

              isNumeric: function(v) {
                return !isNaN(v);
              },

              isString: function(v) {
                return Object.prototype.toString.call(v) === '[object String]';
              },
            },
          };
        },
        { './helpers/uri': 3, './terms': 9 },
      ],
      8: [
        function(_dereq_, module, exports) {
          'use strict';

          var init = _dereq_('./init');

          var sbjs = {
            init: function(prefs) {
              this.get = init(prefs);
              if (prefs && prefs.callback && typeof prefs.callback === 'function') {
                prefs.callback(this.get);
              }
            },
          };

          module.exports = sbjs;
        },
        { './init': 5 },
      ],
      9: [
        function(_dereq_, module, exports) {
          'use strict';

          module.exports = {
            traffic: {
              utm: 'utm',
              organic: 'organic',
              referral: 'referral',
              typein: 'typein',
            },

            referer: {
              referral: 'referral',
              organic: 'organic',
              social: 'social',
            },

            none: '(none)',
            oops: '(Houston, we have a problem)',
          };
        },
        {},
      ],
    },
    {},
    [8]
  )(8);
});
