/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

function ArrowDown({
  width,
  height,
  fill,
  transform,
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" transform={transform} d="M0.195262 0.168746C0.455612 -0.0562488 0.877722 -0.0562487 1.13807 0.168746L6 4.37044L10.8619 0.168747C11.1223 -0.0562484 11.5444 -0.0562483 11.8047 0.168747C12.0651 0.393742 12.0651 0.758531 11.8047 0.983526L6 6L0.195262 0.983525C-0.0650874 0.75853 -0.0650874 0.393741 0.195262 0.168746Z" fill={fill} />
    </svg>
  );
}

ArrowDown.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  transform: PropTypes.string,
};

ArrowDown.defaultProps = {
  width: 12,
  height: 6,
  fill: '#6177FF',
  transform: '',
};

export default memo(ArrowDown);
