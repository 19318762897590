import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/promise';
import 'unorm';
import React from 'react';
import { render } from 'react-dom';
import Promise from 'es6-promise';
import { IntlProvider } from 'react-intl';
import { i18n } from './utils';

import './styles/index.scss';
import App from './App';

const { locale } = i18n;

Promise.polyfill();

const renderApp = () => {
  render(
    <IntlProvider
      locale={locale.name}
      messages={locale.messages}
    >
      <App />
    </IntlProvider>,
    document.getElementById('root'),
  );
};

renderApp();
