import React, {
  memo,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  useDispatch,
} from 'react-redux';

import { Typography, KrLoader } from 'elk-uikit';
import cn from 'classnames';
import BreadCrumbs from '../BreadCrumbs';
import utilsActions from '../../store/actions/utils';
import styles from './index.module.scss';

const PageContainer = ({
  breadCrumbs,
  title,
  children,
  description,
  actionBlock,
  isLoading,
  classNameHeaderWrapper,
}) => {
  const dispatch = useDispatch();

  const handleResize = useCallback(() => {
    dispatch(utilsActions.handleOnResize({
      width: window.innerWidth,
    }));
  }, [dispatch]);

  useEffect(() => {
    handleResize();
    dispatch(utilsActions.setReadyPage());
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch, handleResize]);

  return (
    <>
      <KrLoader isLoading={isLoading} isCentered>
        <div id='pageContainer' className={styles.pageContentWrapper}>
          <div className={cn(styles.header, classNameHeaderWrapper)}>
            <div className={styles.infoBlock}>
              <div>
                <BreadCrumbs arrayBreadcrumbs={breadCrumbs} />
              </div>
              <div className={styles.title}>
                <Typography variant="h1">
                  {title}
                </Typography>
              </div>
              <div className={styles.description}>
                {description}
              </div>
            </div>
            <div className={styles.actionBlock}>
              {actionBlock}
            </div>
          </div>
          <div className={styles.mainContent}>
            {children}
          </div>
        </div>
      </KrLoader>
    </>

  );
};

PageContainer.propTypes = {
  breadCrumbs: PropTypes.arrayOf(PropTypes.shape({
    page: PropTypes.string,
    url: PropTypes.string,
  })),
  title: PropTypes.string,
  children: PropTypes.oneOfType(PropTypes.node, PropTypes.string),
  description: PropTypes.oneOfType(PropTypes.node, PropTypes.string),
  actionBlock: PropTypes.node,
  isLoading: PropTypes.bool,
  classNameHeaderWrapper: PropTypes.string,
};

PageContainer.defaultProps = {
  breadCrumbs: [],
  title: '',
  children: null,
  description: null,
  actionBlock: null,
  isLoading: false,
  classNameHeaderWrapper: PropTypes.string,
};

export default memo(PageContainer);
