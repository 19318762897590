import actions from '../actions/partnerServicesFilters';
import { filtersNames } from '../constants/partnerServicesFilters';

const {
  getPartnerServicesFiltersRequest,
  getPartnerServicesFiltersSuccess,
  getPartnerServicesFiltersFailure,
  clearPartnerServicesFilters,
} = actions;

export const DEFAULT_FILTERS = {
  [filtersNames.directions]: { value: '', data: [] },
  [filtersNames.partnerService]: { value: '', data: [] },
};

export const initialState = {
  isLoading: false,
  error: null,
  filters: DEFAULT_FILTERS,
};

const HANDLERS = {
  [clearPartnerServicesFilters]: (state) => ({
    ...state,
    filters: DEFAULT_FILTERS,
  }),
  [getPartnerServicesFiltersRequest]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [getPartnerServicesFiltersSuccess]: (state, { name, value }) => ({
    ...state,
    isLoading: false,
    filters: {
      ...state.filters,
      [name]: {
        value: state.filters[name].value,
        data: value,
      },
    },
  }),
  [getPartnerServicesFiltersFailure]: (state, payload) => ({
    ...state,
    isLoading: false,
    error: payload,
  }),
  ...initialState,
};

export default function search(state = initialState, { type, payload }) {
  const handler = HANDLERS[type];

  return handler ? handler(state, payload) : state;
}
