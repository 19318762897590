/* eslint no-bitwise: 0 */
/* eslint no-plusplus: 0 */

export default function calculateChecksum(buffer) {
  let result = 0;
  let i;
  const bytes = [];

  for (i = 0; i < buffer.length; i++) {
    const code = buffer.charCodeAt(i);
    if (code < 128) {
      bytes.push(code);
    } else if (code < 2048) {
      bytes.push(192 + (code >> 6));
      bytes.push(128 + (code & 63));
    } else if (code < 65536) {
      bytes.push(224 + (code >> 12));
      bytes.push(128 + ((code >> 6) & 63));
      bytes.push(128 + (code & 63));
    } else if (code < 2097152) {
      bytes.push(240 + (code >> 18));
      bytes.push(128 + ((code >> 12) & 63));
      bytes.push(128 + ((code >> 6) & 63));
      bytes.push(128 + (code & 63));
    }
  }

  for (i = 0; i < bytes.length; i++) {
    let toShift;

    if (i % 3 === 0 || i % 5 === 2) {
      toShift = bytes[i] > 127 ? 0xffffff00 | bytes[i] : bytes[i];
      result ^= toShift << (i % 24);
    } else {
      result ^= (0xff & ~bytes[i]) << (i % 24);
    }
  }

  return result;
}
