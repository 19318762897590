import { call } from 'redux-saga/effects';
import axios from 'axios';
import { get } from 'lodash';

import constants from './constants';

const { DADATA_URL, TOKEN } = constants;

function* dadataAdapter(options) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Token ${TOKEN}`,
    ...get(options, 'headers', {}),
  };

  const reqOptions = {
    baseURL: DADATA_URL,
    ...options,
    headers,
  };

  return yield call(axios, reqOptions);
}

export default dadataAdapter;
