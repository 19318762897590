/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function Circle({
  width,
  height,
  stroke,
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="6" cy="6" r="5" stroke={stroke} strokeWidth="2" />
    </svg>
  );
}

Circle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  stroke: PropTypes.string,
};

Circle.defaultProps = {
  width: 16,
  height: 16,
  stroke: '#1890FF',
};

export default React.memo(Circle);
