import { call } from 'redux-saga/effects';
import axios from 'axios';
import get from 'lodash/get';

import i18n from '../../i18nConfig';
import cookiesUtils from '../../cookiesUtils';
import constants from './constants';

const { getCookie } = cookiesUtils;
const { TASKS_URL } = constants;
const { locale: { acceptedLanguages } } = i18n;

function* tasksAdapter(options) {
  const token = getCookie('digital_mp_at');
  const headers = {
    Accept: 'application/json',
    'Accept-Language': acceptedLanguages,
    'Content-Type': 'application/json',
    Authorization: `Bearer sso_1.0_${token}`,
    ...get(options, 'headers', {}),
  };

  const reqOptions = {
    baseURL: TASKS_URL,
    ...options,
    headers,
  };

  return yield call(axios, reqOptions);
}

export default tasksAdapter;
