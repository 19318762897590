/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import theme from '../../styles/theme';

function Check({
  width,
  height,
  fill,
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2C6.47717 2 2 6.47711 2 12C2 17.5228 6.47717 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47711 17.5228 2 12 2ZM11.082 16.1338L6.98389 12.0352L8.39795 10.6211L11.043 13.2666L15.6099 8.44043L17.062 9.81543L11.082 16.1338Z"
        fill={fill}
      />
    </svg>
  );
}

Check.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

Check.defaultProps = {
  width: 24,
  height: 24,
  fill: theme.third,
};

export default React.memo(Check);
