import React from 'react';
import intersection from 'lodash/intersection';
import { useSelector } from 'react-redux';

import ErrorNotAccess from '../../components/ErrorNotAccess';

export default function accessHoc(WrappedComponent, accessRoles) {
  return (props) => {
    const { rolesCurrentOrgId } = useSelector((state) => state.auth.data);

    const hasAccess = Boolean(intersection(accessRoles, rolesCurrentOrgId).length);

    if (!hasAccess) return <ErrorNotAccess />;

    return <WrappedComponent {...props} />;
  };
}
