import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Icons, Button } from '..';
import styles from './index.module.scss';

const { Reload } = Icons;

const RefreshButton = ({ onClick, disabled }) => (
  <Button
    outline
    color="dodgerBlue"
    disabled={disabled}
    onClick={onClick}
    className={styles.button}
  >
    <div className={styles.reloadButton}>
      <Reload />
    </div>
  </Button>
);

RefreshButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

RefreshButton.defaultProps = {
  onClick: () => {
  },
  disabled: false,
};

export default memo(RefreshButton);
