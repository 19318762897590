import { createActions } from 'redux-actions';

import * as constants from '../constants/user';

export const {
  sendInviteRequest,
  sendInviteSuccess,
  sendInviteFailure,
  resetInviteSuccessStatus,
  getUsersRequest,
  getUsersSuccess,
  getUsersFailure,
  getCurrentUser,
  showUserInfo,
  showModalInvite,
  showModalBlock,
  showModalInfoUserInvite,
  getCurrentInviteUser,
  sendUsersBlockRequest,
  sendUsersBlockSuccess,
  sendUsersBlockFailure,
  sendUsersUnblockRequest,
  sendUsersUnblockSuccess,
  sendUsersUnblockFailure,
  sendUserBlockRequest,
  sendUserBlockSuccess,
  sendUserBlockFailure,
  sendUserUnblockRequest,
  sendUserUnblockSuccess,
  sendUserUnblockFailure,
  showSnackBar,
  getPartnerAccreditationsCountRequest,
  getPartnerAccreditationsCountSuccess,
  getPartnerAccreditationsCountFailure,
} = createActions(
  constants.SEND_INVITE_REQUEST,
  constants.SEND_INVITE_SUCCESS,
  constants.SEND_INVITE_FAILURE,
  constants.RESET_INVITE_SUCCESS_STATUS,
  constants.GET_USERS_REQUEST,
  constants.GET_USERS_SUCCESS,
  constants.GET_USERS_FAILURE,
  constants.GET_CURRENT_USER,
  constants.SHOW_USER_INFO,
  constants.SHOW_MODAL_INVITE,
  constants.SHOW_MODAL_BLOCK,
  constants.SHOW_MODAL_INFO_USER_INVITE,
  constants.GET_CURRENT_INVITE_USER,
  constants.SEND_USERS_BLOCK_REQUEST,
  constants.SEND_USERS_BLOCK_SUCCESS,
  constants.SEND_USERS_BLOCK_FAILURE,
  constants.SEND_USERS_UNBLOCK_REQUEST,
  constants.SEND_USERS_UNBLOCK_SUCCESS,
  constants.SEND_USERS_UNBLOCK_FAILURE,
  constants.SEND_USER_BLOCK_REQUEST,
  constants.SEND_USER_BLOCK_SUCCESS,
  constants.SEND_USER_BLOCK_FAILURE,
  constants.SEND_USER_UNBLOCK_REQUEST,
  constants.SEND_USER_UNBLOCK_SUCCESS,
  constants.SEND_USER_UNBLOCK_FAILURE,
  constants.SHOW_SNACK_BAR,
  constants.GET_PARTNER_ACCREDITATIONS_COUNT_REQUEST,
  constants.GET_PARTNER_ACCREDITATIONS_COUNT_SUCCESS,
  constants.GET_PARTNER_ACCREDITATIONS_COUNT_FAILURE,
);
