import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'elk-uikit';

import styles from './index.module.scss';

const InfoField = ({
  title,
  titleSize,
  titleBold,
  titleColor,
  styleTitle,
  description,
  descriptionSize,
  descriptionBold,
  descriptionColor,
  value,
  valueSize,
  valueBold,
  valueColor,
  styleValue,
}) => (
  <div className={styles.wrapper}>
    <Typography
      size={titleSize}
      bold={titleBold}
      color={titleColor}
      style={styleTitle}
    >
      {title}
    </Typography>
    {description && (
      <Typography
        size={descriptionSize}
        bold={descriptionBold}
        color={descriptionColor}
        style={styleTitle}
      >
        {description}
      </Typography>
    )}
    {value
      ? (
        <Typography
          size={valueSize}
          bold={valueBold}
          color={valueColor}
          style={styleValue}
        >
          {value}
        </Typography>
      )
      : (
        <span className={styles.emptyValue}>&mdash;</span>
      )}
  </div>
  );

InfoField.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  titleSize: PropTypes.string,
  titleBold: PropTypes.bool,
  titleColor: PropTypes.string,
  styleTitle: PropTypes.objectOf(PropTypes.string),
  description: PropTypes.string,
  descriptionSize: PropTypes.string,
  descriptionBold: PropTypes.bool,
  descriptionColor: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  styleValue: PropTypes.objectOf(PropTypes.string),
  valueSize: PropTypes.string,
  valueBold: PropTypes.bool,
  valueColor: PropTypes.string,
};

InfoField.defaultProps = {
  title: '',
  titleSize: 'medium',
  titleBold: false,
  titleColor: 'darkGrey',
  styleTitle: {},
  description: '',
  descriptionSize: 'medium',
  descriptionBold: false,
  descriptionColor: 'darkGrey',
  value: '',
  valueSize: 'medium',
  valueBold: false,
  valueColor: 'darkGrey',
  styleValue: {},
};

export default memo(InfoField);
