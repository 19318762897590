import { createActions } from 'redux-actions';

const syncTypes = [
  'GET_ACCREDITATION_HISTORY',
  'GET_ACCREDITATION_HISTORY_FAILURE',
  'GET_ACCREDITATION_HISTORY_SUCCESS',

  'SET_DRAFT_STATUS',
  'SET_DRAFT_STATUS_FAILURE',
  'SET_DRAFT_STATUS_SUCCESS',

  'GET_ATTACHMENTS',
  'GET_ATTACHMENTS_SUCCESS',
];

export default createActions(...syncTypes);
