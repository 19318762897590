/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

function Attachment({
  width,
  height,
  fill,
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3223 5.82717C15.0612 5.56751 14.6391 5.56866 14.3794 5.8297L6.36525 13.8872C5.32551 14.927 3.63681 14.927 2.59513 13.8858C1.55423 12.8444 1.55423 11.1557 2.59526 10.1147L10.8467 1.81992C11.4958 1.17081 12.5512 1.17081 13.2022 1.82132C13.853 2.47215 13.853 3.52739 13.202 4.17841L6.3665 11.0139L6.36525 11.0153C6.10484 11.2742 5.68399 11.2739 5.4241 11.014C5.16379 10.7537 5.16379 10.3318 5.4241 10.0715L8.72356 6.77131C8.98388 6.51093 8.98384 6.0888 8.72346 5.82848C8.46308 5.56816 8.04095 5.56819 7.78063 5.82857L4.48121 9.12865C3.70022 9.90964 3.70022 11.1758 4.48127 11.9568C5.26229 12.7378 6.52844 12.7378 7.30949 11.9568L7.31199 11.954L14.1448 5.12118C15.3166 3.94947 15.3166 2.04999 14.1448 0.878271C12.973 -0.292757 11.0736 -0.292757 9.90258 0.878271L1.65117 9.17309C0.090698 10.7336 0.0906978 13.2663 1.6523 14.8287C3.21493 16.3905 5.74767 16.3905 7.30937 14.8288L15.3248 6.77C15.5845 6.50893 15.5833 6.08683 15.3223 5.82717Z"
        fill={fill}
      />
    </svg>
  );
}

Attachment.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

Attachment.defaultProps = {
  width: 24,
  height: 24,
  fill: '#6177ff',
};

export default memo(Attachment);
