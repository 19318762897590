import { createActions } from 'redux-actions';

const requestTypes = [
  'TOGGLE_CERTIFICATES_MODAL',
  'GET_ALL_DOCUMENTS_FOR_SIGN',
  'GET_DOCUMENT_FOR_SIGN',
  'GET_DOCUMENT_FOR_SIGN_SUCCESS',
  'SAVE_CERTIFICATE',
];

const types = [...requestTypes];

export default createActions(...types);
