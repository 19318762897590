import configAdapter from '../../../config/config';

const API_URL_V1 = '/api/v1';
const ORG_URL = configAdapter('com.rooxteam.widgets.org.api.url');
const ORG = '/org-card/';
const REPORT = '/count-report';
const SEARCH_ORG = '/search-org';
const RESIDENT_PARAMS = {
  hideDeprecated: true,
};

export default {
  API_URL_V1,
  ORG_URL,
  ORG,
  REPORT,
  SEARCH_ORG,
  RESIDENT_PARAMS,
};
