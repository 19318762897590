import { createActions } from 'redux-actions';

const syncTypes = [
  'TOGGLE_MENU',
  'HANDLE_ON_RESIZE',
  'SET_READY_PAGE',
  'SET_HIDE_BANNER_BETA_VERSION',
  'SET_HEIGHT_FOOTER',
  'SET_MODAL_WINDOW_POSITION',
];

export default createActions(...syncTypes);
