import { call } from 'redux-saga/effects';
import axios from 'axios';
import get from 'lodash/get';

import i18n from '../../i18nConfig';
import constants from './constants';

const { ORG_URL } = constants;
const { locale: { acceptedLanguages } } = i18n;

function* orgAdapter(options) {
  const headers = {
    Accept: 'application/json',
    'Accept-Language': acceptedLanguages,
    'Content-Type': 'application/json',
    ...get(options, 'headers', {}),
  };

  const reqOptions = {
    baseURL: ORG_URL,
    ...options,
    headers,
  };

  return yield call(axios, reqOptions);
}

export default orgAdapter;
