import actions from './actions';

const {
  getBannerInfo,
  getBannerInfoSuccess,
  getBannerInfoFailure,
  closeBanner,
} = actions;

export const initialState = {
  isLoading: false,
  isShow: false,
  text: '',
  error: null,
};

const HANDLERS = {
  [getBannerInfo]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [getBannerInfoSuccess]: (state, { isShow, text }) => ({
    ...state,
    isShow,
    text,
  }),
  [getBannerInfoFailure]: (state, error) => ({
    ...state,
    error,
    isLoading: false,
  }),
  [closeBanner]: (state, { isShow, text = '' }) => ({
    ...state,
    isShow,
    text,
  }),
  ...initialState,
};

export default function exportersReducer(state = initialState, { type, payload }) {
  const handler = HANDLERS[type];
  return handler ? handler(state, payload) : state;
}
