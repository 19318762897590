import constants from './constants';

const {
  CUSTOMER,
  ORG,
  WEBAPI_OTP_SETTINGS,
  OTP,
} = constants;

const generateUrlUidmUsers = (orgId) => `${CUSTOMER}${ORG}/${orgId}/members`;
const generateUrlUidmCurUser = (orgId, userId) => `${generateUrlUidmUsers(orgId)}/${userId}`;
const generateUrlBlockUser = (orgId, userId) => `${generateUrlUidmCurUser(orgId, userId)}/block`;
const getOrgListUrl = (orgId) => `${CUSTOMER}${ORG}/${orgId}/members`;
const getUrlUidmUsers = (orgId, userId) => `${getOrgListUrl(orgId)}/${userId}`;
const getUrlChangingRolesUser = (orgId, userId) => `${getUrlUidmUsers(orgId, userId)}/roles`;
const getUrlBlockUser = (orgId, userId) => `${getUrlUidmUsers(orgId, userId)}/block`;
const getOtpUrl = () => `${WEBAPI_OTP_SETTINGS}${OTP}`;
const getOtpSendingUrl = `${WEBAPI_OTP_SETTINGS}${OTP}/send`;
const getOtpSettingsUrl = `${WEBAPI_OTP_SETTINGS}/@me${OTP}/settings/otp.login.enabled`;

export default {
  generateUrlUidmUsers,
  generateUrlBlockUser,
  getUrlChangingRolesUser,
  getUrlBlockUser,
  getOtpUrl,
  getOtpSendingUrl,
  getOtpSettingsUrl,
};
