import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  memo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'elk-uikit';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';

import { i18n } from 'utils';
import actions from '../../effects/actions';

import styles from './FormDocuments.module.scss';

const {
  getCompanyDocumentsFromForm,
  updateFormDocuments,
} = actions;
const { getFormattedMessage } = i18n;

const DEBOUNCE_TIME = 700;
const getDocuments = (dispatch, value, type) => {
  dispatch(getCompanyDocumentsFromForm({ value, type }));
};
const getDocumentsDebounced = debounce(getDocuments, DEBOUNCE_TIME);

function DocumentsDropdown({
  documentType,
  index,
  disabled,
}) {
  const dispatch = useDispatch();
  const dropdownDocuments = useSelector((state) => state.ServiceProfile.dropdownDocuments);
  const formDocuments = useSelector((state) => state.ServiceProfile.formDocumentsBuffer);
  const [dropdownValue, handleDropdownValue] = useState('');

  useEffect(() => {
    getDocumentsDebounced(dispatch, dropdownValue, documentType);
  }, [dispatch, dropdownValue, documentType]);

  const variants = useMemo(() => (
    dropdownDocuments.reduce((acc, document) => {
      if (!formDocuments.some((item) => item.uuid === document.uuid)) {
        acc.push({
          value: document.caption,
          key: document.uuid,
        });
      }
      return acc;
    }, [])
  ), [formDocuments, dropdownDocuments]);

  const onSelectVariant = useCallback((value, key) => {
    const addedDocument = dropdownDocuments
      .find((item) => item.uuid === key);

    if (addedDocument) {
      handleDropdownValue('');
      const newDocument = {
        ...addedDocument,
        formProps: {
          renderType: 'ADDED',
        },
      };
      const updatedFormDocuments = [...formDocuments];
      updatedFormDocuments.splice(index + 1, 0, newDocument);
      dispatch(updateFormDocuments(updatedFormDocuments));
    }
  }, [dispatch, formDocuments, dropdownDocuments, index]);

  return (
    <div className={styles.dropdownWrapper}>
      <Dropdown
        name="multipleDocuments"
        label={getFormattedMessage('form.dropdown.multipleDocuments.label', 'doc')}
        variants={variants}
        value={dropdownValue}
        onChange={handleDropdownValue}
        onSelect={onSelectVariant}
        disabled={disabled}
        fullWidth
      />
    </div>
  );
}

DocumentsDropdown.propTypes = {
  documentType: PropTypes.string,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

DocumentsDropdown.defaultProps = {
  documentType: null,
  disabled: false,
};

export default memo(DocumentsDropdown);
