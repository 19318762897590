import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { RefreshButton, SearchButton } from '..';
import styles from './index.module.scss';

const ServiceButtonBlock = ({
  onSearch, onRefresh, refresh, searchDisabled,
}) => (
  <div className={styles.filter}>
    {refresh && <RefreshButton onClick={onRefresh} disabled={searchDisabled} />}
    <SearchButton disabled={searchDisabled} onClick={onSearch} />
  </div>
);

ServiceButtonBlock.defaultProps = {
  onSearch: () => {},
  onRefresh: () => {},
  refresh: true,
  searchDisabled: false,
};

ServiceButtonBlock.propTypes = {
  onSearch: PropTypes.func,
  onRefresh: PropTypes.func,
  refresh: PropTypes.bool,
  searchDisabled: PropTypes.bool,
};

export default memo(ServiceButtonBlock);
