/* eslint-disable */
export default {
  'com.rooxteam.widgets.org.api.url': `http://org-adapter.integration-dev.d.exportcenter.ru/org-adapter`,
  imagesBasePath: '/widgets/spa/assets/img/',
  'com.rooxteam.webapi.url': `http://uidm.uidm-dev.d.exportcenter.ru/uidm-webapi-1`,
  'com.rooxteam.webapi.timeout': 300000,
  'com.rooxteam.wms.oauth2.client_id': 'rec_elk_m2m',
  'com.rooxteam.widgets.logout_idle': `http://uidm.uidm-dev.d.exportcenter.ru/oauth2-consumer/logout?redirect_uri=${encodeURIComponent('http://nps-front.arm-services-dev.d.exportcenter.ru')}%2Fsso%2FUI%2FLogout%3Frealm%3D%2Fcustomer%26goto%3D${encodeURIComponent('http://nps-front.arm-services-dev.d.exportcenter.ru')}%26gotoOnFail%3D${encodeURIComponent('http://nps-front.arm-services-dev.d.exportcenter.ru')}`,
  'com.rooxteam.widgets.logout': `http://uidm.uidm-dev.d.exportcenter.ru/oauth2-consumer/logout?redirect_uri=%2F..%2Fsso%2FUI%2FLogout%3Frealm%3D%2Fcustomer%26goto%3D${encodeURIComponent('http://nps-front.arm-services-dev.d.exportcenter.ru')}%26gotoOnFail%3D${encodeURIComponent('http://nps-front.arm-services-dev.d.exportcenter.ru')}`,
  'com.rooxteam.widgets.login_iframe': `http://uidm.uidm-dev.d.exportcenter.ru/sso/oauth2/authorize?redirect_uri=${encodeURIComponent('http://uidm.uidm-dev.d.exportcenter.ru')}/oauth2-consumer/authorize&realm=/customer&response_type=code&client_id=rec_elk&state=goto%3D${encodeURIComponent('http://nps-front.arm-services-dev.d.exportcenter.ru')}%2Fwidgets%2Fsuccess.html%26gotoOnFail%3D${encodeURIComponent('http://nps-front.arm-services-dev.d.exportcenter.ru')}%2Fwidgets%2Ffailure.html%26client_id%3Drec_elk`,
  'com.rooxteam.uidm.block.limit': 7,
  'com.rooxteam.uidm.block.seconds': '60',
  'com.rooxteam.widgets.refresh.consumerUrl': `http://uidm.uidm-dev.d.exportcenter.ru/oauth2-consumer/refresh`,
  'com.rooxteam.widgets.refresh.popupLifetimeMs': 60000,
  'com.rooxteam.widgets.refresh.forceRefreshIntervalMs': 240000,
  'com.rooxteam.widgets.refresh.idleTimeoutMs': 240000,
  'com.rooxteam.widgets.armDmp.oauthConsumerBaseUrl': `http://uidm.uidm-dev.d.exportcenter.ru`,
  'com.rooxteam.widgets.armDmp.ssoBaseUrl': `http://uidm.uidm-dev.d.exportcenter.ru`,
  'com.rooxteam.widgets.armDmp.gotoUrl': `http://local.arm-digital-marketplace.arm-services-dev.d.exportcenter.ru:3000`,
  'com.rooxteam.widgets.armDmp.gotoOnFailUrl': `http://local.arm-digital-marketplace.arm-services-dev.d.exportcenter.ru#fail`,
  'com.rooxteam.widgets.armDmp.clientId': 'digital_mp',
  'com.rooxteam.widgets.armDmp.catalogs.documents': 'c00832',
  'com.rooxteam.widgets.armDmp.catalogs.invoice': 'c00990',
  'com.rooxteam.widgets.armDmp.catalogs.acts': 'c00833',
  'com.rooxteam.uidm.url': `${process.env.REACT_APP_UIDM_API}`,
  'com.rooxteam.widgets.digitalProfile.api.url': `http://digital-profile-exporter-api-svc-0.t.exportcenter.ru`,
  'com.rooxteam.widgets.chat.apiSecret': `${process.env.REACT_APP_CHAT_API_SECRET}`,
  'com.rooxteam.widgets.chat.constructorHost': `${process.env.REACT_APP_CHAT_CONSTRUCTOR_HOST}`,
  'com.rooxteam.widgets.chat.websocketHost': `${process.env.REACT_APP_CHAT_WEBSOCKET_HOST}`,
  'com.rooxteam.widgets.chat.widgetId': `${process.env.REACT_APP_CHAT_WIDGET_ID}`,
  'com.rooxteam.widgets.locale.default': `${process.env.REACT_APP_LOCALE_DEFAULT}`,
  'com.rooxteam.widgets.locale.allowed': `${process.env.REACT_APP_LOCALE_ALLOWED}`,
  'com.rooxteam.widgets.mdm.api.url': `${process.env.REACT_APP_MDM_API}`,
  'com.rooxteam.widgets.mdm.api.service.url': `${process.env.REACT_APP_MDM_API_SERVICE}`,
  'com.rooxteam.widgets.ecm.api.url': `${process.env.REACT_APP_ECM_API}`,
  'com.rooxteam.widgets.org.api.url': `${process.env.REACT_APP_ORG_API}`,
  'com.rooxteam.widgets.notify.api.url': `${process.env.REACT_APP_NOTIFY_API}`,
  'com.rooxteam.widgets.offers.api.url': `${process.env.REACT_APP_OFFERS_API}`,
  'com.rooxteam.widgets.bpmn.api.url': `${process.env.REACT_APP_BPMN_API}`,
  'com.rooxteam.widgets.tasks.api.url': `${process.env.REACT_APP_TASKS_API}`,
  'com.rooxteam.widgets.registryEditor.api.url': `${process.env.REACT_APP_DECLARATION_REGISTRY_EDITOR_API}`,
  'com.rooxteam.widgets.zvat.api.url': `${process.env.REACT_APP_ZVAT_API}`,
  'com.rooxteam.widgets.zvat.api.service.url': `${process.env.REACT_APP_ZVAT_API_SERVICE}`,
  'com.rooxteam.widgets.zvat.korus.api.url': `${process.env.REACT_APP_ZVAT_KORUS_API}`,
  'com.rooxteam.widgets.service_id_accreditation': '92c3aed1-dc00-4ab3-8bad-2378f0bb5672',
  'com.rooxteam.widgets.service_id_partner': 'cfc353be-23c2-4ede-9e59-26feb1c9f645',
  'com.rooxteam.widgets.service_id_partner_history_of_success': 'a81606f5-974f-4c38-913d-2e93edd108c9',
  'com.rooxteam.organization_role.permission.read': 'exporter_admin,partner_director,exporter_director',
  'com.rooxteam.organization_role.permission.members.modify': 'exporter_admin,partner_director,exporter_director',
  'com.rooxteam.organization_role.permission.members.block': 'exporter_admin,partner_director,exporter_director',
  'com.rooxteam.widgets.partner_landing_link': 'http://master-portal-dev.d.exportcenter.ru/partner-catalog/partner/?id=',
  'com.rooxteam.organization_role.permission.invite-principal': "exporter_admin,partner_director,exporter_director",
  'com.rooxteam.organization_role.permission.invite-principal-to-any-org': "ORG_INVITE_ADMIN",
  'com.rooxteam.widgets.armDmp.transport-type': "elk_new",
  "com.rooxteam.widgets.accDecision": "a697b830-9264-43a5-8cf9-5b4638f307d5",
  'com.rooxteam.widgets.partner_history_moderator': '4a27ff58-7ee0-488f-9cd1-6c7c5035513e',
  'com.rooxteam.widgets.partner_accreditation_decision': ["28dd35bf-7d82-42fa-aeb5-cc01b02d3941", "a697b830-9264-43a5-8cf9-5b4638f307d5"],
  'com.rooxteam.widgets.partner_accreditation_req_service':'Маркетплейсы. Размещение в национальных магазинах',
  'com.rooxteam.widgets.delivery_date_interval': 15,
  'com.rooxteam.widgets.partner_services.etp_acc_creation_national': '5ea06d20-c38c-46d2-a28b-ea830ade2fcc',
  'com.rooxteam.widgets.service_subcategories.electronic_commerce': '00687bce-66c8-495d-b09a-08cbf5324e81',
  'com.rooxteam.widgets.service_id_create_request_payment': '0ee0cd4a-f825-442c-968d-7691343b57e3',
  'com.rooxteam.widgets.service_id_paysrv_paymentInvoice_acc': 'ab2a8524-52da-442c-9eea-76c4f7d2aa7e',
  'com.rooxteam.widgets.paymentAccDecision': ["a697b830-9264-43a5-8cf9-5b4638f307d5", "28dd35bf-7d82-42fa-aeb5-cc01b02d3941", "03ff140e-d34b-45d0-9287-f7756ced1d85"]
};
   // TODO: Добавить в конфиг 
   // com.rooxteam.widgets.partner_accreditation_req_service
   // com.rooxteam.widgets.delivery_date_interval
   // com.rooxteam.widgets.partner_services.etp_acc_creation_national uuid из справочника partner_services код услуги etp_acc_creation_national
   // com.rooxteam.widgets.service_subcategories.electronic_commerce uuid из справочника service_subcategories  Электронная торговля
   // com.rooxteam.widgets.service_id_create_request_payment
   // com.rooxteam.widgets.service_id_paysrv_paymentInvoice_acc