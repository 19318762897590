import React, { memo } from 'react';
import { Typography, KrTooltip } from 'elk-uikit';
import PropTypes from 'prop-types';
import { Icons } from 'components';

import styles from '../../Raiting.module.scss';

const {
 Top3, Top5, Top10, TopQuantity, TopTime,
} = Icons;
const RAITING_TOOLTIP = 'Партнер входит в список лидеров по рейтингу.';
const QUANTITY_TOOLTIP = 'Партнер входит в список лидеров по количеству оказанных услуг.';
const SPEED_TOOLTIP = 'Партнер входит в список лидеров по скорости ответа на запросы.';

const AwardsBlock = ({
 award,
}) => (
  <div className={styles.achievementsBlock}>
    <Typography className={styles.raitingDescription}>Награды</Typography>
    <div className={styles.raitingAchievements}>
      {award?.awardSpeed && <KrTooltip title={SPEED_TOOLTIP} position="bottom"><TopTime /></KrTooltip>}
      {award?.awardQuantity && <KrTooltip title={QUANTITY_TOOLTIP} position="bottom"><TopQuantity /></KrTooltip>}
      {award?.awardRating === 'top3'
        && (
        <KrTooltip
          title={RAITING_TOOLTIP}
          position="bottom"
        >
          <Top3 />
        </KrTooltip>
)}
      {award?.awardRating === 'top5'
        && (
        <KrTooltip
          title={RAITING_TOOLTIP}
          position="bottom"
        >
          <Top5 />
        </KrTooltip>
)}
      {award?.awardRating === 'top10'
        && (
        <KrTooltip
          title={RAITING_TOOLTIP}
          position="bottom"
        >
          <Top10 />
        </KrTooltip>
)}
    </div>
  </div>
  );

AwardsBlock.propTypes = {
  award: PropTypes.shape({
    awardQuantity: PropTypes.bool,
    awardRating: PropTypes.string,
    awardSpeed: PropTypes.bool,
    isConfirmed: PropTypes.bool,
  }),
};

AwardsBlock.defaultProps = {
  award: {},
};

export default memo(AwardsBlock);
