import configAdapter from '../../../config/config';

const API_URL_V1 = '/api/v1';
const TASKS_URL = configAdapter('com.rooxteam.widgets.tasks.api.url');
// const TASKS_URL = 'http://tasks-api-adapter.integration-dev.d.exportcenter.ru/tasks-adapter';

const PROCESSES_CONTROLLER = '/processes';

export default {
  API_URL_V1,
  PROCESSES_CONTROLLER,
  TASKS_URL,
};
