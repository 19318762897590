/* eslint-disable */
import SHA1 from 'crypto-js/sha1';
import encBase64 from 'crypto-js/enc-base64';

let config = {};

function deepFilterWithConfig(content, inConfig) {
  config = inConfig;

  return deepFilter(content);
}

function shouldBeObfuscated(key) {
  const filters = config.OBFUSCATED_DATA;
  const re = new RegExp(filters.join('|'), 'i');

  return key.match(re) !== null;
}

/**
 * Parses URL parameters into an object.
 * @param {string} url - the url parameters to parse.
 * @return {Array.<string>} The parameters as an array.
 */
function parseUrlParams(url) {
  // Get settings from url, 'hash' takes precedence over 'search' component
  // don't use document.location.hash due to browser differences.
  let query;
  const queryIdx = url.indexOf('?');
  const hashIdx = url.indexOf('#');
  if (hashIdx === -1) {
    query = url.substr(queryIdx + 1);
  } else {
    // essentially replaces '#' with '&'
    query = [url.substr(queryIdx + 1, hashIdx - queryIdx - 1), '&', url.substr(hashIdx + 1)].join('');
  }
  return query.split('&');
}

function obfuscateUrlParams(url) {
  const filters = config.LIC_OBFUSCATED_DATA;
  const queryIdx = url.indexOf('?');
  const urlParams = parseUrlParams(url);
  const makeRegExp = () => {
    const regExpArr = [];
    for (const i in filters) {
      if (filters.hasOwnProperty(i)) {
        regExpArr.push(filters[i]);
      }
    }
    return new RegExp(regExpArr.join('|'), 'i');
  };

  const replaceObfuscatedParam = (paramStr) => {
    const aux = paramStr.split('=');
    if (aux.length !== 2) {
      return paramStr;
    }
    aux[1] = 'NA';
    return aux.join('=');
  };

  const testParam = (re, param) => {
    const name = param.split('=')[0];
    return re.test(name);
  };

  const regExpObj = makeRegExp();
  const arrNewUrlParams = [];

  for (const index in urlParams) {
    if (testParam(regExpObj, urlParams[index])) {
      arrNewUrlParams[index] = replaceObfuscatedParam(urlParams[index]);
    } else {
      arrNewUrlParams[index] = urlParams[index];
    }
  }

  return url.substr(0, queryIdx + 1) + arrNewUrlParams.join('&');
}

const deepDataFilter = (o, level = 0) => {
  // Prevent stack overflow
  if (level > 1000) {
    return false;
  }
  for (const i in o) {
    if (o.hasOwnProperty(i)) {
      if (Array.isArray(o)) {
        deepDataFilter(o[i], ++level);
      } else if (o[i] !== null && o[i] !== undefined && typeof o[i] === 'object') {
        deepDataFilter(o[i], ++level);
      } else if (i === 'url' && typeof o[i] === 'string') {
        o[i] = obfuscateUrlParams(o[i]);
      } else if (shouldBeObfuscated(i)) {
        if (typeof o[i] !== 'string' && typeof o[i] !== 'number') {
          // console.log(`Attempt to obfuscate non string or number - ${typeof o[i]}`);
        }
        if (o[i] && o[i].toString && o[i].toString().length) {
          // encrypt only if dataType has toString method^
          o[i] = SHA1(o[i].toString()).toString(encBase64);
        }
      }
    }
  }

  return true;
};

function deepFilter(content) {
  const data = { data: content };
  let contentObj;
  let result;

  try {
    contentObj = JSON.parse(content);
    if (contentObj.data && typeof contentObj.data !== 'object') {
      data.error = 'wrong_structure';
      return data;
    }
  } catch (e) {
    data.error = 'json_parse';
    return data;
  }

  try {
    deepDataFilter(contentObj);
  } catch (e) {
    data.error = 'deep_filter';
    return data;
  }

  try {
    result = JSON.stringify({ data: contentObj.data });
  } catch (e) {
    data.error = 'stringify';
    return data;
  }
  return result;
}

export default deepFilterWithConfig;
