import { createActions } from 'redux-actions';

const requestTypes = [
  'GET_ACCESSES_ETP_ACC_CREATION_NATIONAL',
  'GET_ACCESSES_ETP_ACC_CREATION_NATIONAL_SUCCESS',
  'GET_ACCESSES_ETP_ACC_CREATION_NATIONAL_ERROR',
  'GET_ACCESSES_AFFILATES',
  'GET_ACCESSES_AFFILATES_SUCCESS',
  'GET_ACCESSES_AFFILATES_ERROR',
  'GET_PARTNER_ACCREDITATIONS',
  'GET_PARTNER_ACCREDITATIONS_SUCCESS',
  'GET_PARTNER_ACCREDITATIONS_ERROR',
];

const syncTypes = [
];

const types = [...requestTypes, ...syncTypes];

export default createActions(...types);
