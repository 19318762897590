import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import ErrorBlock from '../ErrorBlock';

import styles from './ContentContainer.module.scss';

const ContentContainer = ({
  children,
  customStyle,
  errorResponse,
  maxWidthError,
}) => {
  const { isLessMaxWidth } = useSelector((state) => state.utils);

  return (
    <div
      className={cn(
        styles.container,
        customStyle,
        {
          [styles.mobile]: isLessMaxWidth,
        },
      )}
    >
      <div
        className={styles.innerContainer}
      >
        <>
          {errorResponse && (
            <ErrorBlock
              maxWidthError={maxWidthError}
            />
          )}
          {children}
        </>
      </div>
    </div>
  );
};

ContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
  customStyle: PropTypes.shape(),
  errorResponse: PropTypes.bool,
  maxWidthError: PropTypes.number,
};

ContentContainer.defaultProps = {
  customStyle: null,
  errorResponse: false,
  maxWidthError: 800,
};

export default React.memo(ContentContainer);
