export { default as reducer } from './reducer';
// eslint-disable-next-line import/no-cycle
export { default as sagas } from './sagas';

export const buildAction = (key, payload) => {
  const [act, salt] = Array.isArray(key) ? key : [key];
  return {
    type: [act.toString(), salt].filter((el) => el).join('_'),
    payload,
  };
};
