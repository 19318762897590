/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import theme from '../../styles/theme';

function TopCheck({
  width,
  height,
  fill,
}) {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M16.0788 0.555137C16.6763 1.25266 16.6329 2.33709 15.9819 2.97728L7.26532 11.5487C6.65287 12.151 5.71198 12.1504 5.10019 11.5473L1.01675 7.52251C0.366427 6.88151 0.324221 5.79703 0.922484 5.10025C1.52075 4.40347 2.53293 4.35825 3.18326 4.99924L6.1847 7.95762L13.8181 0.451311C14.4691 -0.188874 15.4813 -0.142389 16.0788 0.555137Z" fill={fill} />
    </svg>
  );
}

TopCheck.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

TopCheck.defaultProps = {
  width: 17,
  height: 20,
  fill: theme.dodgerBlue,
};

export default React.memo(TopCheck);
