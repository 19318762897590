import * as actions from '../actions/replies';

export const initialState = {
  showChat: false,
  files: [],
};

const HANDLERS = {
  [actions.showChat]: (state, payload) => ({
    ...state,
    showChat: payload,
  }),
  [actions.setFiles]: (state, payload) => ({
      ...state,
      files: [...state.files, ...payload],
  }),
  ...initialState,
};

export default function search(state = initialState, { type, payload }) {
  const handler = HANDLERS[type];

  return handler ? handler(state, payload) : state;
}
