import React, {
  memo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  Link,
  // useHistory,
} from 'react-router-dom';
// import get from 'lodash/get';

import styles from './BreadCrumbs.module.scss';

const BreadCrumbs = (props) => {
  const { arrayBreadcrumbs } = props;
  // const history = useHistory();

  const breadCrumbsRef = useRef(null);

  return (
    <div className={styles.rootBreadCrumbsWrapper}>
      <div
        ref={breadCrumbsRef}
        className={cn({
          [styles.breadCrumbsLinksShow]: true,
        })}
      >
        {arrayBreadcrumbs.length > 0 && arrayBreadcrumbs.map(({
          url,
          page,
        }, index) => (
          <div key={`${url}-${page}`} className={styles.breadCrumbsWrapper}>
            {url ? (
              <Link
                to={url}
                className={!url ? styles.disabled : ''}
              >
                {page}
              </Link>
            ) : page}
            {(index !== arrayBreadcrumbs.length - 1) && (
              <span>&nbsp;&nbsp;/&nbsp;&nbsp;</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

BreadCrumbs.propTypes = {
  arrayBreadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      page: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      url: PropTypes.string,
    }),
  ),
};

BreadCrumbs.defaultProps = {
  arrayBreadcrumbs: [],
};

export default memo(BreadCrumbs);
