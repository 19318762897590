import configAdapter from '../../../config/config';

const API_URL_V1 = '/api/v1';

const DELETE = 'DELETE';
const POST = 'POST';
const GET = 'GET';

const DOCUMENT_CONTROLLER = '/document';
const DOCUMENT_TYPE = '/document-type';
const ECM_URL = configAdapter('com.rooxteam.widgets.ecm.api.url');
const VERSIONS = '/versions';
const SMART_DELETE = '/smartdelete';
const COUNT = '/count';
const SEARCH = '/search';
const ITEMS = '/items';
const FILE = '/file';
const DOWNLOAD = '/download';
const SMART_CREATE = '/smart-create';

export default {
  API_URL_V1,
  ECM_URL,
  // routes
  DOCUMENT_CONTROLLER,
  DOCUMENT_TYPE,
  VERSIONS,
  SMART_DELETE,
  COUNT,
  SEARCH,
  ITEMS,
  FILE,
  DOWNLOAD,
  SMART_CREATE,
  DELETE,
  POST,
  GET,
};
