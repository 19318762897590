import Joi from 'joi-browser';

export const validate = (data) => {
  try {
    const schema = Joi.object()
      .options({ abortEarly: false, stripUnknown: true })
      .keys({
        isTheProductTechnicallySophisticated: Joi.string().required(),
        theProductCorrespondsToTheProfileOfTheEvent: Joi.string().required(),
        isDirectManufacturerOfProducts: Joi.string().required(),
        'docsOfDirectManufacturerProducts-yes': Joi.array(),
        'docsOfDirectManufacturerProducts-no': Joi
          .array()
          .when('isDirectManufacturerOfProducts', {
            is: 'isDirectManufacturerOfProducts-no',
            then: Joi
              .array()
              .min(1)
              .required(),
          }),
        additionalInformation: Joi.string().allow([null, '']),
        necessaryStandArea: Joi.number().positive().precision(1).allow([null, '']),
        specialRequirementsForStandArea: Joi.string().allow([null, '']),
      });
    const standAreaSchema = Joi.object()
      .options({ abortEarly: false, stripUnknown: true })
      .keys({
        necessaryStandArea: Joi.string().regex(/\d$/).allow([null, '']),
      });
    const { error } = Joi.validate({ ...data, necessaryStandArea: data.necessaryStandArea.replace(',', '.') }, schema);
    const { error: standAreaError } = Joi.validate(data, standAreaSchema);
    if (error && standAreaError) {
      error.details = [...error.details, ...standAreaError.details];
    }

    return error || standAreaError;
  } catch (e) {
    console.log('%c e', 'background: gray; color: #fff', e);
    return null;
  }
};
