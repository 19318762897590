/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function QuestionInCircle({
  width,
  height,
  fill,
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 12.6094C8.43147 12.6094 8.78125 12.2596 8.78125 11.8281C8.78125 11.3967 8.43147 11.0469 8 11.0469C7.56853 11.0469 7.21875 11.3967 7.21875 11.8281C7.21875 12.2596 7.56853 12.6094 8 12.6094Z" fill={fill} />
      <path d="M8 6.99382e-07C3.57863 3.12853e-07 -3.12801e-07 3.57803 -6.99382e-07 8C-1.08591e-06 12.4214 3.57803 16 8 16C12.4214 16 16 12.422 16 8C16 3.57863 12.422 1.08596e-06 8 6.99382e-07ZM8 14.75C4.26947 14.75 1.25 11.731 1.25 8C1.25 4.26947 4.26897 1.25 8 1.25C11.7305 1.25 14.75 4.26897 14.75 8C14.75 11.7305 11.731 14.75 8 14.75Z" fill={fill} />
      <path d="M8 4.01563C6.6215 4.01563 5.5 5.13713 5.5 6.51562C5.5 6.86081 5.77981 7.14063 6.125 7.14063C6.47019 7.14063 6.75 6.86081 6.75 6.51563C6.75 5.82638 7.31075 5.26563 8 5.26563C8.68925 5.26563 9.25 5.82638 9.25 6.51563C9.25 7.20488 8.68925 7.76563 8 7.76563C7.65481 7.76563 7.375 8.04544 7.375 8.39063L7.375 9.95313C7.375 10.2983 7.65481 10.5781 8 10.5781C8.34519 10.5781 8.625 10.2983 8.625 9.95313L8.625 8.93653C9.70206 8.65831 10.5 7.67841 10.5 6.51563C10.5 5.13713 9.3785 4.01563 8 4.01563Z" fill={fill} />
    </svg>
  );
}

QuestionInCircle.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

QuestionInCircle.defaultProps = {
  width: 16,
  height: 16,
  fill: '#1890FF',
};

export default React.memo(QuestionInCircle);
