/* eslint-disable max-len */
import React, { memo } from 'react';
import PropTypes from 'prop-types';

function UploadLight({
  width,
  height,
  fill,
}) {
  return (
    <svg width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.72852 11.3675C8.74453 11.3878 8.76498 11.4042 8.78833 11.4155C8.81168 11.4268 8.83731 11.4326 8.86328 11.4326C8.88925 11.4326 8.91488 11.4268 8.93823 11.4155C8.96158 11.4042 8.98203 11.3878 8.99804 11.3675L11.3938 8.36175C11.4815 8.25145 11.4023 8.08811 11.259 8.08811H9.67398V0.909931C9.67398 0.816598 9.59697 0.740234 9.50285 0.740234H8.21943C8.12531 0.740234 8.04831 0.816598 8.04831 0.909931V8.08599H6.46756C6.32424 8.08599 6.2451 8.24933 6.3328 8.35963L8.72852 11.3675ZM16.6922 10.6251H15.4087C15.3146 10.6251 15.2376 10.7014 15.2376 10.7948V14.0614H2.48895V10.7948C2.48895 10.7014 2.41194 10.6251 2.31783 10.6251H1.0344C0.940287 10.6251 0.863281 10.7014 0.863281 10.7948V14.9948C0.863281 15.3702 1.16916 15.6736 1.54777 15.6736H16.1788C16.5574 15.6736 16.8633 15.3702 16.8633 14.9948V10.7948C16.8633 10.7014 16.7863 10.6251 16.6922 10.6251Z" fill={fill} />
    </svg>
  );
}

UploadLight.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

UploadLight.defaultProps = {
  width: 17,
  height: 17,
  fill: '#9C9D9E',
};

export default memo(UploadLight);
