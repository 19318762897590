/* eslint-disable import/no-extraneous-dependencies */
import {
  put,
  call,
  select,
  fork,
} from 'redux-saga/effects';
import axios from 'axios';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';

import { sagaGenerator } from '../../../../store/helpers';
import {
  getCookie,
  bpmnApi,
  zvatApi,
} from '../../../../utils';
import {
  uploadEmitter,
  progressListener,
  checkCredentials,
  getFilesFormData,
} from '../sagaHelpers';
import actions, { specActions } from '../actions';

const {
  sendFormFile,
  sendFormFiles,
  deleteFormFile,
  reloadFile,
  downloadFile,
} = actions;

const HANDLERS = {
  * [sendFormFiles]({
    payload: {
      taskId,
      camundaId,
      files: unformattedFiles,
      name,
      addInfo,
      callback,
    },
  }, nsId) {
    let finished = false;
    try {
      const files = getFilesFormData(unformattedFiles);
      const hasCredentials = checkCredentials(taskId, camundaId);
      if (!hasCredentials) return;

      let currentSendingFile = 0;

      // initialize all upload statuses
      const uploadingFileStatuses = {
        uploading: true,
        name,
        statuses: files.map((item) => ({
          id: item.id,
          loadPercent: 0.1,
        })),
      };
      yield put(specActions.handleFilesUploadingStatuses(nsId, uploadingFileStatuses));

      while (!finished) {
        yield call(HANDLERS[sendFormFile], {
          file: files[currentSendingFile],
          taskId,
          camundaId,
          name,
          addInfo,
        }, nsId);

        currentSendingFile += 1;
        if (files.length === currentSendingFile) {
          finished = true;
          yield put(specActions.handleSendServiceFormStatus(nsId, false));
          yield put(specActions.handleFilesUploadingStatuses(nsId, { uploading: false, statuses: [] }));
          if (typeof callback === 'function') {
            callback();
          }
        }
      }
    } catch (error) {
      finished = true;
      yield put(specActions.handleSendServiceFormStatus(nsId, false));
      yield put(specActions.errorHandlerServices(nsId, error));
    }
  },
  * [reloadFile]({
    payload: {
      id,
      name,
      taskId,
      camundaId,
    },
  }, nsId) {
    const ns = `ServiceProfile${nsId}`;
    try {
      const hasCredentials = checkCredentials(taskId, camundaId);
      if (!hasCredentials) return;

      const { formFiles } = yield select((state) => state[ns]);
      const file = formFiles[name].find(({ id: fileId }) => fileId === id);
      const { filesUploadingErrors } = yield select((state) => state[ns]);
      const updatedFilesUploadingErrors = {
        hasError: filesUploadingErrors.hasError,
        filesIds: filesUploadingErrors.filesIds.filter((item) => item !== id),
      };
      yield put(specActions.handleUploadingFilesErrors(nsId, updatedFilesUploadingErrors));

      // initialize this upload status
      const uploadingFileStatuses = {
        uploading: true,
        name,
        statuses: [{
          id,
          loadPercent: 0.1,
        }],
      };
      yield put(specActions.handleFilesUploadingStatuses(nsId, uploadingFileStatuses));

      yield call(HANDLERS[sendFormFile], {
        file,
        taskId,
        camundaId,
        name,
      }, nsId);
      yield put(specActions.handleFilesUploadingStatuses(nsId, { uploading: false, statuses: [] }));
    } catch (error) {
      yield put(specActions.handleSendServiceFormStatus(nsId, false));
      yield put(specActions.errorHandlerServices(nsId, error));
    }
  },
  * [sendFormFile]({
    file,
    taskId,
    camundaId,
    name: formFieldName,
    addInfo,
  }, nsId) {
    const ns = `ServiceProfile${nsId}`;
    try {
      const url = `${bpmnApi.constants.BPMN_URL}${bpmnApi.utils.getTaskAttachmentsUrl(taskId)}`;
      const [promise, chan] = uploadEmitter({ url, camundaId, file });
      yield fork(progressListener, chan);
      const { data } = yield call(() => promise);
      const { formFiles } = yield select((state) => state[ns]);
      // replacement temporary local id to the server id, add download data
      const updatedFilesByName = formFiles[formFieldName] && formFiles[formFieldName].reduce((acc, item) => {
        if (item.id === file.id) {
          const { id, url: fileUrl } = data;
          const updatedFile = {
            ...item,
            addInfo,
            file: {
              name: item.file.name,
              size: item.file.size,
              url: fileUrl,
              requestConfig: {
                headers: {
                  camundaId,
                },
              },
            },
            id,
          };
          acc.push(updatedFile);
        } else {
          acc.push({ ...item });
        }
        return acc;
      }, []);

      const updatedFiles = {
        ...formFiles,
        [formFieldName]: updatedFilesByName || [],
      };

      yield put(specActions.handleUploadedFilesIds(nsId, data.id));
      yield put(specActions.handleFormFiles(nsId, updatedFiles));
    } catch (error) {
      yield put(specActions.handleFilesUploadingStatuses(nsId, { uploading: false, statuses: [] }));

      const { filesUploadingErrors } = yield select((state) => state[ns]);
      const updatedFilesUploadingErrors = {
        hasError: true,
        filesIds: filesUploadingErrors.filesIds.concat(file.id),
      };
      yield put(specActions.handleUploadingFilesErrors(nsId, updatedFilesUploadingErrors));
    }
  },
  * [deleteFormFile]({
    payload: {
      id,
      taskId,
      camundaId,
      callback,
    },
  }, nsId) {
    const ns = `ServiceProfile${nsId}`;
    try {
      yield put(specActions.handleSendServiceFormStatus(nsId, true));

      const hasCredentials = checkCredentials(taskId, camundaId);
      if (!hasCredentials) return;

      const url = bpmnApi.utils.getTaskAttachmentsUrl(taskId);

      if (!id) {
        yield put(specActions.errorHandlerServices(nsId, {
          hasError: true,
          type: 'no file id to delete',
        }));
        return;
      }

      const token = getCookie('digital_mp_at');
      const Authorization = `Bearer sso_1.0_${token}`;

      yield call(bpmnApi.adapter, {
        method: 'DELETE',
        url: `${url}/${id}`,
        headers: {
          Authorization,
          camundaId,
        },
      });

      const { formFiles } = yield select((state) => state[ns]);
      const filteredFormFiles = Object.entries(formFiles).reduce((acc, [key, files]) => {
        acc[key] = files.filter(({ id: fileId }) => fileId !== id);
        return acc;
      }, {});

      yield put(specActions.removeUploadedFilesId(nsId, id));
      yield put(specActions.handleFormFiles(nsId, filteredFormFiles));
      yield put(specActions.handleSendServiceFormStatus(nsId, false));
      if (typeof callback === 'function') callback();
    } catch (error) {
      yield put(specActions.errorHandlerServices(nsId, error));
      yield put(specActions.handleSendServiceFormStatus(nsId, false));
    }
  },
  * [downloadFile]({ payload }) {
    try {
      const {
        file: {
          name = '',
          id: fileId,
          url,
          requestConfig,
          isEncrypted,
          contentType,
          printFns,
        },
        task,
      } = payload;

      const token = getCookie('digital_mp_at');
      let headers = token
        ? {
         Authorization: `Bearer sso_1.0_${token}`,
         camundaId: 'camunda-exp-search',
        }
        : {};

      if (requestConfig && requestConfig.headers) {
        headers = { ...headers, ...requestConfig.headers };
      }

      const requestData = {
        url,
        headers,
        responseType: 'blob',
      };
      const { data } = yield call(axios, requestData);

      if (isEncrypted) {
        const reader = new FileReader();

        reader.onloadend = async () => {
          try {
            const { cadesplugin } = window;

            // convert to base64 from binary
            const b64data = btoa(reader.result);

            // create enveloped object
            const oEnvelop = await cadesplugin.CreateObjectAsync('CAdESCOM.CPEnvelopedData');
            await oEnvelop.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);
            await oEnvelop.propset_Content(b64data);

            await oEnvelop.Decrypt(b64data);

            // get decrypted content
            const content = await oEnvelop.Content;

            if (printFns) {
              const localHeaders = token
                ? { Authorization: `Bearer sso_1.0_${token}` }
                : {};

              const printFnsRequestPrams = {
                method: 'POST',
                url: `${zvatApi.constants.ZVAT_API_URL}${zvatApi.utils.getProcessAttachmentsPrintFnsUrl(task.processInstanceId, fileId)}`,
                headers: localHeaders,
                responseType: 'blob',
                data: {
                  content,
                },
              };

              const { data: printFile } = await axios(printFnsRequestPrams);

              // const printFileBlob = contentType
              //   // for fix wrong blob type from file request with responseType: 'blob'
              //   ? new Blob([printFile], { type: contentType })
              //   : printFile;

              const printFileBlob = new Blob([printFile], { type: 'application/pdf' });
              // eslint-disable-next-line no-useless-escape
              const pdfName = name.replace(/\.[^\.]+$/, '.pdf');
              saveAs(printFileBlob, pdfName);
            } else {
              // get file content from zip as blob
              const zip = await JSZip.loadAsync(content, { base64: true });
              const blob = await zip.files.file.async('blob');
              saveAs(blob, name);
            }
          } catch (error) {
            console.log('error --> ', error);
          }
        };

        reader.readAsBinaryString(data);
      } else if (data) {
        const fileToDownload = contentType
          // for fix wrong blob type from file request with responseType: 'blob'
          ? new Blob([data], { type: contentType })
          : data;

        saveAs(fileToDownload, name);
      }
    } catch (error) {
      yield put(specActions.errorHandlerServices({
        hasError: true,
        message: error.message,
        errorFrom: 'downloadFile',
      }));
    }
  },
};

export default sagaGenerator(HANDLERS);
