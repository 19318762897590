export default {
  black: '#000',
  darkGrey: '#7a7a7c',
  lightGrey: '#9c9d9e',
  light: '#eee',
  disabled: '#f9f9f9',
  white: '#fff',
  orange: '#ff8515',
  scorpion: '#595959',

  blueActive: '#233de0',
  blueNormal: '#6177FF',
  blue60: 'rgb(97,119,255, 0.6)',
  blueHover: '#788bfb',
  blueDisabled: '#d5dceb',
  blueLight: '#f2f5fe',
  darkBlue: '#2C386B',
  dodgerBlue: '#1890ff',
  boticelli: '#d5dceb',
  error: '#db4444',
  errorActive: '#bb2929',
  errorHover: '#f86464',
  // old color
  main: '#15122F',
  secondary: '#656289',
  third: '#A2A1BB',
  fourth: '#E3E1F3',
  background: '#F7F7FB',
  accent: '#3F75FF',

  statusGreen: '#349C6A',
  statusOrange: '#FAAD14',
};
