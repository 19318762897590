import Banner from './Banner';
import store from '../../store/store';

import {
  bannerReducer,
  bannerSagas,
} from './effects';

store.injectReducer('Banner', bannerReducer);
store.injectSaga('Banner', bannerSagas);

export default Banner;
