import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const FiltersBlock = ({ children }) => (
  <div className={styles.filtersBlock}>
    {children}
  </div>
);

FiltersBlock.defaultProps = {
  children: <></>,
};

FiltersBlock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
};

export default memo(FiltersBlock);
