/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function Close({
  width,
  height,
  fill,
  simple,
}) {
  if (simple) {
    return (
      <svg width={width} height={height} viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.35938 1.31445L0.999865 7.98953" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.35938 7.99023L0.999865 1.31516" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
  }
  return (
    <svg width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 0.5C8.9233 0.5 6.89323 1.11581 5.16652 2.26957C3.4398 3.42332 2.09399 5.0632 1.29927 6.98182C0.50455 8.90045 0.296614 11.0116 0.701759 13.0484C1.1069 15.0852 2.10693 16.9562 3.57538 18.4246C5.04383 19.8931 6.91476 20.8931 8.95156 21.2982C10.9884 21.7034 13.0996 21.4955 15.0182 20.7007C16.9368 19.906 18.5767 18.5602 19.7304 16.8335C20.8842 15.1068 21.5 13.0767 21.5 11C21.5 8.21523 20.3938 5.54451 18.4246 3.57538C16.4555 1.60625 13.7848 0.5 11 0.5ZM14.75 13.655L13.655 14.75L11 12.0575L8.34501 14.75L7.28751 13.6925L9.9425 11L7.28751 8.345L8.34501 7.2875L11 9.9425L13.655 7.2875L14.75 8.345L12.0575 11L14.75 13.655Z" fill={fill} />
    </svg>
  );
}

Close.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
  simple: PropTypes.bool,
};

Close.defaultProps = {
  width: 22,
  height: 22,
  fill: '#D9D9D9',
  simple: false,
};

export default React.memo(Close);
