import { call } from 'redux-saga/effects';
import qs from 'qs';
import axios from 'axios';
import get from 'lodash/get';

import constants from './constants';
import configAdapter from '../../../config/config';

const { UIDM_URL } = constants;

const baseData = {
  client_id: configAdapter('com.rooxteam.wms.oauth2.client_id') || 'digital_mp_m2m',
  client_secret: 'password',
  realm: '/customer',
  grant_type: 'urn:roox:params:oauth:grant-type:m2m',
};

const uidmAdapter = function* uidmAdapterFunction(options) {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
    ...get(options, 'headers', {}),
  };

  const reqOptions = {
    baseURL: UIDM_URL,
    ...options,
    headers,
  };
  // rewrite data object if url is access_token
  if (['/sso/oauth2/access_token', '/sso/auth/add-certificate-credentials'].includes(reqOptions.url)
    && reqOptions.method !== 'get') {
    reqOptions.data = qs.stringify({
      ...baseData,
      ...get(options, 'data', {}),
    });
  }

  return yield call(axios, reqOptions);
};

export default uidmAdapter;
