import { createActions } from 'redux-actions';

const requestTypes = [
  'SEND_PASSWORD_RECOVERY_OTP_CODE',
  'SEND_PASSWORD_RECOVERY_OTP_CODE_SUCCESS',

  'PASSWORD_RECOVERY_REQUEST',
  'PASSWORD_RECOVERY_SUCCESS',

  'FORCE_PASSWORD_RECOVERY_REQUEST',
  'FORCE_PASSWORD_RECOVERY_SUCCESS',

  'START_PASSWORD_RECOVERY_REQUEST',
  'START_PASSWORD_RECOVERY_SUCCESS',

  'SEND_ADDRESS_PASSWORD_RECOVERY_REQUEST',
  'SEND_ADDRESS_PASSWORD_RECOVERY_SUCCESS',
  'SEND_ADDRESS_PASSWORD_RECOVERY_ERROR',

  'ERROR_HANDLER',
];

const syncTypes = [
  'HANDLE_CHANGE_PASSWORD_RECOVERY_EMAIL_INPUT',
  'CHANGE_PASSWORD_RECOVERY_STEP',
  'HANDLE_SEND_PASSWORD_RECOVERY_OTP_CODE_STATUS',
  'RESET_STATE',
];

const types = [...requestTypes, ...syncTypes];

export default createActions(...types);
