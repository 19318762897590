/* eslint-disable */
import React, {
  Suspense,
  lazy,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import { KrLoader } from 'elk-uikit';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import ArmMenu from 'arm-menu';
import { withUidmSSO } from 'uidm-react-lib';
import { useHistory } from 'react-router-dom';
import actions from 'store/actions/auth';
import * as userActions from '../store/actions/user';
import companyActions from './Company/effects/actions';
import notificationsActions from './NotificationsPage/effects/actions';
import { TabsBlock, CertificateSelectorModal, Chat, Banner } from 'components';

import accessHoc from '../hocs/AccessHoc/AccessHoc';
import configAdapter from '../config/config';
import { IS_DEVELOPMENT } from '../utils';
import mdmApi from '../utils/api/mdmApi';
import styles from './index.module.scss';
import { LoadPage as LoadPageWithEffects, loadReducerAndSaga } from '../utils/helpers';

loadReducerAndSaga('Company');
loadReducerAndSaga('NotificationsPage');

const LoadPage = (pageName) => withRouter(lazy(() => import(`./${pageName}`)));
const Documents = LoadPageWithEffects('Documents');
const NewDocument = LoadPageWithEffects('NewDocument');
const SuccessStory = LoadPageWithEffects('SuccessStory');
const SuccessStoryDetail = LoadPageWithEffects('SuccessStoryDetail');
const Company = LoadPageWithEffects('Company');
const Requests = LoadPageWithEffects('Requests');
const Reports = LoadPageWithEffects('Reports');
const Replies = LoadPageWithEffects('Replies');
const ReplyCard = withRouter(LoadPageWithEffects('ReplyCard'));
// const Communications = LoadPageWithEffects('Communications');
const AccreditationCard = withRouter(LoadPageWithEffects('AccreditationCard'));
const AccreditationParametersRequest = withRouter(LoadPageWithEffects('AccreditationParametersRequest'));
const Contracts = LoadPageWithEffects('Contracts');
const ActionHistory = LoadPageWithEffects('ActionHistory');
const RequestCard = withRouter(LoadPageWithEffects('RequestCard'));
const Profile = withRouter(LoadPageWithEffects('Profile'));
const Acts = LoadPageWithEffects('Acts');
const Accreditations = LoadPageWithEffects('Accreditations');
const AccreditationRequests = LoadPageWithEffects('AccreditationRequests');
const AccreditationDocument = LoadPageWithEffects('AccreditationDocument');
const Affiliates = LoadPageWithEffects('Affiliates');
const UserProfile = LoadPageWithEffects('UserProfile');
const ChangePassword = LoadPageWithEffects('UserProfile', 'ChangePassword');
const Users = LoadPageWithEffects('Users');
const MyCredential = LoadPageWithEffects('MyCredential');
const Statistics = LoadPageWithEffects('Statistics');
const InvoicesPayment = LoadPageWithEffects('InvoicesPayment');
const PaymentAccess = LoadPageWithEffects('PaymentAccess');
const PartnerAccess = LoadPageWithEffects('PartnerAccess');
const TariffPlans = LoadPageWithEffects('TariffPlans');
const ServiceProfile = withRouter(lazy(() => import('./ServiceProfile/ServiceProfile')));
const Services = withRouter(lazy(() => import('./Services')));
const ArmMenuBlock = withUidmSSO(ArmMenu);
import Support from "./Support";
import ProtectedRoute from '../components/ProtectedRoute/ProtectedRoute';

// import PaymentAccess from "./PaymentAccess";
// import PaymentAccess from "./PaymentAccess";
// import PaymentAccess from "./PaymentAccess";
// import PaymentAccess from "./PaymentAccess";

const {
  authToggleCertificatesModal,
  authSaveCertificate,
} = actions;

const ALL_ROLES = ['partner', 'partner_director', 'partner_confirmed'];
const ONLY_CONFIRMED = ['partner_confirmed', 'partner_director', 'partner'];

const mainRoutes = [
  {
    path: '/accreditations',
    component: accessHoc(Accreditations, ONLY_CONFIRMED),
    exact: true,
  },
  {
    path: '/accreditation-requests',
    component: accessHoc(AccreditationRequests, ONLY_CONFIRMED),
    exact: true,
  },
  {
    path: '/accreditation-requests/invite',
    component:  accessHoc(AccreditationRequests, ONLY_CONFIRMED),
    exact: true,
  },
  {
    path: '/accreditation-document',
    component: accessHoc(AccreditationDocument, ALL_ROLES),
    exact: true,
  },
  {
    path: '/affiliates',
    component: accessHoc(Affiliates, ALL_ROLES),
    exact: true,
  },
  {
    path: '/',
    component: accessHoc(Requests, ONLY_CONFIRMED),
    exact: true,
  },
  {
    path: '/my-credential',
    component: accessHoc(MyCredential, ALL_ROLES),
    exact: true,
  },
  {
    path: '/user-profile/my-credential',
    component: accessHoc(MyCredential, ALL_ROLES),
    exact: true,
  },
  {
    path: '/accreditation-requests/services/:camundaId/:id',
    component: accessHoc(ServiceProfile, ONLY_CONFIRMED),
    exact: true,
  },
  {
    path: '/accreditation-requests/services/:processId',
    component: accessHoc(Services, ONLY_CONFIRMED),
    exact: true,
  },
  {
    path: '/company',
    component: accessHoc(Company, ONLY_CONFIRMED),
    exact: true,
  },
  {
    path: '/user-profile',
    component: accessHoc(UserProfile, ALL_ROLES),
    exact: true,
  },
  {
    path: '/support',
    component: accessHoc(Support, ALL_ROLES),
    exact: true,
  },
  {
    path: '/reports',
    component: accessHoc(Reports, ALL_ROLES),
    exact: true,
  },
  {
    path: '/users',
    component: accessHoc(Users, ['partner_director']),
    exact: true,
  },
  {
    path: '/user-profile/change-password',
    component: accessHoc(ChangePassword, ALL_ROLES),
    exact: true,
  },
  // {
  //   path: ['/communications', '/communications/:serviceId/:petitonNumber/:requestUuid'],
  //   component: accessHoc(Communications, ALL_ROLES),
  //   exact: true,
  // },
];

const routes = [
  {
    path: '/documents',
    component: accessHoc(Documents, ALL_ROLES),
  },
  {
    path: '/new-document',
    component: accessHoc(NewDocument, ALL_ROLES),
    exact: true,
  },
  {
    path: '/requests/:type',
    component: accessHoc(Requests, ALL_ROLES),
    exact: true,
  },
  {
    path: '/requests/info/:id',
    component: accessHoc(RequestCard, ALL_ROLES),
    exact: true,
  },
  {
    path: '/requests',
    component: accessHoc(Requests, ALL_ROLES),
    exact: true,
  },
  {
    path: '/replies/info/:id',
    component: accessHoc(ReplyCard, ALL_ROLES),
    exact: true,
  },
  {
    path: '/replies',
    component: accessHoc(Replies, ALL_ROLES),
    exact: true,
  },
  {
    path: '/action-history',
    component: accessHoc(ActionHistory, ALL_ROLES),
    exact: true,
  },
  {
    path: '/profile',
    component: accessHoc(Profile, ALL_ROLES),
    exact: true,
  },
  {
    path: '/contracts',
    component: accessHoc(Contracts, ALL_ROLES),
    exact: true,
  },
  {
    path: '/acts',
    component: accessHoc(Acts, ALL_ROLES),
    exact: true,
  },
  {
    path: '/statistics',
    component: accessHoc(Statistics, ALL_ROLES),
    exact: true,
  },
  {
    path: '/success-story',
    component: accessHoc(SuccessStory, ALL_ROLES),
    exact: true,
  },
  {
    path: '/success-story/:camundaId/:taskId',
    component: accessHoc(SuccessStoryDetail, ALL_ROLES),
    exact: true,
  },
  {
    path: '/accreditations/accreditation-card/:uuid',
    component: accessHoc(AccreditationCard, ALL_ROLES),
    exact: true,
  },
  {
    path: '/invoices-payment',
    component: accessHoc(InvoicesPayment, ALL_ROLES),
    exact: true,
  },
  {
    path: '/payment-access',
    component: accessHoc(PaymentAccess, ALL_ROLES),
    exact: true,
  },
  {
    path: '/tariff-plans',
    component: accessHoc(TariffPlans, ALL_ROLES),
    exact: true,
  },
  {
    path: '/partner-access',
    component: accessHoc(PartnerAccess, ALL_ROLES),
    exact: true,
  },
  ...mainRoutes,
];

const RouteMap = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    isAuthenticated,
    // isLoading,
    data,
  } = useSelector((state) => state.auth);
  const rolesCurrentOrgId = useSelector((state) => get(state, 'auth.data.rolesCurrentOrgId'));
  const currentOrgId = useSelector((state) => get(state, 'auth.data.currentOrgId'));
  const companyInfo = useSelector((state) => get(state, 'Company.companyInfo'));
  const partnerLogo = useSelector((state) => get(state, 'Company.companyInfo.partnerLogo', ''));
  const hasAccred = useSelector((state) => get(state, 'accreditationAccesses.hasAccred', ''));

  const [currentLogo, setCurrentLogo] = useState('');

  useEffect(() => {
    if (rolesCurrentOrgId) {
      dispatch(userActions.getPartnerAccreditationsCountRequest());
    }
  }, [rolesCurrentOrgId]);

  useEffect(() => {
    const linkTracking = (event) => {
      const parentClass = String(get(event, 'target.offsetParent.className', '')).includes('Notification');
      const targetClass = String(get(event, 'target.className', '')).includes('Notification');
      const block = parentClass || targetClass;
      if (!block) {
        dispatch(notificationsActions.handleNotificationsListVisible(false));
      }
    };

    document.addEventListener('click', linkTracking);

    return () => {
      document.removeEventListener('click', linkTracking);
    };
  }, [dispatch]);

  useEffect(() => {
    if (currentOrgId) {
      if (isEmpty(partnerLogo)) {
        setCurrentLogo(localStorage.getItem(currentOrgId));
      } else {
        setCurrentLogo(partnerLogo);
      }
    }
  }, [currentOrgId, partnerLogo]);

  const authAddSignatureHandler = useCallback(() => {
    dispatch(authToggleCertificatesModal());
    // dispatch(loginActions.startCertificateLoginRequest({ duck: 'auth', type: 'login' }));
  }, [dispatch]);

  useEffect(() => {
    if (isEmpty(companyInfo)) {
      dispatch(companyActions.getCompanyInfo());
    }
    if (!isEmpty(companyInfo)) {
      localStorage.setItem(get(companyInfo, 'idUl.uuid', ''), partnerLogo);
    }
  }, [dispatch, companyInfo]);

  const toggleVisibility = () => {
    dispatch(authToggleCertificatesModal());
  };

  useEffect(() => {
    if (history.location.pathname === '/' && !hasAccred) {
      history.push('/accreditation-requests');
    }
  }, [hasAccred]);

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <>
          <div>
            <ArmMenuBlock
              showNotifications={true}
              partnerLogo={currentLogo}
              profileUrl={'/company'}
              settings={{
                mdmBaseUrl: mdmApi.constants.MDM_URL,
              }}
            />
          </div>
          <div className={styles.routes}>
            <TabsBlock />
            {routes.map((route) => (
              <Route {...route} key={route.path} />
            ))}
            {currentOrgId && <Chat />}
            <Banner />
          </div>
          <CertificateSelectorModal
            stateSelector="auth"
            toggleVisibility={toggleVisibility}
            handleSaveCertificate={authSaveCertificate}
            signCertificate={authAddSignatureHandler}
          />
        </>
      </Switch>
    </Suspense>
  );
};

export default RouteMap;
