import { createActions } from 'redux-actions';

const requestTypes = [
  'GET_TOKEN_REQUEST',
  'GET_TOKEN_SUCCESS',
  'GET_TOKEN_FAILURE',

  'SEND_OTP_TO_MOBILE_REQUEST',
  'SEND_OTP_TO_MOBILE_SUCCESS',
  'SEND_OTP_TO_MOBILE_FAILURE',

  'SEND_OTP_REQUEST',
  'SEND_OTP_SUCCESS',
  'SEND_OTP_FAILURE',

  'GET_OTP_STATUS_REQUEST',
  'GET_OTP_STATUS_SUCCESS',
  'GET_OTP_STATUS_FAILURE',

  'SEND_TIMEZONE_REQUEST',
  'SEND_TIMEZONE_SUCCESS',
  'SEND_TIMEZONE_FAILURE',

  'CREATE_NONCE',
  'SAVE_NONCE',
  'CREATE_NONCE_FAILURE',
  'UPDATE_AUTH_LOAD_TIME',

  'CREATE_CRYPTO_PRO_NONCE',
  'CREATE_JINN_NONCE',
  'AUTH_ADD_CERTIFICATE_CREDENTIALS_START_REQUEST',
  'AUTH_TOGGLE_CERTIFICATES_MODAL',
  'AUTH_SAVE_CERTIFICATE',

  'CHECK_ACTUAL_AGREEMENT_REQUEST',
  'CHECK_ACTUAL_AGREEMENT_SUCCESS',
  'CHECK_ACTUAL_AGREEMENT_FAILURE',

  'GET_ACTUAL_AGREEMENT_REQUEST',
  'GET_ACTUAL_AGREEMENT_SUCCESS',
  'GET_ACTUAL_AGREEMENT_FAILURE',

  'UPDATE_USER_AGREEMENT_REQUEST',
  'UPDATE_USER_AGREEMENT_SUCCESS',
  'UPDATE_USER_AGREEMENT_FAILURE',
];

const syncTypes = [
  'CHANGE_TOKEN_EXPIRE_IN',
  'TOGGLE_BANNER',
  'SAVE_CERTIFICATE_FLOW',
  'CHANGE_PHONE',
  'OTP_SHOWN',
  'SAVE_OTP_PHONE_CHANGE_FLOW',
  'SAVE_IFRAME_AUTH_INFO',
  'CLEAR_OTP_ERROR',
];

const types = [...requestTypes, ...syncTypes];

export default createActions(...types);
