/* eslint-disable import/no-unresolved */
import React, {
  useEffect, useState, useMemo, memo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  KrButton as ButtonLink,
  KrDropdown as DropDown,
} from 'elk-uikit';
import { i18n } from '../../utils';

import actions from '../../store/actions/profile';
import '../../utils/cryptopro-api';
import styles from './index.module.scss';
import { getCertSimpleName, getFormattedDate } from '../../utils/helpers/helpers';

const { saveCertificate } = actions;
const { getFormattedMessage } = i18n;

const getCertificatesList = async () => {
  try {
    if (window.cadesplugin) {
      const { cadesplugin } = window;
      await cadesplugin;

      const getCertificates = () => new Promise((resolve, reject) => {
        const {
          async_spawn: asyncSpawn,
          CreateObjectAsync,
          CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
          CAPICOM_CURRENT_USER_STORE,
          CAPICOM_MY_STORE,
        } = window.cadesplugin;

        asyncSpawn(function* certificatesGenerator() {
          try {
            const oStore = yield CreateObjectAsync('CAdESCOM.Store');
            yield oStore.Open(
              CAPICOM_CURRENT_USER_STORE,
              CAPICOM_MY_STORE,
              CAPICOM_STORE_OPEN_MAXIMUM_ALLOWED,
            );

            const certificatesObj = yield oStore.Certificates;

            resolve(certificatesObj);
          } catch (e) {
            reject(e);
          }
        });
      });

      const certificatesLocal = await getCertificates();

      const amountOfCertificates = await certificatesLocal.Count;

      const getCertificateData = async (index) => {
        const certificate = await certificatesLocal.Item(index);

        const fullSubjectName = await certificate.SubjectName;
        const validFromDate = await certificate.ValidFromDate;
        const formattedDate = getFormattedDate(validFromDate);
        const subjectName = getCertSimpleName(fullSubjectName);

        return {
          value: `${subjectName} от ${formattedDate}`,
          data: certificate,
        };
      };

      let certificatesData = [];
      for (let i = 1; i <= amountOfCertificates; i += 1) {
        certificatesData.push(getCertificateData(i));
      }
      certificatesData = await Promise.all(certificatesData);

      return certificatesData;
    }

    throw new Error('Error with cadesplugin. Please reload the page.');
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  return [];
};

const CertificateSelector = ({
  label,
  disabled,
  handleSaveCertificate,
  stateSelector,
}) => {
  // state
  const [certificates, setCertificates] = useState([]);
  const [dropdownValue, setDropdownValue] = useState('');
  const [isCertificatesReady, setIsCertificatesReady] = useState(false);
  const [isUpdateLoading, setUpdateLoading] = useState(false);

  // store
  const error = useSelector((state) => _.get(state, 'ServiceProfile.error.message', null));
  const dispatch = useDispatch();

  const errorMessage = useMemo(() => {
    if (error) {
      if (error.includes('(0x')) return error;
      return getFormattedMessage('error.base', 'certificates');
    }
    return null;
  }, [error]);

  const getCertificatesHandler = async () => {
    const certificatesList = await getCertificatesList();

    setCertificates(certificatesList);
    setIsCertificatesReady(true);
  };

  const updateCertificates = async () => {
    setUpdateLoading(true);
    setDropdownValue('');
    dispatch(saveCertificate(null));
    await getCertificatesHandler();
    setUpdateLoading(false);
  };

  // get list of available certificates
  useEffect(() => {
    (async () => {
      await getCertificatesHandler();
    })();
  }, []);

  const selectCertificate = (value) => {
    const certificate = certificates.find((el) => el.value === value);
    if (certificate) {
      // dispatch(saveCertificate(certificate.data));
      dispatch(handleSaveCertificate(certificate.data) || saveCertificate(certificate.data));
      setDropdownValue(certificate.value);
    }
  };

  const handleDropdownInput = (value) => {
    setDropdownValue(value);
  };

  return (
    <>
      {certificates.length === 0 && isCertificatesReady ? (
        <div>
          {getFormattedMessage('error.zeroCertificates', 'certificates')}
        </div>
      ) : (
        <div className={styles.dropdownWrapper}>
          <DropDown
            fullWidth
            name="certificate"
            label={label}
            variants={certificates}
            onChange={handleDropdownInput}
            onSelect={selectCertificate}
            value={dropdownValue}
            disabled={disabled}
          />
          <ButtonLink
            wrapperClasses={{
              button: styles.button,
            }}
            onClick={updateCertificates}
            isLoading={isUpdateLoading}
            // eslint-disable-next-line max-len
            isDisabled={stateSelector !== 'ServiceProfile'} // Модель данных для других страницы отличается изза использования криптопро, вследствии чего падает ошибка, исправить
          >
            {getFormattedMessage('button.update', 'certificates')}
          </ButtonLink>
        </div>
      )}
      {errorMessage && (
        <div className={styles.error}>
          {errorMessage}
        </div>
      )}
    </>
  );
};

CertificateSelector.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  handleSaveCertificate: PropTypes.func,
  stateSelector: PropTypes.string,
};

CertificateSelector.defaultProps = {
  label: 'Выберите сертификат',
  disabled: false,
  handleSaveCertificate: () => null,
  stateSelector: 'ServiceProfile',
};

export default memo(CertificateSelector);
