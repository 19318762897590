import configAdapter from '../../../config/config';

const API_URL_V1 = '/api/v1';
const BPMN_ROUTE = `${API_URL_V1}/bpmn`;

const TASKS = '/tasks';
const PROCESS = '/process';
const PROCESSES = '/processes';
const PROCESS_INSTANCE = '/process-instance';
const PROCESS_HISTORY_OF_SUCCESS = '/partner_history_of_success';
const PROCESS_HISTORY = '/history/process-instance/$processId/variables?size=2000';
const BPMN_URL = configAdapter('com.rooxteam.widgets.bpmn.api.url');
// const BPMN_URL = 'http://bpmn-api-service.bpms-dev.d.exportcenter.ru/bpmn';

const BPMN_TASKS_API = `${BPMN_URL}${BPMN_ROUTE}${TASKS}`;
const BPMN_PROCESS_API = `${BPMN_URL}${BPMN_ROUTE}${PROCESS}`;
const POST = 'POST';
const GET = 'GET';
const PUT = 'PUT';
const DELETE = 'DELETE';

export default {
  API_URL_V1,
  BPMN_ROUTE,
  BPMN_URL,
  PROCESSES,
  // routes
  TASKS,
  PROCESS,
  BPMN_TASKS_API,
  PROCESS_INSTANCE,
  PROCESS_HISTORY,
  BPMN_PROCESS_API,
  PROCESS_HISTORY_OF_SUCCESS,
  POST,
  GET,
  PUT,
  DELETE,
};
