import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './TabButton.module.scss';

const TabButton = ({
  text = '',
  isActive,
  onClick,
  disabled,
  classNameButton,
}) => (
  <button
    type="submit"
    onClick={onClick}
    className={cn(styles.button, classNameButton, {
      [styles.buttonActive]: isActive,
      [styles.disabled]: disabled,
    })}
  >
    {text}
  </button>
);

TabButton.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isActive: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  classNameButton: PropTypes.string,
};

TabButton.defaultProps = {
  isActive: false,
  disabled: false,
  onClick: () => {},
  classNameButton: '',
};

export default memo(TabButton);
