import { reducerGenerator } from '../helpers';
import actions from '../actions/services';

import {
  STATIC_SERVICE_PROVIDED,
  STATIC_SERVICE_EVALUATION,
  STATIC_SERVICE_DOCUMENT_CHOOSE,
  STATIC_SERVICE_ACCELERATOR,
} from '../../utils/staticServices';

const {
  getServicesListSuccess,
  handleServicesError,
} = actions;

/*
  TODO: временные хард-код карточки услуг
  Пожалуйста, добавляйте их сюда и только сюда (пока)
  так как их не нужно отображать, если настоящих услуг и вовсе не пришло с бэка
*/
const DRAFT_HARD_SERVICES = [
  ...STATIC_SERVICE_PROVIDED,
  ...STATIC_SERVICE_EVALUATION,
  ...STATIC_SERVICE_DOCUMENT_CHOOSE,
];
const ACCELERATOR_HARD_SERVICES = [
  ...[STATIC_SERVICE_ACCELERATOR],
];
const ALL_HARD_SERVICES = [
  ...STATIC_SERVICE_PROVIDED,
  ...STATIC_SERVICE_EVALUATION,
  ...STATIC_SERVICE_DOCUMENT_CHOOSE,
];

export const initialState = {
  servicesList: {
    firstLoad: false,
    all: [], // все
    drafts: [], // в процессе
    accelerator: [], // аксселератор
    archive: [], // архивные
  },
  error: null,
};

const HANDLERS = {
  [getServicesListSuccess]: (state, payload) => {
    const drafts = [
      ...payload.drafts,
      // Отображать хард-код карточки только тогда, когда список реальных услуг есть
      ...(payload.all.length ? DRAFT_HARD_SERVICES : []),
    ];
    const accelerator = [
      ...payload.accelerator,
      // Отображать хард-код карточки только тогда, когда список реальных услуг есть
      ...(payload.all.length ? ACCELERATOR_HARD_SERVICES : []),
    ];
    const all = [
      ...payload.all,
      // Отображать хард-код карточки только тогда, когда список реальных услуг есть
      ...(payload.all.length ? ALL_HARD_SERVICES : []),
    ];
    if (payload.all.length) all.splice(2, 0, STATIC_SERVICE_ACCELERATOR);
    return {
      ...state,
      servicesList: {
        ...payload,
        firstLoad: true,
        /*
          TODO: убрать accelerator и drafts ниже,
          когда уже не будет хард-код карточек (...payload и так весь список сохраняет)
        */
        all, // с хард-код карточками
        accelerator, // с хард-код карточками
        drafts, // с хард-код карточками
      },
    };
  },
  [handleServicesError]: (state, payload) => ({
    ...state,
    error: payload.error,
  }),
};

export default reducerGenerator(initialState, HANDLERS);
