import { createActions } from 'redux-actions';

const requestTypes = [
  'GET_SERVICES_LIST',
  'GET_SERVICES_LIST_SUCCESS',
];

const syncTypes = [
  'HANDLE_SERVICES_ERROR',
];

const types = [...requestTypes, ...syncTypes];

export default createActions(...types);
