import adapter from './adapter';
import constants from './constants';
import utils from './utils';

const mdmApi = {
  adapter,
  constants,
  utils,
};

export default mdmApi;
