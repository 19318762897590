import React, { memo } from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import Icons from '../Icons';
import styles from './index.module.scss';

const { ArrowDown } = Icons;

const ReactPagination = ({
 totalPages, page, onPageChange, pageRangeDisplayed,
}) => (
  <ReactPaginate
    pageCount={totalPages}
    forcePage={page}
    pageRangeDisplayed={pageRangeDisplayed || 10}
    onPageChange={({ selected }) => onPageChange(selected)}
    breakLabel={<div className={styles.break}>...</div>}
    containerClassName={styles.containerPaginate}
    pageClassName={styles.pageClassName}
    activeClassName={styles.activeClassName}
    marginPagesDisplayed={2}
    previousLabel={totalPages ? <div className={`${styles.navButton} ${styles.buttonPrev}`}><ArrowDown /></div> : null}
    nextLabel={totalPages ? <div className={`${styles.navButton} ${styles.buttonNext}`}><ArrowDown /></div> : null}
    previousClassName={styles.pageClassName}
    nextClassName={styles.pageClassName}
    activeLinkClassName={styles.linkClass}
    pageLinkClassName={styles.linkClass}
    previousLinkClassName={styles.buttonsLinkClass}
    nextLinkClassName={styles.buttonsLinkClass}
    disableInitialCallback
  />
);

ReactPagination.defaultProps = {
  totalPages: 0,
  page: 0,
  onPageChange: () => {},
  pageRangeDisplayed: 10,
};

ReactPagination.propTypes = {
  totalPages: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  pageRangeDisplayed: PropTypes.number,
};

export default memo(ReactPagination);
