import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const DateInputContainer = ({ firstInput, secondInput }) => (
  <div className={styles.container}>
    <div className={styles.date}>
      {firstInput}
    </div>
    {secondInput && (
      <>
        <div className={styles.dateSeparator}>
          &nbsp;—&nbsp;
        </div>
        <div className={styles.date}>
          {secondInput}
        </div>
      </>
    )}
  </div>
);

DateInputContainer.defaultProps = {
  firstInput: <></>,
  secondInput: <></>,
};

DateInputContainer.propTypes = {
  firstInput: PropTypes.node,
  secondInput: PropTypes.node,
};

export default memo(DateInputContainer);
