import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {
  KrButton as ButtonLink,
} from 'elk-uikit';
import actions from '../../store/actions/profile';

import CertificateSelectorInfo from '../CertificateSelectorInfo';
import ProgressBar from '../CertificateProgressBar';
import ModalBanner from '../ModalBanner';
import CertificateSelector from '../CertificateSelector';

import styles from './index.module.scss';

const CertificateSelectorModal = ({
  stateSelector,
  toggleVisibility,
  handleSaveCertificate,
  signCertificate,
}) => {
  const {
    certificate,
    documentsSignStep: {
      isSignLoading,
      allDocs,
      isCertificatesModalOpen,
      successfullySignedDocuments,
    } = {},
  } = useSelector((state) => state[stateSelector]);

  const dispatch = useDispatch();

  const toggleHandler = () => {
    if (isSignLoading) return;
    toggleVisibility();
  };

  const signHandler = useCallback(() => {
    if (signCertificate) {
      signCertificate();
    } else if (allDocs.length) {
      dispatch(actions.getAllDocumentsForSign());
    } else {
      dispatch(actions.getDocumentForSign());
    }
  }, [get(allDocs, 'length'), dispatch]);

  return (
    <ModalBanner
      isShown={isCertificatesModalOpen}
      toggleVisibility={toggleHandler}
      height="auto"
      useLeftMenu={false}
      parentLayout={styles.modalContent}
    >
      <div
        className={styles.modalTitle}
      >
        Информация о сертификате
      </div>

      <div className={styles.dropdownWrapper}>
        <CertificateSelector
          label="Выберите сертификат"
          disabled={isSignLoading}
          handleSaveCertificate={handleSaveCertificate}
          stateSelector={stateSelector}
        />
      </div>

      <ProgressBar
        total={allDocs}
        current={successfullySignedDocuments}
        isSignLoading={isSignLoading}
      />

      <div className={cn({ [styles.infoWrapper]: Boolean(certificate) })}>
        <CertificateSelectorInfo
          certificate={stateSelector === 'ServiceProfile' ? certificate : get(certificate, 'cert', {})}
        />
      </div>

      <div className={styles.modalButtonWrapper}>
        <ButtonLink
          wrapperClasses={{
            button: styles.modalButton,
          }}
          customStyle={styles.modalButton}
          onClick={signHandler}
          isLoading={isSignLoading}
          isDisabled={!certificate}
        >
          Подписать
        </ButtonLink>
      </div>
    </ModalBanner>
  );
};

CertificateSelectorModal.propTypes = {
  stateSelector: PropTypes.string,
  toggleVisibility: PropTypes.func,
  handleSaveCertificate: PropTypes.func,
  signCertificate: PropTypes.func,
};

CertificateSelectorModal.defaultProps = {
  stateSelector: 'ServiceProfile',
  toggleVisibility: undefined,
  handleSaveCertificate: undefined,
  signCertificate: undefined,
};

export default CertificateSelectorModal;
