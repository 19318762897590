import constants from './constants';

const {
  BPMN_ROUTE,
  TASKS,
  PROCESSES,
  PROCESS,
  PROCESS_INSTANCE,
  PROCESS_HISTORY,
} = constants;

const tasksRoute = `${BPMN_ROUTE}${TASKS}`;
const processesInstanceRoute = `${BPMN_ROUTE}${PROCESS_INSTANCE}`;

const getTaskFinishUrl = (taskId) => `${tasksRoute}/${taskId}/finish`;
const getTaskCancelUrl = (taskId) => `${tasksRoute}/${taskId}/cancel`;
const getTaskAttachmentsUrl = (taskId) => `${tasksRoute}/${taskId}/attachments`;
const deleteTaskAttachmentsUrl = (taskId, attachId) => `${tasksRoute}/${taskId}/attachments/${attachId}`;
const getProcessAttachmentsPrintFnsUrl = (processId, attachmentId) => `${BPMN_ROUTE}${PROCESSES}/${processId}/attachments/${attachmentId}/print`;
const getTaskContextUrl = (taskId) => `${tasksRoute}/${taskId}/context`;
const getProcessStart = (processId) => `${BPMN_ROUTE}${PROCESS}/${processId}/start`;
const getProcessCancelUrl = (processId) => `${processesInstanceRoute}/${processId}/message`;
const getProcessAttachmentsUrl = (processId) => `${processesInstanceRoute}/${processId}/attachments`;
const getProcessHistoryUrl = (processId) => `${BPMN_ROUTE}${PROCESS_HISTORY.replace('$processId', processId)}`;

export default {
  getTaskFinishUrl,
  getTaskCancelUrl,
  getTaskAttachmentsUrl,
  deleteTaskAttachmentsUrl,
  getTaskContextUrl,
  getProcessStart,
  getProcessCancelUrl,
  getProcessAttachmentsUrl,
  getProcessAttachmentsPrintFnsUrl,
  getProcessHistoryUrl,
};
