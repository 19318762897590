import actions from '../actions/chat';

const { showChat } = actions;

export const initialState = {
  showChat: false,
};

const HANDLERS = {
  [showChat]: (state, payload) => ({
    ...state,
    showChat: payload,
  }),
  ...initialState,
};

export default function search(state = initialState, { type, payload }) {
  const handler = HANDLERS[type];

  return handler ? handler(state, payload) : state;
}
