import {
  put,
  call,
  delay,
  select,
} from 'redux-saga/effects';
// import { createMetrics } from 'react-metrics';
import {
  get, isEmpty, isObject,
} from 'lodash';
import { history as rootHistory } from 'store/reducers';

// import chatActions from 'store/actions/chat';
import {
  sagaGenerator,
} from 'store/helpers';
import {
  getCookie,
  bpmnApi,
  zvatApi,
  // metricsConfig,
} from '../../../../utils';
import { handleFormDataWithTmpl } from '../../utils/helpers';
import customUISchema from '../../customSchema';
import actions, { specActions } from '../actions';
import {
  checkCredentials,
  checkTaskAttachments,
  parseAttachments,
} from '../sagaHelpers';
import { validate } from '../validation';
import getSubFormSagas from '../../components/SubForm/effects';
import { buildReducer } from '../reducer';

// const { setChatFieldsServices } = chatActions;
const {
  validateEventInfo,
  getServiceInfo,
  // refreshSubforms,
  cancelForm,
  saveFormContext,
  getTaskAttachments,
  sendElectronicSignDocumentRequest,
  updateFormStatus,
  sendCertificate,
  sendDecryptedApprove,
} = actions;
// const metrics = createMetrics(metricsConfig);

const getErrMsg = (error) => error?.response?.data?.message || error?.message;

const prepareSubmitParent = (data) => {
  if (data === true) return {};
  if (!isObject(data)) return null;
  const entries = Object.entries(data).map(([k, value]) => ([
    k, { value },
  ]));
  return Object.fromEntries(entries);
};

function* getSubtasks({ task, uiSchema: { fields } }, store, handlers) {
  const { processInstanceId, camundaId: cmndId, id: taskId } = task;
  const subforms = Object.values(fields).filter(({ uiType }) => uiType === 'subform')
    .reduce((map, { formKey, submitParent: sp }) => {
      const submitParent = prepareSubmitParent(sp);
      return {
        ...map,
        [formKey]: { submitParent },
      };
    }, {});
  if (isEmpty(subforms)) return {};
  if (!processInstanceId) return {};
  let result = {};
  try {
    const url = bpmnApi.constants.BPMN_TASKS_API;
    const { data = [] } = yield call(bpmnApi.adapter, {
      method: 'get',
      url,
      headers: {
        camundaId: cmndId,
      },
      params: {
        processInstanceId,
        size: 50,
      },
    });
    result = get(data, 'content', [])
      .filter(({ id, formKey }) => id !== taskId && subforms[formKey]) // only in schemas tasks
      .reduce((map, { id, camundaId, formKey }) => {
        if (store) {
          store.injectReducer(`ServiceProfile${formKey}`, buildReducer(formKey));
          store.injectSaga(['ServiceProfile', formKey], getSubFormSagas(sagaGenerator(handlers)));
        }
        return {
          ...map,
          [formKey]: {
            taskId: id,
            camundaId,
            formKey,
            submitParent: subforms[formKey].submitParent,
          },
        };
      }, {});
  } catch (err) {
    console.log(err);
  }
  return result;
}

const HANDLERS = {
  * [validateEventInfo]({ payload }, nsId) { // products only
    const ns = `ServiceProfile${nsId}`;
    try {
      const info = yield select((state) => ({
        ...Object.entries(state[ns].taskContext.variables).reduce((acc, [key, { value }]) => {
          acc[key] = value;
          return acc;
        }, {}),
        'docsOfDirectManufacturerProducts-yes': state[ns]?.formFiles['docsOfDirectManufacturerProducts-yes'],
        'docsOfDirectManufacturerProducts-no': state[ns]?.formFiles['docsOfDirectManufacturerProducts-no'],
      }));
      const errors = validate(info);
      if (errors) {
        yield put(actions.updateEventInfoErrors(errors.details.reduce((acc, i) => {
          acc[i.context.key] = true;
          return acc;
        }, {})));
      } else {
        yield put(actions.setPromptData(payload));
      }
    } catch (e) {
      console.log('%c e', 'background: gray; color: #fff', e);
    }
  },
  * [getServiceInfo]({ payload }, nsId) {
    const {
      taskId, camundaId, isUpdating = false, store,
    } = payload;
    const isMainTask = !nsId;
    // const sendSuccessStatistic = () => {
    //   if (isUpdating) return;
    //   metrics.api.track('service_get', {
    //     status: 'success',
    //     taskId,
    //     camundaId,
    //   });
    // };
    try {
      if (!isUpdating) yield put(specActions.handleGetServiceInfoLoader(nsId, true));

      const hasCredentials = checkCredentials(taskId, camundaId);
      if (!hasCredentials) return;

      const token = getCookie('digital_mp_at');
      const Authorization = `Bearer sso_1.0_${token}`;
      const url = bpmnApi.utils.getTaskContextUrl(taskId);
      let { data } = yield call(bpmnApi.adapter, {
        method: 'get',
        url,
        headers: {
          Authorization,
          camundaId,
        },
      });
      if (isMainTask) {
        if (!isEmpty(customUISchema)) data.uiSchema = customUISchema;
        data = handleFormDataWithTmpl(data);
        const subtasks = yield call(getSubtasks, data, store, HANDLERS);
        data = {
          ...data,
          task: { ...data.task, subtasks },
        };
      }

      const needToGetAttachments = checkTaskAttachments(data);
      if (needToGetAttachments) {
        yield HANDLERS[getTaskAttachments]({ // todo use properly handler
          taskId,
          camundaId,
          data,
        }, nsId);
      } else {
        yield put(specActions.getServiceInfoSuccess(nsId, data));
      }

      if (isMainTask) yield put(actions.updateProductsToForm(data?.variables?.productsTable?.value || []));

      if (!isUpdating) yield put(specActions.handleGetServiceInfoLoader(nsId, false));
      // const {
      //   variables: {
      //     chatId: { value },
      //   },
      //   task: { name },
      //   uiSchema: {
      //     serviceTitle,
      //   },
      // } = data;
      // if (isMainTask) {
      //   yield put(setChatFieldsServices({
      //     id: value,
      //     name: serviceTitle || name,
      //   }));
      // }

      // sendSuccessStatistic();
    } catch (error) {
      // metrics.api.track('service_get', {
      //   status: 'failure',
      //   taskId,
      //   camundaId,
      // });
      yield put(specActions.handleGetServiceInfoLoader(nsId, false));
      yield put(specActions.errorHandlerServices(nsId, {
        hasError: true,
        message: getErrMsg(error),
        errorFrom: 'getServiceInfo',
      }));
    }
  },
  /* * [refreshSubforms]({ payload: { nsId } }) { // refreshSubform >> SubForm/sagas
    const DEFAULT_DELAY = 2500;
    const DEFAULT_TRIES = 16;
    const ns = 'ServiceProfile';
    let finished = false;
    let counterOfCalls = 0;

    try {
      const {
        taskContext,
        task: {
          processInstanceId,
          camundaId: cmndId,
          id: taskId,
        },
        tries,
        refreshDelay,
      } = yield select((state) => ({
        taskContext: get(state, `${ns}.taskContext`, ''),
        task: get(state, `${ns}.taskContext.task`, ''),
        tries: get(state, `${ns}.taskContext.uiSchema.formRefresh.tries`, DEFAULT_TRIES),
        refreshDelay: get(state, `${ns}.taskContext.uiSchema.formRefresh.delay`, DEFAULT_DELAY),
      }));

      const url = bpmnApi.constants.BPMN_TASKS_API;
      let tasks = [];
      while (!finished) {
        const { data } = yield call(bpmnApi.adapter, {
          method: 'get',
          url,
          headers: {
            camundaId: cmndId,
          },
          params: {
            processInstanceId,
            size: 50,
          },
        });
        tasks = get(data, 'content', []);
        const nextTask = tasks.find(({ formKey }) => formKey === nsId);
        if (nextTask) {
          finished = true;
        }
        counterOfCalls += 1;
        if (counterOfCalls >= tries) {
          finished = true;
        }
        yield delay(refreshDelay);
      }
      const subtasks = tasks.filter(({ id }) => id !== taskId)
        .reduce((map, { id, camundaId, formKey }) => ({
          ...map,
          [formKey]: { taskId: id, camundaId, formKey },
        }), {});

      const updatedContext = {
        ...taskContext,
        task: {
          ...taskContext.task,
          subtasks,
        },
      };
      yield put(actions.getServiceInfoSuccess(updatedContext));
    } catch (e) {
      console.error(e);
    }
  }, */
  * [cancelForm]({ payload: { camundaId, submitData } }, nsId) {
    const ns = 'ServiceProfile';
    try {
      const {
        processInstanceId = '',
      } = yield select((state) => state[ns].taskContext.task);
      if (!processInstanceId) return;
      const url = bpmnApi.utils.getProcessCancelUrl(processInstanceId);
      const data = {
        messageName: 'killProcessMsg',
        processInstanceId,
        processVariables: submitData,
      };
      yield call(bpmnApi.adapter, {
        method: 'post',
        url,
        headers: { camundaId },
        data,
      });
    } catch (error) {
      yield put(specActions.errorHandlerServices(nsId, {
        hasError: true,
        message: getErrMsg(error),
        errorFrom: 'cancelForm',
      }));
    }
  },
  * [saveFormContext]({ payload: { taskId, camundaId, formData } }, nsId) {
    try {
      const hasCredentials = checkCredentials(taskId, camundaId);
      if (!hasCredentials) return;

      const token = getCookie('digital_mp_at');
      const Authorization = `Bearer sso_1.0_${token}`;
      const url = bpmnApi.utils.getTaskContextUrl(taskId);
      const taskContext = {
        modifications: { ...formData },
      };

      yield call(bpmnApi.adapter, {
        method: 'put',
        url,
        headers: {
          Authorization,
          camundaId,
        },
        data: taskContext,
      });
    } catch (error) {
      yield put(specActions.errorHandlerServices(nsId, {
        hasError: true,
        message: getErrMsg(error),
        errorFrom: 'saveFormContext',
      }));
    }
  },
  * [getTaskAttachments]({
    taskId,
    camundaId,
    data: taskContext,
  }, nsId) {
    try {
      const {
        uiSchema: { fields },
        uiSchema,
        variables,
      } = taskContext;
      const url = bpmnApi.utils.getTaskAttachmentsUrl(taskId);
      const size = 100;
      const queries = `?size=${size}`;
      const urlWithQueries = `${url}${queries}`;
      const token = getCookie('digital_mp_at');
      const Authorization = `Bearer sso_1.0_${token}`;
      const response = yield call(bpmnApi.adapter, {
        method: 'get',
        url: urlWithQueries,
        headers: {
          Authorization,
          camundaId,
        },
      });

      const { data: { content: taskAttachments } } = response;

      const formFieldsArray = Object.entries(fields || {});
      const {
        attachedFiles,
        attachedFilesIds,
        updatedFields,
        documentsForSignCounter,
        documentsWithSign,
      } = parseAttachments({
        formFields: formFieldsArray,
        taskAttachments,
        variables,
        camundaId,
      });

      yield put(specActions.checkDocumentsSigns(nsId, documentsWithSign));
      yield put(specActions.handleDocumentsForSignCounter(nsId, documentsForSignCounter));
      yield put(specActions.handleFormFiles(nsId, attachedFiles));
      yield put(specActions.handleUploadedFilesIds(nsId, attachedFilesIds));

      const updatedTaskContext = {
        ...taskContext,
        taskAttachments,
        uiSchema: {
          ...uiSchema,
          fields: updatedFields,
        },
      };
      yield put(specActions.getServiceInfoSuccess(nsId, updatedTaskContext));
    } catch (error) {
      console.log('%c error', 'background: gray; color: #fff', error);
      yield put(specActions.getServiceInfoSuccess(nsId, taskContext));
      yield put(specActions.errorHandlerServices(nsId, {
        hasError: true,
        message: getErrMsg(error),
        errorFrom: 'getTaskAttachments',
      }));
    }
  },
  * [sendElectronicSignDocumentRequest]({
    payload: {
      documentId,
      signId,
    },
  }, nsId) {
    try {
      yield put(specActions.handleSendElectricSignRequestStatus(nsId, true));
      yield delay(700);
      yield put(specActions.sendElectronicSignDocumentRequestSuccess(nsId, {
        documentId,
        signId,
      }));
      yield put(specActions.handleSendElectricSignRequestStatus(nsId, false));
    } catch (error) {
      yield put(specActions.errorHandlerServices(nsId, {
        hasError: true,
        message: getErrMsg(error),
        errorFrom: 'sendElectronicSignDocumentRequest',
      }));
    }
  },
  * [updateFormStatus]({
    payload: {
      data: formData,
      taskId,
      camundaId,
    },
  }, nsId) {
    try {
      yield put(specActions.handleSendServiceFormStatus(nsId, true));
      yield HANDLERS[saveFormContext]({
        payload: {
          taskId,
          camundaId,
          formData,
        },
      }, nsId);

      // TODO: add request to API for form update, when its ready
      yield delay(700);

      yield HANDLERS[getServiceInfo]({
        payload: {
          taskId,
          camundaId,
          isUpdating: true,
        },
      }, nsId);
      yield put(specActions.handleSendServiceFormStatus(nsId, false));
    } catch (error) {
      yield put(specActions.errorHandlerServices(nsId, {
        hasError: true,
        message: getErrMsg(error),
        errorFrom: 'updateFormStatus',
      }));
      yield put(specActions.handleSendServiceFormStatus(nsId, false));
    }
  },
  * [sendCertificate](_, nsId) {
    const ns = `ServiceProfile${nsId}`;
    try {
      const { certificate } = yield select((state) => state[ns]);

      // get base64 certificate and wrap it
      let base64Cert = yield certificate.Export(window.cadesplugin.CADESCOM_ENCODE_BASE64);
      base64Cert = base64Cert.replace(/\r\n/g, '');

      const begin = '-----BEGIN CERTIFICATE-----';
      const end = '-----END CERTIFICATE-----';
      base64Cert = `${begin}${base64Cert}${end}`;

      // configure request
      const headers = { Authorization: `Bearer sso_1.0_${getCookie('digital_mp_at')}` };
      const params = { free: true };

      const { data } = yield call(zvatApi.adapter, {
        method: 'post',
        url: '/authenticate',
        data: base64Cert,
        headers,
        params,
      });

      yield put(specActions.sendCertificateSuccess(nsId, data.EncryptedKey));
      yield put(specActions.sendDecryptedApprove(nsId));
    } catch (e) {
      yield put(specActions.errorHandlerServices(nsId, {
        hasError: true,
        message: e.message,
        errorFrom: 'form-secure-connection',
      }));
    }
  },
  * [sendDecryptedApprove](_, nsId) {
    const ns = `ServiceProfile${nsId}`;
    try {
      const {
        connectionStep,
        certificate,
      } = yield select((state) => state[ns]);
      const { cadesplugin } = window;

      // create EnvelopedData object
      const oEnvelop = yield cadesplugin.CreateObjectAsync('CAdESCOM.CPEnvelopedData');
      yield oEnvelop.propset_ContentEncoding(cadesplugin.CADESCOM_BASE64_TO_BINARY);
      yield oEnvelop.propset_Content(connectionStep.encryptedKey);

      yield oEnvelop.Decrypt(connectionStep.encryptedKey);

      // get decrypted content and certificate's thumbprint
      const content = yield oEnvelop.Content;
      const thumbprint = yield certificate.Thumbprint;

      // configure request
      const headers = { Authorization: `Bearer sso_1.0_${getCookie('digital_mp_at')}` };
      const params = { thumbprint };

      const raw = window.atob(content);
      const rawLength = raw.length;
      const byteArray = new Uint8Array(new ArrayBuffer(rawLength));

      for (let i = 0; i < rawLength; i += 1) {
        byteArray[i] = raw.charCodeAt(i);
      }

      yield call(zvatApi.adapter, {
        method: 'post',
        url: '/approve',
        data: byteArray,
        params,
        headers,
      });

      yield put(specActions.sendDecryptedApproveSuccess(nsId));

      // go to next step
      const { taskContext = '' } = yield select((state) => state[ns]);
      const {
        camundaId = '',
        id: taskId = '',
      } = yield select((state) => state[ns].taskContext.task);
      const withSteps = get(taskContext, 'uiSchema.uiFormType.withSteps', false);

      const data = {
        camundaId,
        taskId,
        withSteps,
        data: {},
        history: rootHistory,
      };

      yield put(specActions.sendServiceForm(nsId, data));
    } catch (e) {
      yield put(specActions.errorHandlerServices(nsId, {
        hasError: true,
        message: e.message,
        errorFrom: 'form-secure-connection',
      }));
    }
  },
};
export default sagaGenerator(HANDLERS);
