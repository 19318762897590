import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

export const formatDateTime = (date) => (dayjs(date).isValid() ? dayjs(date).format('DD.MM.YYYY HH:mm') : '-');
export const isAfterDate = (date) => dayjs().isAfter(date);
export const dateCustomParse = (date) => {
  if (!date) return '';
  dayjs.extend(customParseFormat);
  return dayjs(date, 'DD.MM.YYYY');
};

export default {
  formatDateTime,
  isAfterDate,
  dateCustomParse,
};
