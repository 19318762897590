import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { KrButton, Typography } from 'elk-uikit';
import Icons from '../Icons';
import styles from './index.module.scss';

const ResetButton = ({ onClick, isDisabled }) => (
  <div className={styles.wrapper}>
    <KrButton
      onClick={onClick}
      isDisabled={isDisabled}
      wrapperClasses={{
        button: styles.button,
      }}
    >
      <Icons.Refresh />
      <Typography className={styles.label}>Сбросить фильтр</Typography>
    </KrButton>
  </div>
);

ResetButton.propTypes = {
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

ResetButton.defaultProps = {
  onClick: () => { },
  isDisabled: false,
};

export default memo(ResetButton);
