import configAdapter from '../../../config/config';

const API_URL_V1 = '/api/v1';
const API_URL_V2 = '/api/v2';
const MDM_URL = configAdapter('com.rooxteam.widgets.mdm.api.url');
const MDM_API_SERVICE_URL = configAdapter('com.rooxteam.widgets.mdm.api.service.url');

const INVOICES = '/invoices_for_access_payment';
const PAYSRV_INVOICES = '/paysrv_invoices';
const TARIFFS = '/tariffs_for_access_payment';
const ORG_CONTROLLER = '/org';
const USER_CONTROLLER = '/user-profile';
const USER_PROFILE = '/user_profile';
const CATALOGS = '/catalogs';
const SERVICE = 'service/items';
const ITEMS = '/items';
const SEARCH = 'items/search';
const PARTNER_SERVICES = '/partner_services';
const PARTNER_SERVICE_HISTORY = '/partner_service_history';
const PARTNER_REQUEST = '/partner_request';
const PARTNER_RESPONSE = '/partner_response';
const PARTNER_ACCREDITATIONS = '/partner_accreditations';
const PARTNER_REPORTS = '/partner_reports';
const PARTNER_HISTORY = '/partner_history';
const PARTNERS = '/partners';
const POST = 'POST';
const GET = 'GET';
const PUT = 'PUT';
const DELETE = 'DELETE';
const DETAILS_PARAMS = 'showDetails=1&showRefs=1';
const DETAILS_PARAMS_0 = 'showDetails=0&showRefs=0';
const DETAILS_PARAMS_1 = 'showDetails=1&showRefs=1';
const DETAILS_PARAMS_2 = 'showDetails=2&showRefs=2';
const LINK_HEAD_PARTNER_WITH_PARTNER = '/link_head_partner_with_partner';
const ETP = '/etp';

export default {
  API_URL_V1,
  API_URL_V2,
  MDM_URL,
  MDM_API_SERVICE_URL,
  ORG_CONTROLLER,
  USER_CONTROLLER,
  USER_PROFILE,
  CATALOGS,
  SERVICE,
  POST,
  GET,
  PUT,
  DELETE,
  ITEMS,
  DETAILS_PARAMS,
  DETAILS_PARAMS_0,
  DETAILS_PARAMS_1,
  DETAILS_PARAMS_2,
  PARTNER_SERVICE_HISTORY,
  SEARCH,
  PARTNER_REQUEST,
  INVOICES,
  PARTNER_ACCREDITATIONS,
  TARIFFS,
  PARTNER_RESPONSE,
  PARTNER_SERVICES,
  PARTNER_REPORTS,
  PARTNERS,
  PARTNER_HISTORY,
  LINK_HEAD_PARTNER_WITH_PARTNER,
  ETP,
  PAYSRV_INVOICES,
};
