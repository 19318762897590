const PROCESS_STATUS_DRAFT1 = 1;
const PROCESS_STATUS_EXPORT_ACCELERATOR = 7;

const STATIC_SERVICE_PROVIDED = [
  {
    id: 79439,
    name: 'Экспортный товарный отчет',
    processSequence: 5,
    processStageCount: 5,
    processDefinitionId: 'mti_russian_report:6:1c70917f-3d62-11ea-a07c-5673094ffe35',
    processInstanceId: 'static_test-service-provided',
    camundaId: 'test',
    processStatus: PROCESS_STATUS_DRAFT1,
    processStatusName: 'Завершена обработка',
    createDate: '2020-01-22T21:57:01.991Z',
    lastModifyDate: '2020-01-23T07:47:43.879304Z',
    clientId: '2f237496-b9ec-4eb5-b239-17e24a39d078',
    clientName: 'ООО АЛСЕЗА',
    initiator: '379d09b7-a896-4aa4-80d8-ce8743e444a8',
    initiatorName: null,
    chatId: '1ebb44da-3d62-11ea-a07c-5673094ffe35',
    tasks: [
      {
        id: 79510,
        client: null,
        status: 'В работе',
        priority: 50,
        name: 'Отображеине результата',
        processName: 'Экспортный товарный отчет',
        processInstanceId: '1ebb44da-3d62-11ea-a07c-5673094ffe35',
        chatId: '1ebb44da-3d62-11ea-a07c-5673094ffe35',
        camundaId: 'test',
        externalTaskId: 'provided', // 'a3a18338-3db4-11ea-a07c-567309400004',
        taskDefinitionKey: 'UserTask_0l85wx3',
        formUrl: 'uiSchema25491',
        formKey: 'uiSchema25491',
        createDate: '2020-01-23T07:47:43.69Z',
        strictPlanDate: false,
        planDate: null,
        closeDate: null,
        dueDate: null,
        duration: 0,
        planCompletionDate: null,
        role: 'exporter',
        userId: null,
        criteria: [],
        taskType: 'Конкурирующая задача',
        userFio: null,
      },
    ],
    serviceId: '30cecc0e-fe2e-466b-8278-bd99a743cc45',
    themeGroupId: null,
    themeGroupName: null,
  },
  {
    id: 79449,
    name: 'Экспортный товарный отчет',
    processSequence: 4,
    processStageCount: 5,
    processDefinitionId: 'mti_russian_report:6:1c70917f-3d62-11ea-a07c-5673094ffe35',
    processInstanceId: 'static_test-1ebb44da-3d62-11ea-a07c-5673094ffe35',
    camundaId: 'test',
    processStatus: PROCESS_STATUS_DRAFT1,
    processStatusName: 'Подтверждение оплаты услуг',
    createDate: '2020-01-27T06:07:01.991Z',
    lastModifyDate: '2020-01-28T17:47:43.879304Z',
    clientId: '2f237496-b9ec-4eb5-b239-17e24a39d078',
    clientName: 'ООО АЛСЕЗА',
    initiator: '379d09b7-a896-4aa4-80d8-ce8743e444a8',
    initiatorName: null,
    chatId: '1ebb44da-3d62-11ea-a07c-5673094ffe35',
    tasks: [
      {
        id: 79512,
        client: null,
        status: 'В работе',
        priority: 50,
        name: 'Отображеине результата',
        processName: 'Экспортный товарный отчет',
        processInstanceId: '1ebb44da-3d62-11ea-a07c-5673094ffe35',
        chatId: '1ebb44da-3d62-11ea-a07c-5673094ffe35',
        camundaId: 'test',
        externalTaskId: 'paid',
        taskDefinitionKey: 'UserTask_0l85wx3',
        formUrl: 'uiSchema25491',
        formKey: 'uiSchema25491',
        createDate: '2020-01-28T09:23:03.69Z',
        strictPlanDate: false,
        planDate: null,
        closeDate: null,
        dueDate: null,
        duration: 0,
        planCompletionDate: null,
        role: 'exporter',
        userId: null,
        criteria: [],
        taskType: 'Конкурирующая задача',
        userFio: null,
      },
    ],
    serviceId: '30cecc0e-fe2e-466b-8278-bd99a743cc45',
    themeGroupId: null,
    themeGroupName: null,
  },
];

const STATIC_SERVICE_EVALUATION = [
  {
    id: 794319,
    name: 'Экспортный товарный отчет',
    // processSequence: 5,
    // processStageCount: 5,
    processDefinitionId: 'mti_russian_report:6:1c70917f-3d62-11ea-a07c-567309400006',
    processInstanceId: 'static_test-service-evaluation',
    camundaId: 'test',
    processStatus: PROCESS_STATUS_DRAFT1,
    processStatusName: 'Оценить услугу',
    createDate: '2020-01-22T21:57:01.991Z',
    lastModifyDate: '2020-01-23T07:47:43.879304Z',
    clientId: '2f237496-b9ec-4eb5-b239-17e24a300006',
    clientName: 'ООО АЛСЕЗА',
    initiator: '379d09b7-a896-4aa4-80d8-ce8743e00006',
    initiatorName: null,
    chatId: '1ebb44da-3d62-11ea-a07c-5673094ffe35',
    tasks: [
      {
        id: 79510,
        client: null,
        status: 'В работе',
        priority: 50,
        name: 'Отображеине результата',
        processName: 'Экспортный товарный отчет',
        processInstanceId: '1ebb44da-3d62-11ea-a07c-5673094ffe35',
        chatId: '1ebb44da-3d62-11ea-a07c-5673094ffe35',
        camundaId: 'test',
        externalTaskId: 'evaluation',
        taskDefinitionKey: 'UserTask_0l85wx3',
        formUrl: 'uiSchema25491',
        formKey: 'uiSchema25491',
        createDate: '2020-01-23T07:47:43.69Z',
        strictPlanDate: false,
        planDate: null,
        closeDate: null,
        dueDate: null,
        duration: 0,
        planCompletionDate: null,
        role: 'exporter',
        userId: null,
        criteria: [],
        taskType: 'Конкурирующая задача',
        userFio: null,
      },
    ],
    serviceId: '30cecc0e-fe2e-466b-8278-bd99a743cc45',
    themeGroupId: null,
    themeGroupName: null,
  },
];

const STATIC_SERVICE_DOCUMENT_CHOOSE = [
  {
    id: 794320,
    name: 'Поиск потенциальных иностранных покупателей',
    processSequence: 1,
    processStageCount: 4,
    processDefinitionId: 'mti_russian_report:6:1c70917f-3d62-11ea-a07c-567309400006',
    processInstanceId: 'static_test-service-documents',
    camundaId: 'test',
    processStatus: PROCESS_STATUS_DRAFT1,
    processStatusName: 'Оформление заявки',
    createDate: '2020-01-22T21:57:01.991Z',
    lastModifyDate: '2020-01-23T07:47:43.879304Z',
    clientId: '2f237496-b9ec-4eb5-b239-17e24a300006',
    clientName: 'ООО АЛСЕЗА',
    initiator: '379d09b7-a896-4aa4-80d8-ce8743e00006',
    initiatorName: null,
    chatId: '1ebb44da-3d62-11ea-a07c-5673094ffe35',
    tasks: [
      {
        id: 79511,
        client: null,
        status: 'Оформление заявки',
        priority: 50,
        name: 'Отображеине результата',
        processName: 'Поиск потенциальных иностранных покупателей',
        processInstanceId: '1ebb44da-3d62-11ea-a07c-5673094ffe35',
        chatId: '1ebb44da-3d62-11ea-a07c-5673094ffe35',
        camundaId: 'test',
        externalTaskId: 'nf3',
        taskDefinitionKey: 'UserTask_0l85wx3',
        formUrl: 'uiSchema25491',
        formKey: 'uiSchema25491',
        createDate: '2020-01-23T07:47:43.69Z',
        strictPlanDate: false,
        planDate: null,
        closeDate: null,
        dueDate: null,
        duration: 0,
        planCompletionDate: null,
        role: 'exporter',
        userId: null,
        criteria: [],
        taskType: 'Конкурирующая задача',
        userFio: null,
      },
    ],
    serviceId: '30cecc0e-fe2e-466b-8278-bd99a743cc45',
    themeGroupId: null,
    themeGroupName: null,
  },
];

const STATIC_SERVICE_ACCELERATOR = {
  id: 90928,
  name: 'Базовое консультирование экспортёров о мерах пате...',
  processStatus: PROCESS_STATUS_EXPORT_ACCELERATOR,
  acceleratorType: true,
};

export {
  STATIC_SERVICE_PROVIDED,
  STATIC_SERVICE_EVALUATION,
  STATIC_SERVICE_DOCUMENT_CHOOSE,
  STATIC_SERVICE_ACCELERATOR,
};
