import constants from './constants';

const {
  API_URL_V2,
  API_URL_V0,
  API_URL_V1,
} = constants;

const generateNotifyRoute = (path) => `${API_URL_V2}${path}`;
const generateNotifyRoutev1 = (path) => `${API_URL_V1}${path}`;
const generateOffersRoute = (path) => `${API_URL_V0}${path}`;

export default {
  generateNotifyRoute,
  generateNotifyRoutev1,
  generateOffersRoute,
};
