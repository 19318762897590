import _ from 'lodash';
import React from 'react';

export const getErrMsg = (error) => error?.response?.data?.message || error?.message;

const getIsHTML = (string) => /<\/?[a-z][\s\S]*>/i.test(string);

export const handleUiSchemaTemplates = (templates = {}, variables = {}) => Object.entries(_.omitBy(templates, _.isNil))
  .reduce((acc, [fl, tmpl]) => {
    const tmpltField = _.template(tmpl || '', { interpolate: /{{([\s\S]+?)}}/g });
    const handledHtmlWithTmpl = getIsHTML(tmpltField(variables))
      // eslint-disable-next-line react/no-danger
      ? <div dangerouslySetInnerHTML={{ __html: tmpltField(variables) }} />
      : tmpltField(variables);
    return {
      ...acc,
      [fl]: handledHtmlWithTmpl,
    };
  }, {});

export const handleFormDataWithTmpl = (data) => {
  const { uiSchema = {}, variables = {} } = data;
  const templates = {
    serviceTitle: uiSchema.serviceTitle,
    serviceDescription: uiSchema.serviceDescription,
    formDescription: uiSchema.formDescription,
  };
  const tmpltUiSchema = handleUiSchemaTemplates(templates, variables);

  return {
    ...data,
    uiSchema: { ...data.uiSchema, ...tmpltUiSchema },
  };
};
