import { createActions } from 'redux-actions';

const requestTypes = [
  'CLEAR_PARTNER_SERVICES_FILTERS',
  'GET_PARTNER_SERVICES_FILTERS_REQUEST',
  'GET_PARTNER_SERVICES_FILTERS_SUCCESS',
  'GET_PARTNER_SERVICES_FILTERS_FAILURE',
];

const types = [...requestTypes];

export default createActions(...types);
