/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import theme from '../../styles/theme';

function TopQuantity({
  width,
  height,
  fill,
}) {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.2028 0.21967C11.4731 -0.0732233 11.9115 -0.0732233 12.1818 0.21967L15.1818 3.46967C15.4522 3.76256 15.4522 4.23744 15.1818 4.53033C14.9115 4.82322 14.4731 4.82322 14.2028 4.53033L12.3846 2.56066L12.3846 11.25C12.3846 11.6642 12.0747 12 11.6923 12C11.31 12 11 11.6642 11 11.25L11 2.56066L9.18184 4.53033C8.91148 4.82322 8.47314 4.82322 8.20277 4.53033C7.93241 4.23744 7.93241 3.76256 8.20277 3.46967L11.2028 0.21967Z" fill={fill} />
      <path d="M8.91504 13.9043H7.31982V18H6.50684V13.9043H4.94238V13.2451H8.91504V13.9043ZM9.30615 15.5786C9.30615 15.1128 9.39697 14.6938 9.57861 14.3218C9.76318 13.9497 10.0181 13.6626 10.3433 13.4604C10.6714 13.2583 11.0449 13.1572 11.4639 13.1572C12.1113 13.1572 12.6343 13.3813 13.0327 13.8296C13.4341 14.2778 13.6348 14.874 13.6348 15.6182V15.6753C13.6348 16.1382 13.5454 16.5542 13.3667 16.9233C13.1909 17.2896 12.9375 17.5752 12.6064 17.7803C12.2783 17.9854 11.9004 18.0879 11.4727 18.0879C10.8281 18.0879 10.3052 17.8638 9.90381 17.4155C9.50537 16.9673 9.30615 16.374 9.30615 15.6357V15.5786ZM10.1235 15.6753C10.1235 16.2026 10.2451 16.626 10.4883 16.9453C10.7344 17.2646 11.0625 17.4243 11.4727 17.4243C11.8857 17.4243 12.2139 17.2632 12.457 16.9409C12.7002 16.6157 12.8218 16.1616 12.8218 15.5786C12.8218 15.0571 12.6973 14.6353 12.4482 14.313C12.2021 13.9878 11.874 13.8252 11.4639 13.8252C11.0625 13.8252 10.7388 13.9849 10.4927 14.3042C10.2466 14.6235 10.1235 15.0806 10.1235 15.6753ZM18.5435 18H17.7305V13.9175H15.542V18H14.7246V13.2451H18.5435V18Z" fill={fill} />
    </svg>
  );
}

TopQuantity.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

TopQuantity.defaultProps = {
  width: 24,
  height: 20,
  fill: theme.dodgerBlue,
};

export default React.memo(TopQuantity);
