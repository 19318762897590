import get from 'lodash/get';
import union from 'lodash/union';
import omit from 'lodash/omit';
import map from 'lodash/map';
import { reducerGenerator } from '../../../store/helpers';

import actions from './actions';

const {
  resetServiceState,
  errorHandlerServices,
  updateProductsInfo,
  updateEventInfoErrors,
  getServiceInfoSuccess,
  handleSendServiceFormStatus,
  handleGetServiceInfoLoader,
  getCompanyDocumentsFromFormSuccess,
  updateFormDocuments,
  addFormDocumentsBuffer,
  addProductToForm,
  updateProductsToForm,
  deleteProductFromForm,
  handleSignedDocuments,
  sendElectronicSignDocumentRequestSuccess,
  handleSendElectricSignRequestStatus,
  handleFilesUploadingStatuses,
  handleUploadedFilesIds,
  removeUploadedFilesId,
  handleFormFiles,
  handleFormFilesByName,
  handleUploadingFilesErrors,
  getAllDocumentsForSign,
  getDocumentForSign,
  getDocumentForSignSuccess,
  checkDocumentSignSuccess,
  handleDocumentsForSignCounter,
  saveDocs,
  saveCurrentDocInfo,
  disableSignLoading,
  sendCertificateSuccess,
  saveCertificate,
  toggleCertificatesModal,
  setPromptData,
  resetPromptData,
  getProductsSuccess,
  getCatalogSuccess,
  removeSubtask,
} = actions;

let isPromptEnabled = true;
try {
  isPromptEnabled = !JSON.parse(localStorage.getItem('my-export-leaving-prompt-disabled'));
} catch (error) {
  console.error(error);
}

export const initialState = {
  selectedProducts: [],
  productsInfoErrors: {},
  products: [],
  process: null,
  taskContext: {},
  formDocumentsBuffer: [],
  dropdownDocuments: [],
  error: {
    hasError: false,
    message: '',
    errorFrom: '',
  },
  success: false,
  isSending: false,
  isLoading: false,
  filesUploadingStatuses: {
    uploading: false,
    statuses: [],
    name: '',
  },
  filesUploadingErrors: {
    hasError: false,
    filesIds: [],
  },
  uploadedFilesIds: [],
  formFiles: {},
  certificate: null,
  documentsSignStep: {
    isSignLoading: false,
    signData: '',
    successfullySignedDocuments: [],
    successfullySignedDetails: [],
    documentsForSignCounter: 0,
    allDocs: [],
    currentDocInfo: null,
    isCertificatesModalOpen: false,
  },
  connectionStep: {
    encryptedKey: null,
  },
  promptData: {
    enabled: isPromptEnabled,
    actionType: '',
    args: [],
  },
};

const HANDLERS = {
  [updateProductsInfo]: (state, { name, value }) => ({
    ...state,
    taskContext: {
      ...state.taskContext,
      variables: {
        ...state.taskContext.variables,
        [name]: {
          ...state.taskContext.variables[name],
          value,
        },
      },
    },
  }),
  [updateEventInfoErrors]: (state, payload) => ({
    ...state,
    productsInfoErrors: {
      ...state.productsInfoErrors,
      ...payload,
    },
  }),
  [getServiceInfoSuccess]: (state, payload) => ({
    ...state,
    taskContext: { ...payload },
  }),
  [removeSubtask]: (state, payload) => omit(
    state,
    `taskContext.task.subtasks.${payload}`,
  ),
  [addFormDocumentsBuffer]: (state, payload) => ({
    ...state,
    formDocumentsBuffer: payload,
  }),
  [handleSendServiceFormStatus]: (state, payload) => ({
    ...state,
    isSending: payload,
  }),
  [handleGetServiceInfoLoader]: (state, payload) => ({
    ...state,
    isLoading: payload,
  }),
  [getCompanyDocumentsFromFormSuccess]: (state, payload) => ({
    ...state,
    dropdownDocuments: payload,
  }),
  [updateFormDocuments]: (state, payload) => ({
    ...state,
    formDocumentsBuffer: payload,
  }),
  [handleSignedDocuments]: (state, payload) => ({
    ...state,
    documentsSignStep: {
      ...state.documentsSignStep,
      signedDocumentsToCheck: payload,
    },
  }),
  [sendElectronicSignDocumentRequestSuccess]: (state, payload) => ({
    ...state,
    documentsSignStep: {
      ...state.documentsSignStep,
      successfullySignedDocuments: [
        ...state.documentsSignStep.successfullySignedDocuments,
        payload,
      ],
    },
  }),
  [handleSendElectricSignRequestStatus]: (state, payload) => ({
    ...state,
    documentsSignStep: {
      ...state.documentsSignStep,
      electricSignRequestStatus: payload,
    },
  }),
  [errorHandlerServices]: (state, payload) => ({
    ...state,
    error: payload,
  }),
  [resetServiceState]: () => ({
    ...initialState,
  }),
  [updateProductsToForm]: (state, products) => ({
    ...state,
    selectedProducts: [...products],
  }),
  [addProductToForm]: (state, newProduct) => ({
    ...state,
    selectedProducts: [...state.selectedProducts, newProduct],
  }),
  [deleteProductFromForm]: (state, productId) => ({
    ...state,
    selectedProducts: state.selectedProducts.filter((el) => el.uuid !== productId),
  }),
  [handleFilesUploadingStatuses]: (state, payload) => ({
    ...state,
    filesUploadingStatuses: payload,
  }),
  [handleUploadedFilesIds]: (state, payload) => ({
    ...state,
    uploadedFilesIds: state.uploadedFilesIds.concat(payload),
  }),
  [removeUploadedFilesId]: (state, payload) => ({
    ...state,
    uploadedFilesIds: state.uploadedFilesIds.filter((id) => id !== payload),
  }),
  [handleFormFilesByName]: (state, payload) => ({
    ...state,
    formFiles: {
      ...state.formFiles,
      [payload.name]:
        state.formFiles[payload.name]
          ? [...state.formFiles[payload.name], ...payload.files]
          : payload.files
      ,
    },
  }),
  [handleFormFiles]: (state, payload) => ({
    ...state,
    formFiles: payload,
  }),
  [handleUploadingFilesErrors]: (state, payload) => ({
    ...state,
    filesUploadingErrors: payload,
  }),
  [saveDocs]: (state, payload) => ({
    ...state,
    documentsSignStep: {
      ...state.documentsSignStep,
      allDocs: payload,
    },
  }),
  [getAllDocumentsForSign]: (state) => ({
    ...state,
    documentsSignStep: {
      ...state.documentsSignStep,
      isSignLoading: true,
    },
  }),
  [saveCurrentDocInfo]: (state, payload) => ({
    ...state,
    documentsSignStep: {
      ...state.documentsSignStep,
      currentDocInfo: payload,
    },
  }),
  [getDocumentForSign]: (state) => ({
    ...state,
    documentsSignStep: {
      ...state.documentsSignStep,
      isSignLoading: true,
    },
  }),
  [getDocumentForSignSuccess]: (state, payload) => ({
    ...state,
    documentsSignStep: {
      ...state.documentsSignStep,
      signData: payload.signData,
    },
  }),
  [checkDocumentSignSuccess]: (state, payload) => {
    let successfullySignedDetails = payload;
    if (!Array.isArray(payload)) {
      console.warn(`[deprecated: checkDocumentSignSuccess payload must be array, got "${payload}"]`);
      successfullySignedDetails = [{
        documentId: payload,
      }];
    }

    const successfullySignedDocuments = union(
      state.documentsSignStep.successfullySignedDocuments,
      map(successfullySignedDetails, (d) => d?.documentId),
    );

    return {
      ...state,
      documentsSignStep: {
        ...state.documentsSignStep,
        successfullySignedDocuments,
        successfullySignedDetails: [...state.documentsSignStep.successfullySignedDetails, ...successfullySignedDetails],
        // eslint-disable-next-line max-len
        isSignLoading: get(state, 'documentsSignStep.allDocs.length', false) ? state.documentsSignStep.isSignLoading : false,
      },
    };
  },
  [disableSignLoading]: (state) => ({
    ...state,
    documentsSignStep: {
      ...state.documentsSignStep,
      isSignLoading: false,
    },
  }),
  [handleDocumentsForSignCounter]: (state, payload) => ({
    ...state,
    documentsSignStep: {
      ...state.documentsSignStep,
      documentsForSignCounter: payload,
    },
  }),
  [sendCertificateSuccess]: (state, payload) => ({
    ...state,
    connectionStep: {
      encryptedKey: payload,
    },
  }),
  [saveCertificate]: (state, payload) => ({
    ...state,
    certificate: payload,
  }),
  [toggleCertificatesModal]: (state) => {
    const isOpen = !state.documentsSignStep.isCertificatesModalOpen;
    return ({
      ...state,
      documentsSignStep: {
        ...state.documentsSignStep,
        allDocs: [],
        currentDocInfo: null,
        isCertificatesModalOpen: isOpen,
      },
      certificate: isOpen ? null : state.certificate,
      error: {
        hasError: false,
        message: '',
        errorFrom: '',
      },
    });
  },
  [setPromptData]: (state, payload) => ({
    ...state,
    promptData: payload,
  }),
  [resetPromptData]: (state) => ({
    ...state,
    promptData: {
      enabled: true,
      actionType: '',
      args: [],
    },
  }),
  [getProductsSuccess]: (state, payload) => ({
    ...state,
    products: payload,
  }),
  [getCatalogSuccess]: (state, payload) => ({
    ...state,
    mdmCatalog: payload,
  }),
};

export const buildReducer = (salt) => {
  const overwritten = {
    [`${errorHandlerServices}_${salt}`]: (state, { errorFrom, message }) => {
      console.error(`${errorFrom} subform ${salt}`, message);
      return state;
    },
  };
  const handlers = Object.entries(HANDLERS).reduce((acc, [key, handler]) => ({
    ...acc,
    [`${key}_${salt}`]: handler,
  }), {});
  return reducerGenerator(initialState, { ...handlers, ...overwritten });
};

export default reducerGenerator(initialState, HANDLERS);
