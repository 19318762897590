/* eslint-disable import/no-unresolved, import/extensions */
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import user from './user';
import chat from './chat';
import replies from './replies';
import requests from './requests';
import auth from './auth';
import utils from './utils';
import errorResponse from './errorResponse';
import PasswordRecovery from './passwordRecovery';
import ServiceProfile from '../../pages/ServiceProfile/effects/reducer';
import Services from '../../pages/Services/effects/reducer';
import ServicesList from './services';
import accreditationAccesses from './accreditationAccesses';
import partnerServicesFilters from './partnerServicesFilters';

export const history = createBrowserHistory();

const staticReducers = {
  router: connectRouter(history),
  user,
  chat,
  replies,
  requests,
  auth,
  utils,
  errorResponse,
  PasswordRecovery,
  ServiceProfile,
  Services,
  ServicesList,
  accreditationAccesses,
  partnerServicesFilters,
};

export default staticReducers;
