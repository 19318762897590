import adapter from './adapter';
import constants from './constants';
import utils from './utils';

const tasksApi = {
  adapter,
  constants,
  utils,
};

export default tasksApi;
