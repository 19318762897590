import { createActions } from 'redux-actions';

const syncTypes = [
  'GET_CONFIRMED_SUCCESS',
  'GET_COMPANY_INFO',
  'GET_COMPANY_INFO_FAILURE',
  'GET_COMPANY_INFO_SUCCESS',

  'GET_ACC_REQUEST_INFO',
  'GET_ACC_REQUEST_INFO_FAILURE',
  'GET_ACC_REQUEST_INFO_SUCCESS',

  'CREATE_COMPANY_EDIT_PROCESS',
  'CREATE_COMPANY_EDIT_PROCESS_SUCCESS',

  'GET_PARTNER_SERVICES_SUCCESS',
  'GO_TO_EDIT_TASK',

  'CHANGE_PRIVATE_SETTING',
  'CHANGE_PRIVATE_SETTING_FAILURE',
  'CHANGE_PRIVATE_SETTING_SUCCESS',
];

export default createActions(...syncTypes);
