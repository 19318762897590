/* eslint-disable import/no-unresolved */
import {
  put,
  call,
  takeEvery,
  all,
  select,
} from 'redux-saga/effects';
// import { push } from 'connected-react-router';
import axios from 'axios';
import { mdmApi, getCookie, bpmnApi } from '../../../utils';
import configAdapter from '../../../config/config';
import actions from './actions';

const {
 CATALOGS, POST, API_URL_V1,
} = mdmApi.constants;

const { BPMN_PROCESS_API } = bpmnApi.constants;

const {
  getAccreditationHistory,
  getAccreditationHistorySuccess,
  getAccreditationHistoryFailure,
  setDraftStatus,
  getAttachments,
  getAttachmentsSuccess,
} = actions;

const headers = {
  Authorization: `Bearer sso_1.0_${getCookie('digital_mp_at')}`,
  Accept: 'application/json, text/plain, */*',
  'Accept-Encoding': 'gzip, deflate',
  Connection: 'keep-alive',
  'Content-Type': 'application/json',
};

function* getFileData(options) {
  const reqOptions = {
    baseURL: options?.url,
    ...options,
    headers: { ...headers, camundaId: bpmnApi.constants.CAMUNDA_ID },
  };
  return yield call(axios, reqOptions);
}

const HANDLERS = {
  * [getAccreditationHistory]({ payload }) {
    try {
      const { data: { content } } = yield call(mdmApi.adapter, {
        method: POST,
        // eslint-disable-next-line max-len
        url: `${API_URL_V1}${CATALOGS}/acc_req_history/items/search?size=1&showRefs=1&showDetails=1`,
        headers,
        data: { and: [{ or: [{ and: [{ accreditationRequest: payload }] }] }] },
      });
      yield put(getAccreditationHistorySuccess(content && content.length ? content[0] : null));
    } catch (err) {
      yield put(getAccreditationHistoryFailure(err));
    }
  },
  * [setDraftStatus]() {
    try {
      const { currentOrgId, processId } = yield select((state) => ({
        currentOrgId: state.auth.data.currentOrgId,
        processId: state.Services.json.accreditationRequest.processId,
      }));
      const serviceId = configAdapter('com.rooxteam.widgets.service_id_accreditation');

      const { data } = yield call(bpmnApi.adapter, {
        method: POST,
        // eslint-disable-next-line max-len
        url: `${BPMN_PROCESS_API}/process_partner_accreditation/start?client=${currentOrgId}&serviceId=${serviceId}&next=true`,
        headers,
        data: { historyProcessId: { type: 'string', value: processId } },
      });

      const { camundaId, taskId } = data;

      if (camundaId && taskId) {
        window.location = `/accreditation-requests/services/${camundaId}/${taskId}`;
        // yield put(push(`/services/${camundaId}/${taskId}`));
      }
    } catch (err) {
      console.log(err);
    }
  },
  * [getAttachments]({ payload }) {
    try {
      const url = bpmnApi.utils.getProcessAttachmentsUrl(payload);
      const { data: { content } } = yield call(bpmnApi.adapter, {
        method: 'GET',
        url,
        headers: {
          ...headers,
          camundaId: 'camunda-exp-search',
        },
      });
      const filesList = content.map((fileData) => (
        call(getFileData, {
          url: fileData?.url,
          responseType: 'blob',
        })));
      const files = yield all(filesList);
      files.forEach((fileData, index) => {
        content[index].link = fileData?.data;
      });
      yield put(getAttachmentsSuccess([...content]));
    } catch (error) {
      const { message = '' } = error;
      console.error(message);
      yield put(getAttachmentsSuccess([]));
    }
    },
};

export default function* sagaReducer() {
  const sagas = Object
    .keys(HANDLERS)
    .reduce((acc, key) => {
      if (Object.prototype.hasOwnProperty.call(HANDLERS, key)) {
        acc.push(takeEvery(key, HANDLERS[key]));
      }
      return acc;
    }, []);
  yield all([...sagas]);
}
