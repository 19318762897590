import * as actions from '../actions/user';

export const initialState = {
  employees: {
    active: [],
    blocked: [],
    invited: [],
  },
  errorUsersList: null,
  inviteSuccess: false,
  error: null,
  currentUser: null,
  showUserInfo: false,
  modalInvite: {
    showModal: false,
    mode: 'user',
  },
  modalBlock: {
    showModal: false,
    mode: 'userBlock',
    users: [],
  },
  modalInfoUserInvite: false,
  currentInviteUser: null,
  snackbar: {
    message: '',
    type: 'success',
    showIcon: true,
    showSnackbar: false,
  },
  // eslint-disable-next-line max-len
  partnerAccreditationsCount: {
    isLoading: true,
    count: 0, // Данный параметр используется в компоненте TabsBlock для определения зарегестрирован партнер или нет (т.к. при регистрации через ЭЦП/ЕСИА выдается роль аккредитованного пользователя)
  },
};

const HANDLERS = {
  [actions.sendInviteSuccess]: (state) => ({
    ...state,
    inviteSuccess: true,
  }),
  [actions.sendInviteFailure]: (state, payload) => ({
    ...state,
    error: payload,
    inviteSuccess: false,
  }),
  [actions.resetInviteSuccessStatus]: (state) => ({
    ...state,
    inviteSuccess: false,
  }),
  [actions.getUsersRequest]: (state) => ({
    ...state,
    employees: {
      active: [],
      blocked: [],
      invited: [],
    },
  }),
  [actions.getUsersSuccess]: (state, payload) => ({
    ...state,
    employees: payload,
    errorUsersList: null,
  }),
  [actions.getUsersFailure]: (state, payload) => ({
    ...state,
    errorUsersList: payload,
    payload: {
      active: [],
      blocked: [],
      invited: [],
    },
  }),
  [actions.getCurrentUser]: (state, payload) => ({
    ...state,
    currentUser: payload,
  }),
  [actions.showUserInfo]: (state, payload) => ({
    ...state,
    showUserInfo: payload,
  }),
  [actions.showModalInvite]: (state, payload) => ({
    ...state,
    modalInvite: {
      ...state.modalInvite,
      showModal: payload.show,
      mode: payload.mode,
    },
  }),
  [actions.showModalBlock]: (state, payload) => ({
    ...state,
    modalBlock: {
      ...state.modalBlock,
      showModal: payload.show || false,
      mode: payload.mode || 'userBlock',
      users: payload.users || [],
    },
  }),
  [actions.showModalInfoUserInvite]: (state, payload) => ({
    ...state,
    modalInfoUserInvite: payload,
  }),
  [actions.getCurrentInviteUser]: (state, payload) => ({
    ...state,
    currentInviteUser: payload,
  }),
  [actions.sendUsersBlockFailure]: (state, payload) => ({
    ...state,
    error: payload,
  }),
  [actions.sendUsersUnblockFailure]: (state, payload) => ({
    ...state,
    error: payload,
  }),
  [actions.sendUserBlockFailure]: (state, payload) => ({
    ...state,
    error: payload,
  }),
  [actions.sendUserUnblockFailure]: (state, payload) => ({
    ...state,
    error: payload,
  }),
  [actions.showSnackBar]: (state, payload) => ({
    ...state,
    snackbar: {
      ...state.snackbar,
      message: payload.message || '',
      type: payload.type || 'success',
      showIcon: payload.showIcon || true,
      showSnackbar: payload.showSnackbar || false,
    },
  }),
  [actions.getPartnerAccreditationsCountSuccess]: (state, count) => ({
    ...state,
    partnerAccreditationsCount: {
      isLoading: false,
      count,
    },
  }),
  [actions.getPartnerAccreditationsCountFailure]: (state) => ({
    ...state,
    partnerAccreditationsCount: {
      isLoading: false,
      count: 0,
    },
  }),
  ...initialState,
};

export default function serviceProfileReducer(state = initialState, { type, payload }) {
  const handler = HANDLERS[type];
  return handler ? handler(state, payload) : state;
}
