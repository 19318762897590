export const SEND_INVITE_REQUEST = 'SEND_INVITE_REQUEST';
export const SEND_INVITE_SUCCESS = 'SEND_INVITE_SUCCESS';
export const SEND_INVITE_FAILURE = 'SEND_INVITE_FAILURE';

export const RESET_INVITE_SUCCESS_STATUS = 'RESET_INVITE_SUCCESS_STATUS';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const SHOW_USER_INFO = 'SHOW_USER_INFO';

export const SHOW_MODAL_INVITE = 'SHOW_MODAL_INVITE';
export const SHOW_MODAL_BLOCK = 'SHOW_MODAL_BLOCK';
export const SHOW_MODAL_INFO_USER_INVITE = 'SHOW_MODAL_INFO_USER_INVITE';

export const GET_CURRENT_INVITE_USER = 'GET_CURRENT_INVITE_USER';

export const SEND_USERS_BLOCK_REQUEST = 'SEND_USERS_BLOCK_REQUEST';
export const SEND_USERS_BLOCK_SUCCESS = 'SEND_USERS_BLOCK_SUCCESS';
export const SEND_USERS_BLOCK_FAILURE = 'SEND_USERS_BLOCK_FAILURE';

export const SEND_USER_BLOCK_REQUEST = 'SEND_USER_BLOCK_REQUEST';
export const SEND_USER_BLOCK_SUCCESS = 'SEND_USER_BLOCK_SUCCESS';
export const SEND_USER_BLOCK_FAILURE = 'SEND_USER_BLOCK_FAILURE';

export const SEND_USERS_UNBLOCK_REQUEST = 'SEND_USERS_UNBLOCK_REQUEST';
export const SEND_USERS_UNBLOCK_SUCCESS = 'SEND_USERS_UNBLOCK_SUCCESS';
export const SEND_USERS_UNBLOCK_FAILURE = 'SEND_USERS_UNBLOCK_FAILURE';

export const SEND_USER_UNBLOCK_REQUEST = 'SEND_USER_UNBLOCK_REQUEST';
export const SEND_USER_UNBLOCK_SUCCESS = 'SEND_USER_UNBLOCK_SUCCESS';
export const SEND_USER_UNBLOCK_FAILURE = 'SEND_USER_UNBLOCK_FAILURE';

export const SHOW_SNACK_BAR = 'SHOW_SNACK_BAR';

export const GET_PARTNER_ACCREDITATIONS_COUNT_REQUEST = 'GET_PARTNER_ACCREDITATIONS_COUNT_REQUEST';
export const GET_PARTNER_ACCREDITATIONS_COUNT_SUCCESS = 'GET_PARTNER_ACCREDITATIONS_COUNT_SUCCESS';
export const GET_PARTNER_ACCREDITATIONS_COUNT_FAILURE = 'GET_PARTNER_ACCREDITATIONS_COUNT_FAILURE';
