import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const FilterTabsWrapper = ({ children }) => (
  <div className={styles.wrapper}>
    {children}
  </div>
);

FilterTabsWrapper.defaultProps = {
  children: <></>,
};

FilterTabsWrapper.propTypes = {
  children: PropTypes.node,
};

export default memo(FilterTabsWrapper);
