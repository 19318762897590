import get from 'lodash/get';
import mdmApi from '../api/mdmApi';

const { generateMDMRoute } = mdmApi.utils;
console.log('mdmApi', mdmApi);
const { CATALOGS, PARTNER_ACCREDITATIONS, SEARCH } = mdmApi.constants;
console.log('PARTNER_ACCREDITATIONS', PARTNER_ACCREDITATIONS);

const formatServices = (data) => data.map((item) => ({
  value: get(item, 'partnerService.name', ''),
  id: get(item, 'partnerService.uuid', ''),
  parentId: get(item, 'accDirection.uuid', ''),
  data: {
    value: get(item, 'partnerService.name', ''),
    id: get(item, 'partnerService.uuid', ''),
    parentId: get(item, 'accDirection.uuid', ''),
  },
}));

const formatDirection = (data) => Object.values(
  data.reduce((acc, item) => {
    const { uuid, name } = get(item, 'accDirection', { uuid: null, name: null });
    if (uuid && acc[uuid]) return acc;
    return {
      ...acc,
      [uuid]: {
        id: uuid,
        value: name,
        data: {
          id: uuid,
          value: name,
        },
      },
    };
  }, {}),
);

const directionSelector = {
  data: (value) => ({ 'accDirection.name_like_ci': value }),
  resultObjectParams: {
    name: 'name',
    key: 'uuid',
  },
  getValue: (data) => formatDirection(data),
  params: () => ({ size: 100, showRefs: 1, showDetails: 1 }),
};

const serviceSelector = {
  data: (value) => ({ 'partnerService.name_like_ci': value, 'accDirection.available': true }),
  getValue: (data) => formatServices(data),
  params: () => ({ size: 100, showRefs: 1, showDetails: 1 }),
};

const serviceCatalogApi = {
  url: generateMDMRoute(`${CATALOGS}${PARTNER_ACCREDITATIONS}/${SEARCH}`, false),
  ...serviceSelector,
};

const directionCatalogApi = {
  url: generateMDMRoute(`${CATALOGS}${PARTNER_ACCREDITATIONS}/${SEARCH}`, false),
  ...directionSelector,
};

export {
  formatServices,
  formatDirection,
  directionSelector,
  serviceSelector,
  serviceCatalogApi,
  directionCatalogApi,
};
