/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import theme from '../../styles/theme';

function TopTime({
  width,
  height,
  fill,
}) {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.95023 0.666667C4.95023 0.298477 5.24909 0 5.61776 0H9.62296C9.99163 0 10.2905 0.298477 10.2905 0.666667C10.2905 1.03486 9.99163 1.33333 9.62296 1.33333H5.61776C5.24909 1.33333 4.95023 1.03486 4.95023 0.666667ZM11.243 1.8729C11.9381 1.17775 13.066 1.17775 13.7611 1.8729C14.4548 2.56665 14.4548 3.69038 13.7611 4.38413L13.4252 4.72011C14.2423 5.88581 14.7222 7.30852 14.7222 8.84235C14.7222 12.7903 11.5436 16 7.61111 16C3.67773 16 0.5 12.7903 0.5 8.84235C0.5 4.89443 3.67773 1.6847 7.61111 1.6847C8.72311 1.6847 9.77673 1.94206 10.7151 2.40091L11.243 1.8729ZM11.8831 3.11972C12.12 3.29921 12.3454 3.49322 12.558 3.70042L12.8164 3.442C12.9896 3.26879 12.9896 2.98824 12.8164 2.81503C12.6429 2.64148 12.3612 2.64148 12.1877 2.81503L11.8831 3.11972ZM7.61111 3.01803C4.42595 3.01803 1.83507 5.6199 1.83507 8.84235C1.83507 12.0648 4.42595 14.6667 7.61111 14.6667C10.7955 14.6667 13.3872 12.0647 13.3872 8.84235C13.3872 7.411 12.876 6.1025 12.0281 5.08904C11.9968 5.05159 11.965 5.01454 11.9327 4.9779C11.5408 4.53259 11.0824 4.1485 10.5737 3.84137C10.5494 3.82666 10.5249 3.81214 10.5003 3.7978C9.65081 3.30188 8.66471 3.01803 7.61111 3.01803ZM7.62036 4.44444C7.98903 4.44444 8.28789 4.74292 8.28789 5.11111V9.11111C8.28789 9.4793 7.98903 9.77778 7.62036 9.77778C7.25169 9.77778 6.95283 9.4793 6.95283 9.11111V5.11111C6.95283 4.74292 7.25169 4.44444 7.62036 4.44444Z" fill={fill} />
    </svg>
  );
}

TopTime.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fill: PropTypes.string,
};

TopTime.defaultProps = {
  width: 15,
  height: 20,
  fill: theme.dodgerBlue,
};

export default React.memo(TopTime);
