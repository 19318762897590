import actions from '../actions/accreditationAccesses';
import { reducerGenerator } from '../helpers';

export const initialState = {
  etp_acc_creation_national: false,
  error: null,
  isLoading: false,
  hasAccred: true,
  isAffil: false,
};
const {
  getAccessesEtpAccCreationNational,
  getAccessesEtpAccCreationNationalSuccess,
  getAccessesEtpAccCreationNationalError,
  getPartnerAccreditations,
  getPartnerAccreditationsSuccess,
  getPartnerAccreditationsError,
  getAccessesAffilates,
  getAccessesAffilatesSuccess,
  getAccessesAffilatesError,
} = actions;
const HANDLERS = {
  [getAccessesEtpAccCreationNational]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [getAccessesEtpAccCreationNationalSuccess]: (state, payload) => ({
    ...state,
    etp_acc_creation_national: payload,
    isLoading: false,
  }),
  [getAccessesEtpAccCreationNationalError]: (state, payload) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [getAccessesAffilates]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [getAccessesAffilatesSuccess]: (state, payload) => ({
    ...state,
    isAffil: payload,
    isLoading: false,
  }),
  [getAccessesAffilatesError]: (state, payload) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [getPartnerAccreditations]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [getPartnerAccreditationsSuccess]: (state, payload) => ({
    ...state,
    isLoading: false,
    hasAccred: payload,
  }),
  [getPartnerAccreditationsError]: (state, payload) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
};

export default reducerGenerator(initialState, HANDLERS);
