import { all } from 'redux-saga/effects';

import formSagas from './sagas/formSagas';
import mainFormSagas from '../components/ServiceForm/effects';
import fileSagas from './sagas/fileSagas';
import documentsSagas from './sagas/documentsSagas';
import productSagas from './sagas/productSagas';

export default function* allFormSagas(nsId) {
  yield all([
    formSagas(nsId),
    mainFormSagas(),
    fileSagas(nsId),
    documentsSagas(nsId),
    productSagas(),
  ]);
}
