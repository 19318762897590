import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Icons } from 'elk-uikit';
import { hooks } from 'utils';
import styles from './Pagination.module.scss';

const {
  usePagination,
} = hooks;

const ICON_PARAMS = {
  width: 14,
  height: 14,
  fill: '#f2f5fe',
};

const PaginationItem = ({
  item,
  onSelect,
  wrapperClasses,
  active,
}) => (
  <li className={cn(styles.item, wrapperClasses.item)}>
    {
      typeof item === 'string'
        ? <div className={cn(styles.itemEllipsis, wrapperClasses.itemEllipsis)}>...</div>
        : (
          <button
            type="button"
            className={cn(styles.itemButton, (active ? styles.active : ''), wrapperClasses.itemButton)}
            onClick={() => {
              if (active) return;
              onSelect(item);
            }}
          >
            {item}
          </button>
        )
    }
  </li>
);

PaginationItem.propTypes = {
  item: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onSelect: PropTypes.func.isRequired,
  wrapperClasses: PropTypes.shape({
    item: PropTypes.string,
    itemEllipsis: PropTypes.string,
    itemButton: PropTypes.string,
  }).isRequired,
  active: PropTypes.bool.isRequired,
};

const Pagination = ({
  withControls,
  isLoop,
  wrapperClasses,
  current,
  total,
  onSelect,
}) => {
  const {
    content,
    onNextButtonClick,
    onPrevButtonClick,
  } = usePagination({
    current,
    isLoop,
    total,
    onSelect,
  });

  return (
    <div className={cn(styles.container, wrapperClasses.container)}>
      <div className={cn(styles.wrapper, wrapperClasses.wrapper)}>
        {withControls && (
          <button
            type="button"
            className={cn(styles.controlButton, styles.prevBtn, wrapperClasses.prevBtn)}
            onClick={onPrevButtonClick}
          >
            <Icons.ArrowLeft
              width={ICON_PARAMS.width}
              height={ICON_PARAMS.height}
              fill={ICON_PARAMS.fill}
            />
          </button>
        )}
        <ul className={cn(styles.content, wrapperClasses.content)}>
          {
            content.map((el) => (
              <PaginationItem
                key={el}
                item={el}
                active={el === current}
                onSelect={onSelect}
                wrapperClasses={{
                  item: wrapperClasses.item,
                  itemButton: wrapperClasses.itemButton,
                  itemEllipsis: wrapperClasses.itemEllipsis,
                }}
              />
            ))
          }
        </ul>
        {withControls && (
          <button
            type="button"
            className={cn(styles.controlButton, styles.nextBtn, wrapperClasses.nextBtn)}
            onClick={onNextButtonClick}
          >
            <Icons.ArrowRight
              width={ICON_PARAMS.width}
              height={ICON_PARAMS.height}
              fill={ICON_PARAMS.fill}
            />
          </button>
        )}
      </div>
    </div>
  );
};

Pagination.defaultProps = {
  withControls: true,
  isLoop: false,
  wrapperClasses: {
    container: '',
    wrapper: '',
    content: '',
    prevBtn: '',
    nextBtn: '',
    item: '',
    itemEllipsis: '',
    itemButton: '',
  },
  current: 1,
};

Pagination.propTypes = {
  withControls: PropTypes.bool,
  isLoop: PropTypes.bool,
  wrapperClasses: PropTypes.shape({
    container: PropTypes.string,
    wrapper: PropTypes.string,
    content: PropTypes.string,
    prevBtn: PropTypes.string,
    nextBtn: PropTypes.string,
    item: PropTypes.string,
    itemEllipsis: PropTypes.string,
    itemButton: PropTypes.string,
  }),
  current: PropTypes.number,
  total: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default memo(Pagination);
