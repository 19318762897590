import { all } from 'redux-saga/effects';

import subFormSagas from './sagas';
import fileSagas from '../../../effects/sagas/fileSagas';
import documentsSagas from '../../../effects/sagas/documentsSagas';

export default (formSagas) => function* allFormSagas(nsId) {
  yield all([
    subFormSagas(nsId),
    formSagas(nsId),
    fileSagas(nsId),
    documentsSagas(nsId),
  ]);
};
