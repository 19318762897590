import React, {
  memo,
  useCallback,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Icons } from 'elk-uikit';
import styles from './ModalBanner.module.scss';

const { Close } = Icons;

const ModalBanner = ({
  isShown,
  toggleVisibility,
  children,
  height,
  useLeftMenu,
  parentLayout,
  closeText,
  bodyOverflowY,
}) => {
  useEffect(() => {
    if (isShown && !bodyOverflowY) {
      const body = document.querySelector('body');
      body.style.overflowY = 'hidden';

      return () => {
        body.style.overflowY = 'auto';
      };
    }

    return () => {};
  }, [isShown, bodyOverflowY]);

  const closeModal = useCallback(() => {
    if (isShown) toggleVisibility();
  }, [isShown, toggleVisibility]);

  return (
    <>
      {isShown && (
        <div key={1}>
          <div
            className={cn([
              styles.modalWrapper,
              parentLayout,
              { [styles.leftMenu]: useLeftMenu },
            ])}
            style={{ height }}
            id="elk-modal"
          >
            <button
              type="button"
              className={styles.closeButton}
              onClick={() => closeModal()}
            >
              {closeText || (
                <Close
                  width={16}
                  height={16}
                  simple
                />
              )}
            </button>
            <div key={3} className={styles.modalContent}>
              {children}
            </div>
          </div>
          <div
            className={cn([
              styles.modalBackground,
              { [styles.leftMenu]: useLeftMenu },
            ])}
            onClick={closeModal}
            key={2}
          />
        </div>
      )}
    </>
  );
};

ModalBanner.propTypes = {
  isShown: PropTypes.bool,
  toggleVisibility: PropTypes.func,
  children: PropTypes.node,
  closeText: PropTypes.string,
  parentLayout: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  useLeftMenu: PropTypes.bool,
  bodyOverflowY: PropTypes.bool,
};

ModalBanner.defaultProps = {
  isShown: false,
  toggleVisibility: () => {},
  children: null,
  closeText: null,
  parentLayout: null,
  height: 499,
  useLeftMenu: true,
  bodyOverflowY: true,
};

export default memo(ModalBanner);
