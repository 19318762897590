import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './index.module.scss';

const TextArea = ({
  name,
  type,
  value,
  maxRows,
  placeholder,
  onChange,
  onSearch,
  onClose,
  customStyle,
  disabled,
  ...restProps
}) => (
  <div className={styles.wrapperTextarea}>
    <textarea
      className={cn(styles.textarea, customStyle)}
      onFocus={() => {}}
      onBlur={() => {}}
      value={value}
      disabled={disabled}
      rows={maxRows}
      onChange={(event) => {
        onChange(event.target);
      }}
      {...restProps}
    >
      {value}
    </textarea>
  </div>
);

TextArea.propTypes = {
  type: PropTypes.oneOf(['text']),
  name: PropTypes.string,
  value: PropTypes.string,
  maxRows: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  onClose: PropTypes.func,
  customStyle: PropTypes.string,
  disabled: PropTypes.string,
};
TextArea.defaultProps = {
  type: 'text',
  name: '',
  value: '',
  maxRows: '',
  placeholder: '',
  onChange: () => {},
  onSearch: () => {},
  onClose: () => {},
  customStyle: '',
  disabled: false,
};

export default memo(TextArea);
