import {
  put,
  takeEvery,
  all,
  call,
  select,
} from 'redux-saga/effects';
import qs from 'qs';

import * as actions from '../actions/user';
import userUtils from '../../utils/pages/users';
import { getCookie, uidmApi, mdmApi } from '../../utils';

const { generateMDMRoute } = mdmApi.utils;
const { CATALOGS, POST } = mdmApi.constants;

const CATALOG_ID = 'partner_accreditations';

const BASE_DATA = {
  client_id: 'rec_elk_m2m',
  client_secret: 'password',
  realm: '/customer',
  grant_type: 'urn:roox:params:oauth:grant-type:m2m',
  response_type: 'token cookie',
  service: 'invite-principal',
};

const SEND_DATA = {
  _eventId: 'next',
};

const HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/x-www-form-urlencoded',
};

const HEADERS_AUTH = {
  headers: {
    Authorization: `Bearer sso_1.0_${getCookie('digital_mp_at')}`,
  },
};

const generateConfigRequestUrlBlock = (orgId, userId, method) => ({
  url: uidmApi.utils.generateUrlBlockUser(orgId, userId),
  headers: {
    'content-type': 'application/json',
    ...HEADERS_AUTH.headers,
  },
  method,
});

const SIZE_USERS = 1000;

const HANDLERS = {
  * [actions.getPartnerAccreditationsCountRequest]() {
    try {
      const currentOrgId = yield select((state) => state.auth.data.currentOrgId);

      const url = generateMDMRoute(`${CATALOGS}/${CATALOG_ID}/items/search`);

      const { data: { totalElements } } = yield call(mdmApi.adapter, {
        method: POST,
        url,
        headers: {
          Authorization: `Bearer sso_1.0_${getCookie('digital_mp_at')}`,
        },
        data: { and: [{ or: [{ and: [{ partner: currentOrgId }] }] }] },
        params: { size: 1, hideDepricated: true },
      });

      yield put(actions.getPartnerAccreditationsCountSuccess(totalElements));
    } catch (error) {
      console.log(error);
      yield put(actions.getPartnerAccreditationsCountFailure(error));
    }
  },
  * [actions.getUsersRequest]() {
    try {
      const organisationId = yield select((state) => state.search.currentOrg.uuid);
      // GET LIST USERS FROM UIDM API
      const { data: dataUidm } = yield call(uidmApi.adapter, {
        method: 'get',
        url: `${uidmApi.utils.generateUrlUidmUsers(`bis_____${organisationId}`)}?size=${SIZE_USERS}`,
        headers: {
          'content-type': 'application/json',
          ...HEADERS_AUTH.headers,
        },
      });
      // GET LIST USERS FROM MDM API
      const { data: dataMdm } = yield call(mdmApi.adapter, {
        method: 'get',
        url: mdmApi.utils.generateMDMRoute(
          `${mdmApi.constants.USER_CONTROLLER}?orgId=${organisationId}&showDetails=1&showRefs=1&size=${SIZE_USERS}`,
        ),
        ...HEADERS_AUTH,
      });

      // MAP LIST MDM USERS and LIST UIDM USERS
      if (dataMdm && dataMdm.content && dataUidm && dataUidm.content) {
        const { content: usersMdm } = dataMdm;
        const { content: usersUidm } = dataUidm;

        const combinedUsersList = userUtils.getUserList(usersMdm, usersUidm);
        yield put(actions.getUsersSuccess(combinedUsersList));
      }
    } catch (error) {
      yield put(actions.getUsersFailure(error));
    }
  },
  * [actions.sendInviteRequest]({ payload }) {
    try {
      const {
        email,
        // coveringLetter,
      } = payload;
      const appointToAdmin = false;
      const role = appointToAdmin ? 'exporter_admin' : 'exporter';

      const dataInviteStart = qs.stringify({
        ...BASE_DATA,
        access_token: getCookie('digital_mp_at'),
      });

      const { data: { execution } } = yield call(uidmApi.adapter, {
        method: 'post',
        url: 'sso/oauth2/access_token',
        data: dataInviteStart,
        headers: HEADERS,
        withCredentials: true,
      });

      const mdmOrgId = yield select((state) => state.search.currentOrg.uuid);

      const dataInviteSend = qs.stringify({
        ...BASE_DATA,
        ...SEND_DATA,
        execution,
        login: email,
        'emails[0].value': email,
        'extendedAttributes[mdmOrgId]': mdmOrgId,
        access_token: getCookie('digital_mp_at'),
        'orgMemberForm.roles[0]': role,
      });

      yield call(uidmApi.adapter, {
        method: 'post',
        url: '/sso/oauth2/access_token',
        headers: HEADERS,
        withCredentials: true,
        data: dataInviteSend,
      });

      yield put(actions.showSnackBar({ message: `Приглашение отправлено на почту ${email}`, showSnackbar: true }));
      yield put(actions.sendInviteSuccess());
    } catch (error) {
      yield put(actions.showSnackBar({
        message: 'Ошибка отправки приглашения на почту', showSnackbar: true, type: 'error',
      }));
      yield put(actions.sendInviteFailure(error));
    }
  },
  * [actions.sendUsersBlockRequest]({ payload: blockedUsers }) {
    try {
      const effects = [];
      blockedUsers.forEach((user) => {
        const {
          id,
          organization: { orgId },
        } = user;
        effects.push(
          call(uidmApi.adapter, generateConfigRequestUrlBlock(orgId, id, 'post')),
        );
      });
      yield all(effects);
      yield put(actions.showSnackBar({ message: 'Сотрудники заблокированы', showSnackbar: true }));
      yield put(actions.sendUsersBlockSuccess());
      yield put(actions.getUsersRequest());
    } catch (error) {
      yield put(actions.sendUsersBlockFailure(error));
    }
  },
  * [actions.sendUsersUnblockRequest]({ payload: unBlockedUsers }) {
    try {
      const effects = [];
      unBlockedUsers.forEach((user) => {
        const {
          id,
          organization: { orgId },
        } = user;
        effects.push(
          call(uidmApi.adapter, generateConfigRequestUrlBlock(orgId, id, 'delete')),
        );
      });
      yield all(effects);
      yield put(actions.showSnackBar({ message: 'Сотрудники разблокированы', showSnackbar: true }));
      yield put(actions.sendUsersUnblockSuccess());
      yield put(actions.getUsersRequest());
    } catch (error) {
      yield put(actions.sendUsersUnblockFailure(error));
    }
  },
  * [actions.sendUserUnblockRequest]({ payload }) {
    try {
      const {
        id,
        organization: { orgId },
      } = payload;

      yield call(uidmApi.adapter, {
        method: 'delete',
        url: uidmApi.utils.generateUrlBlockUser(orgId, id),
        headers: {
          'content-type': 'application/json',
          ...HEADERS_AUTH.headers,
        },
      });
      yield put(actions.showSnackBar({ message: 'Сотрудник разблокирован', showSnackbar: true }));
      yield put(actions.sendUserUnblockRequest());
      yield put(actions.getUsersRequest());
    } catch (error) {
      yield put(actions.sendUserUnblockFailure(error));
    }
  },
  * [actions.sendUserBlockRequest]({ payload }) {
    try {
      const {
        id,
        organization: { orgId },
      } = payload;
      yield call(uidmApi.adapter, {
        method: 'post',
        url: uidmApi.utils.generateUrlBlockUser(orgId, id),
        headers: {
          'content-type': 'application/json',
          ...HEADERS_AUTH.headers,
        },
      });
      yield put(actions.showSnackBar({ message: 'Сотрудник заблокирован', showSnackbar: true }));
      yield put(actions.sendUserBlockSuccess());
      yield put(actions.getUsersRequest());
    } catch (error) {
      yield put(actions.sendUserBlockFailure(error));
    }
  },

};

export default function* sagaReducer() {
  const sagas = Object
    .keys(HANDLERS)
    .reduce((acc, key) => {
      if (Object.prototype.hasOwnProperty.call(HANDLERS, key)) {
        acc.push(takeEvery(key, HANDLERS[key]));
      }
      return acc;
    }, []);
  yield all([...sagas]);
}
