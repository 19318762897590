import constants from './constants';

const {
  API_URL_V1,
} = constants;

const generateORGRoute = (path) => `${API_URL_V1}${path}`;

export default {
  generateORGRoute,
};
