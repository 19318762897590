import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import utilsConfigAdapter from '../../config/utils';

const CHAT_CONFIG = 'com.rooxteam.widgets.chat';
const CHAT_CONFIG_LIST_VAR = ['channelId', 'bootstrapUrl'];
// eslint-disable-next-line no-unused-vars
const {
  channelId = 'channel_54f930c',
  bootstrapUrl = '//craft-talk.t.exportcenter.ru/get-bootstrap/',
} = utilsConfigAdapter.getValuesFromConfigAdapter(CHAT_CONFIG_LIST_VAR, CHAT_CONFIG);

const Chat = () => {
  const user = useSelector((state) => state?.auth?.data || {});

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    window.__WebchatUserCallback = function () {
      const inn = get(user, 'organizations[0].extendedAttributes.inn');
      const data = {
          uuid: user.mdm_id,
          first_name: user.givenname,
          last_name: user.sn,
          email: user.contactEmail,
          phone: user.contactPhone,
          contract: '',
          company: user.displayNameCurrentOrgId,
          pages: 'lkp',
      };
      if (inn) data.inn = inn;
      return data;
    };
  }, []);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = `${bootstrapUrl}${channelId}`;
    script.defer = true;
    script.crossorigin = 'use-credentials';

    document.body.appendChild(script);
  }, []);

  return null;
};

export default memo(Chat);
