import {
  put,
  call,
  takeEvery,
  all,
  select,
} from 'redux-saga/effects';
import sanitizeHtml from 'sanitize-html';
import axios from 'axios';
import {
 ALLOWED_TAGS, NON_TEXT_TAGS, mdmApi, getCookie,
} from '../../../utils';
import actions from './actions';

const { generateMDMRoute } = mdmApi.utils;

const {
  getBannerInfo,
  getBannerInfoSuccess,
  getBannerInfoFailure,
} = actions;

const {
CATALOGS,
POST,
PARTNER_ACCREDITATIONS,
SEARCH,
} = mdmApi.constants;

const headers = {
  Authorization: `Bearer sso_1.0_${getCookie('digital_mp_at')}`,
  Accept: 'application/json, text/plain, */*',
  'Accept-Encoding': 'gzip, deflate',
  Connection: 'keep-alive',
  'Content-Type': 'application/json',
};
const { CancelToken } = axios;
// eslint-disable-next-line no-unused-vars
let cancel;

function* getPartnerDirections({ accDirections }) {
  try {
    const {
      currentOrgId,
    } = yield select((state) => ({
      currentOrgId: state.auth.data.currentOrgId,
    }));

    const {
      data: { content = [] },
    } = yield call(mdmApi.adapter, {
      url: generateMDMRoute(
        `${CATALOGS}${PARTNER_ACCREDITATIONS}/${SEARCH}`,
      ),
      method: POST,
      headers,
      params: {
        size: 100,
        showRefs: 1,
        showDetails: 1,
    },
      data: { partner: currentOrgId, accDirection_in: accDirections },
    });
    return content;
  } catch (error) {
    return [];
  }
}

const HANDLERS = {
  * [getBannerInfo]({ payload }) {
    try {
      const { key } = payload;
      const {
        data: {
          content = [],
        },
      } = yield call(mdmApi.adapter, {
        method: POST,
        url: generateMDMRoute(`${CATALOGS}/partner_lk_notifications/${SEARCH}`, false),
        headers,
        params: {
          size: 100,
          showRefs: 1,
          showDetails: 1,
        },
        data: { key },
        cancelToken: new CancelToken(((c) => {
          cancel = c;
        })),
      });
    const { accreditationAccess = [], notificationText = '' } = content[0] || {};
    if (content.length && notificationText) {
      const preparedBannerText = sanitizeHtml(notificationText, {
        allowedTags: ALLOWED_TAGS,
        allowedAttributes: false,
        nonTextTags: NON_TEXT_TAGS,
        disallowedTagsMode: 'discard',
      });
      if (!accreditationAccess.length) {
        yield put(getBannerInfoSuccess({ isShow: true, text: preparedBannerText }));
        return;
      }

      if (accreditationAccess.length) {
        const accDirections = accreditationAccess.map(({ accDirection }) => accDirection.uuid);
        const checkAcc = yield call(getPartnerDirections, { accDirections });
        if (checkAcc.length) {
          yield put(getBannerInfoSuccess({ isShow: true, text: preparedBannerText }));
          return;
        }
      }
    }

    yield put(getBannerInfoSuccess({ isShow: false }));
    } catch (err) {
      yield put(getBannerInfoFailure(err));
    }
  },
};

export default function* sagaReducer() {
  const sagas = Object
    .keys(HANDLERS)
    .reduce((acc, key) => {
      if (Object.prototype.hasOwnProperty.call(HANDLERS, key)) {
        acc.push(takeEvery(key, HANDLERS[key]));
      }
      return acc;
    }, []);
  yield all([...sagas]);
}
