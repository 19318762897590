import React, {
  useMemo,
  useCallback,
  memo,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Icons } from 'elk-uikit';
import moment from 'moment';
import lodashUuid from 'lodash-uuid';

import colors from '../../styles/theme';
import serviceProfileActions from '../../pages/ServiceProfile/effects/actions';
import { formatBytes, i18n } from '../../utils';
import { LoadPage } from '../../utils/helpers/loadPage';
import DocumentsDropdown from '../../pages/ServiceProfile/components/FormDocuments/DocumentsDropdown';
import AddButton from '../AddButton';
import LineSeparator from '../LineSeparator';

import Caption from './Caption';
import styles from './Document.module.scss';

const { updateFormDocuments } = serviceProfileActions;
const { getFormattedMessage, locale } = i18n;
const DOC_LOCALE_NAME = 'doc';
const DOCS_LOCALE_NAME = 'docs';

const AddDocumentForm = LoadPage('NewDocument');

const generateDate = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return moment(new Date(year, month - 1, day)).locale(locale.name).format('LL');
};

const getStylesWithDisabled = (disabled, ...defaultStyles) => {
  if (disabled) {
    return cn(
      ...defaultStyles,
      { [styles.disabled]: disabled },
    );
  }
  return cn(...defaultStyles);
};

const getIconColor = (disabled) => (disabled ? colors.disabled : colors.dark);

function Document({
  uuid,
  regDate,
  caption,
  issuer,
  description,
  attachments,
  downloadFile,
  deleteDocument,
  documentTypeIdentifier,
  showSeparator,
  formProps: {
    status,
  },
  isServices,
  index,
  addDocumentFormState,
  handleAddDocumentFormState,
}) {
  const dispatch = useDispatch();
  const formDocuments = useSelector((state) => state.ServiceProfile.formDocumentsBuffer);

  const showAddDocumentForm = useMemo(() => (
    addDocumentFormState.show && addDocumentFormState.id === uuid
  ), [uuid, addDocumentFormState]);
  const disabled = useMemo(() => (
    addDocumentFormState.show && addDocumentFormState.id !== uuid
  ), [uuid, addDocumentFormState]);
  const attachmentsToRender = useMemo(() => attachments.slice(0, 3), [attachments]);
  const needToAddDocument = useMemo(() => status === 'needToUpload' || status === 'multiple', [status]);

  const removeDocumentFromForm = useCallback(() => {
    let filteredDocuments;
    if (status !== 'multiple') {
      filteredDocuments = formDocuments.map((document) => {
        if (document.uuid === uuid) {
          return {
            uuid: `temp-${lodashUuid.uuid()}`,
            caption: document.documentTypeIdentifier.caption,
            docKind: document.docKind,
            formProps: {
              ...document.formProps,
              status: 'needToUpload',
            },
            documentTypeIdentifier: document.documentTypeIdentifier,
          };
        }
        return { ...document };
      });
    } else {
      filteredDocuments = formDocuments.filter((document) => document.uuid !== uuid);
    }

    dispatch(updateFormDocuments(filteredDocuments));
  }, [dispatch, status, formDocuments, uuid]);

  const callFunction = useCallback((callback) => {
    if (!disabled) callback();
  }, [disabled]);

  if (showAddDocumentForm) {
    return (
      <>
        <Caption text={caption} needToAddDocument={needToAddDocument} />
        <div className={styles.formWrapper}>
          <AddDocumentForm
            onActionFromServices={() => handleAddDocumentFormState({ show: false, id: null })}
            sourceDocumentUuid={uuid}
          />
        </div>
        {showSeparator && (
          <div className={styles.separator}>
            <LineSeparator />
          </div>
        )}
      </>
    );
  }

  return (
    <div className={cn(styles.constituentWrapper)}>
      <div className={styles.parametersDocumentWrapper}>
        <div className={styles.parametersDocument}>
          <Caption text={caption} muted={needToAddDocument || disabled} />
          {status === 'multiple' && (
            <DocumentsDropdown
              index={index}
              documentType={documentTypeIdentifier}
              disabled={disabled}
            />
          )}
          {regDate && (
            <span
              className={getStylesWithDisabled(disabled, styles.documentData)}
            >
              {
                getFormattedMessage(
                  'card.label.date.from',
                  DOCS_LOCALE_NAME,
                  { from: generateDate(regDate) },
                )
              }
            </span>
          )}
          {issuer && (
            <>
              <span className={getStylesWithDisabled(disabled, styles.issuingAuthorityTitle)}>
                {getFormattedMessage('form.dropdown.authority.label', DOC_LOCALE_NAME)}
              </span>
              <span
                className={getStylesWithDisabled(
                  disabled,
                  styles.issuingAuthority,
                  styles.spanText,
                )}
              >
                {issuer}
              </span>
            </>
          )}
          <div className={styles.descriptionTrashWrapper}>
            {description && (
              <div className={styles.descriptionWrapper}>
                <span
                  className={getStylesWithDisabled(disabled, styles.descriptionTitle)}
                >
                  {getFormattedMessage('form.input.description.label', DOC_LOCALE_NAME)}
                </span>
                <span
                  className={getStylesWithDisabled(
                    disabled,
                    styles.issuingAuthority,
                    styles.spanText,
                  )}
                >
                  {description}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.documentRightWrapper}>
          {needToAddDocument && (
            <AddButton
              disabled={disabled}
              color="accent"
              onClick={() => callFunction(() => handleAddDocumentFormState({ show: true, id: uuid }))}
            />
          )}
          {attachmentsToRender && (
            <div className={styles.documentFilesWrapper}>
              {attachmentsToRender.map(({
                uuid: uuidFile,
                fileName,
                size = '',
              }) => (
                <div
                  key={`setup-paper-document-file-${uuidFile}`}
                  className={styles.documentFileWrapper}
                  onClick={() => downloadFile(
                    uuid,
                    documentTypeIdentifier.documentTypeIdentifier,
                    uuidFile,
                    fileName,
                  )}
                >
                  <div className={styles.documentFile}>
                    <Icons.FileIcon
                      fill={getIconColor(disabled)}
                    />
                    <span
                      className={getStylesWithDisabled(
                        disabled,
                        styles.nameFile,
                      )}
                    >
                      {fileName}
                    </span>
                    <span
                      className={getStylesWithDisabled(
                        disabled,
                        styles.sizeFile,
                      )}
                    >
                      {size ? formatBytes(size) : ''}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
          {!needToAddDocument && (
            <div className={styles.trash}>
              {
                isServices
                  ? (
                    <Icons.Close
                      width={15}
                      height={15}
                      fill={getIconColor(disabled)}
                      onClick={() => callFunction(removeDocumentFromForm)}
                    />
                  )
                  : (
                    <Icons.Trash
                      fill={getIconColor(disabled)}
                      onClick={() => callFunction(deleteDocument(
                        uuid,
                        documentTypeIdentifier.documentTypeIdentifier,
                        'orgSetupPapers',
                      ))}
                    />
                  )
              }
            </div>
          )}
        </div>
      </div>
      {showSeparator && (
        <div className={styles.separator}>
          <LineSeparator />
        </div>
      )}
    </div>
  );
}

Document.propTypes = {
  uuid: PropTypes.string.isRequired,
  regDate: PropTypes.string,
  caption: PropTypes.string,
  issuer: PropTypes.string,
  description: PropTypes.string,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      fileName: PropTypes.string,
      size: PropTypes.string,
    }),
  ),
  downloadFile: PropTypes.func,
  deleteDocument: PropTypes.func,
  documentTypeIdentifier: PropTypes.string,
  showSeparator: PropTypes.bool,
  formProps: PropTypes.shape({
    status: PropTypes.string,
  }),
  index: PropTypes.number.isRequired,
  isServices: PropTypes.bool,
  addDocumentFormState: PropTypes.shape({
    show: PropTypes.bool,
    id: PropTypes.string,
  }).isRequired,
  handleAddDocumentFormState: PropTypes.func.isRequired,
};

Document.defaultProps = {
  regDate: '',
  caption: '',
  issuer: '',
  description: '',
  attachments: [],
  downloadFile: () => {},
  deleteDocument: () => {},
  documentTypeIdentifier: '',
  showSeparator: true,
  formProps: {},
  isServices: false,
};

export default memo(Document);
