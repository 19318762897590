import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Icons, Button } from '..';
import theme from '../../styles/theme';
import styles from './index.module.scss';

const { Search } = Icons;

const SearchButton = ({ onClick, disabled }) => (
  <Button
    color='dodgerBlue'
    onClick={onClick}
    disabled={disabled}
    className={styles.searchButton}
  >
    <Search fill={theme.white} fillOpacity="1" />
  </Button>
);

SearchButton.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

SearchButton.defaultProps = {
    onClick: () => {},
    disabled: false,
};

export default memo(SearchButton);
