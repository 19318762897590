import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './RoleBadge.module.scss';

const RoleBadge = ({
  children,
  customClassname,
}) => (
  <div
    className={cn(styles.badgeRoleWrapper, styles[customClassname])}
  >
    {children}
  </div>
);

RoleBadge.propTypes = {
  children: PropTypes.oneOfType(PropTypes.node, PropTypes.string),
  customClassname: PropTypes.string,
};

RoleBadge.defaultProps = {
  children: null,
  customClassname: null,
};

export default memo(RoleBadge);
