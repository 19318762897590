import _ from 'lodash';
import fp from 'lodash/fp';
import moment from 'moment';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import isBoolean from 'lodash/isBoolean';

const getCookie = () => {};
// import { getCookie } from 'utils';

/**
 * Хэлпер, который возвращает функцию для определения "не пустоты" передаваемого значения
 * Примеры использования:
 * isNotEmpty({}) // false
 * isNotEmpty({ key: value }) // true

 * @return function(*): boolean
 */
const isNotEmpty = fp.complement(fp.isEmpty);

/**
 * Хэлпер, который принимает общую информацию об сертификате и возвращает его краткое имя
 * getCertSimpleName('CN=Минкомсвязь России, INN=007710474375, ...') // Минкомсвязь России
 * @param {string} certInfo - Общая информация об сертификате

 * @return {string} Краткое имя сертификата
 */
const getCertSimpleName = (certInfo) => {
  const certInfoArray = _.split(certInfo, ',');

  const simpleCertName = _.find(certInfoArray, (name) => name.includes('CN='));

  return simpleCertName ? _.replace(simpleCertName, 'CN=', '') : simpleCertName;
};

/**
 * Метод, который принимает дату и возвращает относительную информацию о том, через сколько эта дата будет
 * или сколько времени прошло от этой даты
 * getRelativeTime('20111031') // 9 лет назад
 * @param {string | Date} date - Дата

 * @return {string} относительное время/дата
 */
const getRelativeTime = (date) => (date ? moment(date, 'YYYY-MM-DD').fromNow() : '');

/**
 * Метод, который принимает дату и возвращает дату в формате 'DD.MM.YYYY'
 * getFormattedDate('2020-06-16T12:02:20.016+0000') // 16.06.2020
 * @param {string | Date} date - Дата

 * @return {string} дата
 */
const getFormattedDate = (date, formatIn, formatOut) => (date
  ? moment(date, formatIn || 'YYYY-MM-DD').format(formatOut || 'DD.MM.YYYY') : '');

/**
 * Метод, который принимает дату и возвращает дату/время в формате 'DD.MM.YYYY HH:mm:ss'
 * getFormattedDateTime('2020-06-16T12:02:20.016+0000') // 16.06.2020 12:02:20
 * @param {string | Date} date - Дата

 * @return {string} дата/время
 */
const getFormattedDateTime = (date) => (date
  ? moment(date, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY HH:mm') : '');

/**
 * Метод, который принимает дату и возвращает дату в формате 'milliseconds'
 * getDateInMillisec('01.06.2020') // 1590958800000
 * @param {string | Date} date - Дата

 * @return {number} дата в миллисекундах
 */
const getDateInMillisec = (date) => (date ? moment(date, 'DD.MM.YYYY').valueOf() : '');

/**
 * Метод, для компонента FilterBlock проверяет больше или равна искомая дата текущей
 * Принимает дату начала и текущую дату
 * isAfterDate("16.06.2020", 15.06.2020") // true
 * @param {string | Date} dateFrom - дата начала
 * @param {string | Date} currentDate - текущая дата

 * @return {bool} true/false
 */
const isAfterDate = (dateFrom, currentDate) => {
  const currentDateInMs = getDateInMillisec(getFormattedDate(currentDate));
  const dateFromInMs = getDateInMillisec(dateFrom);

  return currentDateInMs >= dateFromInMs;
};

/**
 * Функция склонения слова в зависимости от количества
 * @param {string[]} titles [ед.ч, два, множество]
 * @param {number} count количество единиц
 * @return {string} нужное склонение в зависимости от количества
 */

const single = (count) => (
  count % 10 === 1 && count % 100 !== 11
); // если остаток от деления на 10 = 1 и остаток от деления на 100 не 11 выводим склонение в ед ч. (прим. 1 день)
const two = (count) => (
  count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)
); /* если остаток от деления на 10 >= 2 и <= 4
  и остаток от деления на 100 < 10 и >= 20 выводим склонение для двух. (прим 2 дня, 4дня) */

const declination = (titles, count) => {
  if (single(count)) return titles[0];
  if (two(count)) return titles[1];
  return titles[2];
};

/**
 * Метод, для компонента FilterBlock проверяет входит ли текущая дата в диапозон
 * Принимает дату начала, дату конца и текущую дату
 * isIncludesDate("01.06.2020", "30.06.2020", "15.06.2020") // true
 * @param {string | Date} dateFrom - дата начала
 * @param {string | Date} dateTo - дата конца
 * @param {string | Date} currentDate - текущая дата

 * @return {bool} true/false
 */
const isIncludesDate = (dateFrom, dateTo, currentDate) => {
  const dateFind = getDateInMillisec(getFormattedDate(currentDate));
  const dateStart = getDateInMillisec(dateFrom);
  const dateEnd = getDateInMillisec(dateTo);

  return dateFind >= dateStart && dateEnd >= dateFind;
};

/**
 * Метод возвращает токен
 *
 * @returns {string}
 */
const getAuthHeader = () => {
  const token = getCookie('digital_mp_at');
  return `Bearer sso_1.0_${token}`;
};

/**
 * Метод, который принимает число и возвращает строку разбитую на разряды
 * toFinanceFormat(3500) // '3 500'
 * @param {number | string } number - Число / Строка
 *
 * @return {string} строка в виде числа разбитого на разряды
 */
const toFinanceFormat = (number) => new Intl.NumberFormat({ useGrouping: true }).format(number);

// функция для скролла вверх
export const scrollToTopPage = () => window.scroll({
  top: 0,
  left: 0,
  behavior: 'smooth',
});
/**
 * Метод, который принимает строку с query params и возвращает URLSearchParams
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams}
 * useQuery('?uuid=a1ffd5bb-8cb8-47e4-a117-dcdeb10f7597')
 * @param {string } location - Строка
 *
 * @return {object} new URLSearchParams(location)
 */
const useQuery = (location) => new URLSearchParams(location);

// сетим свойство disabled для компонента <Filters />
const setMenuTabs = (tabs, items, name) => tabs.map((tab) => {
  if (items.filter((item) => item[name] === tab.name).length > 0) {
    return {
      ...tab,
      disabled: false,
    };
  }
  return { ...tab };
});

const tzoffset = (new Date()).getTimezoneOffset() * 60000;

// возвращает ширину столбца
const FULL_PAGE_TABLE_WIDTH = 1540;

const getTableColumnPercentWidth = (
  designWidthInPixels,
  tableWidth = FULL_PAGE_TABLE_WIDTH,
) => `${(
  (designWidthInPixels * 100)
    / (tableWidth)
).toFixed(2)}%`;

/**
 * Метод, который принимает объект фильтра и возвращает его значение
 */
const getFilterValue = (value) => {
  if (isArray(value) && value?.length) return value.map((val) => val.id);
  if (isString(value) && value?.length) return value;
  if (isObject(value) && get(value, 'id')) return get(value, 'id');
  if (isBoolean(value)) return value;
  return null;
};

/**
 * Метод, который принимает объект фильтров и возвращает его в виде, необходимом для передачи в data
 */
const prepareFilters = (filters, filtersNames) => Object.values(filtersNames).reduce(
  (acc, filterName) => {
    const filterValue = getFilterValue(get(filters, `${filterName}.value`));
    if (filterName === filtersNames.date) {
      const [dateFrom, dateTo] = filters[filterName].value.split(' - ');

      const result = { ...acc, filter: { ...acc.filter } };
      if (dateFrom) {
        result.filter = {
          ...result.filter,
          create_date_from: `${dateFrom.split('.').reverse().join('-')}T00:00:00.000+0000`,
        };
      }
      if (dateTo) {
        result.filter = {
          ...result.filter,
          create_date_to: `${dateTo.split('.').reverse().join('-')}T23:59:59.999+0000`,
        };
      }
      return result;
    }
    if (filterValue) {
      return {
        ...acc,
        filter: { ...acc.filter, [filterName.replaceAll('/', '.')]: filterValue },
      };
    }
    return acc;
  },
  { filter: {} },
);

export {
  isNotEmpty,
  getCertSimpleName,
  getFormattedDate,
  getFormattedDateTime,
  getDateInMillisec,
  getRelativeTime,
  getAuthHeader,
  setMenuTabs,
  toFinanceFormat,
  isIncludesDate,
  useQuery,
  isAfterDate,
  declination,
  tzoffset,
  getTableColumnPercentWidth,
  getFilterValue,
  prepareFilters,
};
