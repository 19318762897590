import Close from './Close';
import Delete from './Delete';
import Search from './Search';
import Circle from './Circle';
import QuestionInCircle from './QuestionInCircle';
import Upload from './Upload';
import UploadLight from './UploadLight';
import Attachment from './Attachment';
import ArrowDown from './ArrowDown';
import Logo from './Logo';
import LogoGos from './LogoGos';
import SortAsc from './SortAsc';
import SortDesc from './SortDesc';
import Check from './Check';
import Reload from './Reload';
import Top3 from './Top3';
import Top5 from './Top5';
import Top10 from './Top10';
import TopTime from './TopTime';
import TopConfirmed from './TopConfirmed';
import TopQuantity from './TopQuantity';
import TopLab from './TopLab';
import TopAvard from './TopAvard';
import TopAgreement from './TopAgreement';
import TopRecomendation from './TopRecomendation';
import Point from './Point';
import Refresh from './Refresh';

export default {
  Close,
  Delete,
  Search,
  Circle,
  QuestionInCircle,
  Upload,
  UploadLight,
  Attachment,
  ArrowDown,
  Logo,
  LogoGos,
  SortAsc,
  SortDesc,
  Check,
  Reload,
  Top3,
  Top5,
  Top10,
  TopTime,
  TopConfirmed,
  TopQuantity,
  TopLab,
  TopAvard,
  TopAgreement,
  TopRecomendation,
  Point,
  Refresh,
};
