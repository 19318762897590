import React, { memo } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { UidmProvider } from 'uidm-react-lib';
import actions from 'store/actions/auth';
import actionsAccreditationAccesses from 'store/actions/accreditationAccesses';
import { history } from './store/reducers';
import RouteMap from './pages/routes';
import store from './store/store';
import configAdapter from './config/config';
import { IS_DEVELOPMENT } from './utils';

const {
  getTokenSuccess,
} = actions;

const getSsoSettings = () => {
  const fields = ['oauthConsumerBaseUrl', 'ssoBaseUrl', 'gotoOnFailUrl', 'clientId'];
  return fields
    .reduce((obj, field) => ({
      ...obj,
      [field]: configAdapter(`com.rooxteam.widgets.armDmp.${field}`),
    }), {});
};

const App = () => {
  const ssoSettings = {
    ...getSsoSettings(),
    ...(IS_DEVELOPMENT ? { useOauthConsumerBaseUrl: true } : {}),
  };

  const uidmHandler = ({ success, data }) => {
    if (success && data) {
      store.dispatch(getTokenSuccess({ data }));
      store.dispatch(actionsAccreditationAccesses.getAccessesEtpAccCreationNational());
      store.dispatch(actionsAccreditationAccesses.getAccessesAffilates());
      store.dispatch(actionsAccreditationAccesses.getPartnerAccreditations());
    }
  };

  const uidmHandlerError = (error) => {
    // eslint-disable-next-line no-console
    console.log('error', error);
  };

  return (
    <UidmProvider
      initConfig={ssoSettings}
      LoadingComponent={<div>LOADING</div>}
      onEvent={uidmHandler}
      onError={uidmHandlerError}
    >
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <RouteMap />
        </ConnectedRouter>
      </Provider>
    </UidmProvider>
  );
};

export default memo(App);
