/* eslint-disable */
import {
  call, delay, put, select,
} from 'redux-saga/effects';
import get from 'lodash/get';
import { sagaGenerator } from 'store/helpers';
import { push } from 'connected-react-router';
// import { createMetrics } from 'react-metrics';
import actions from '../../../effects/actions';
import {
  bpmnApi,
  getCookie,
  // metricsConfig,
  tasksApi,
} from '../../../../../utils';
import { getErrMsg } from '../../../utils/helpers';
import { checkCredentials, checkErrorResponseCode, getSubmitType } from '../../../effects/sagaHelpers';
// import actionsMain from '../../../../Main/effects/actions';

// TO DO Разобраться с этим функционалом
const actionsMain = {};

const {
  sendServiceForm,
  refreshProcess,
  refreshProcessMultitask,
} = actions;

// const metrics = createMetrics(metricsConfig);

const getNextTaskId = (currentTaskId, direction) => {
  const currentStep = Number(currentTaskId[2]);
  if (currentTaskId === 'provided' && !currentStep) {
    return 'evaluation';
  }
  if (!currentStep) {
    return 'nf2';
  }
  if (direction === 'NEXT') {
    return `nf${currentStep + 1}`;
  }
  if (currentStep === 2) {
    return 'nf';
  }
  return `nf${currentStep - 1}`;
};

export function* sendDataForm({
  taskId,
  camundaId,
  nextStep,
  data,
  variables,
}) {
  const hasCredentials = checkCredentials(taskId, camundaId);
  if (!hasCredentials) return null;

  const submitType = getSubmitType(nextStep);

  const updatedVariables = {
    ...variables,
    ...data,
    NEXT: {
      value: submitType,
      type: 'String',
      valueInfo: {},
    },
  };

  const requestData = { variables: updatedVariables };
  const url = bpmnApi.utils.getTaskFinishUrl(taskId);
  const token = getCookie('digital_mp_at');
  const Authorization = `Bearer sso_1.0_${token}`;
  const { data: result } = yield call(bpmnApi.adapter, {
    method: 'post',
    url,
    headers: {
      Authorization,
      camundaId,
    },
    data: requestData,
  });
  return result;
}

let isSending = false;

const HANDLERS = {
  * [sendServiceForm]({
    payload: {
      taskId,
      camundaId,
      data,
      nextStep = 'NEXT',
      formWithSteps = false,
      redirectTo,
    },
  }) {
    const ns = 'ServiceProfile';
    const {
      processInstanceId = '',
      navigationButtons = {},
    } = yield select((state) => ({
      processInstanceId: get(state, `${ns}.taskContext.task.processInstanceId`, ''),
      navigationButtons: get(state, `${ns}.taskContext.uiSchema.navigationButtons`, {}),
    }));

    try {
      if (isSending) return;
      isSending = true;
      yield put(actions.handleSendServiceFormStatus(true));

      // TEMP: for test on staging
      // TODO: remove
      if (camundaId === 'test') {
        yield delay(500);
        const {
          id: currentTaskId = '',
        } = yield select((state) => state[ns].taskContext.task);

        if (taskId === 'evaluation') {
          redirectTo('/accreditation-requests');
        } else {
          const nextTaskId = getNextTaskId(currentTaskId, nextStep);
          redirectTo(`/accreditation-requests/services/${camundaId}/${nextTaskId}`);
        }

        yield put(actions.handleSendServiceFormStatus(false));

        // metrics.api.track('service_order', {
        //   status: 'success',
        //   taskId,
        //   camundaId,
        //   processInstanceId,
        //   nextStep,
        //   formWithSteps,
        // });
        return;
      }
      const selectedProducts = yield select((state) => state[ns].selectedProducts);
      const productsInfo = yield select((state) => {
        const yesFiles = (state[ns]?.formFiles['docsOfDirectManufacturerProducts-yes'] || []).map(({ id }) => id);
        const noFiles = (state[ns]?.formFiles['docsOfDirectManufacturerProducts-no'] || []).map(({ id }) => id);
        return {
          'docsOfDirectManufacturerProducts-yes': yesFiles,
          'docsOfDirectManufacturerProducts-no': noFiles,
        };
      });

      const productsData = {
        productsTable: {
          value: selectedProducts,
          type: 'Object',
          valueInfo: {
            objectTypeName: 'java.util.ArrayList',
            serializationDataFormat: 'application/x-java-serialized-object',
          },
        },
        ...Object.entries(productsInfo).reduce((acc, [key, value]) => {
          acc[key] = { value };
          return acc;
        }, {}),
      };

      const variables = yield select((state) => state[ns].taskContext.variables);

      const result = yield call(sendDataForm, {
        taskId,
        camundaId,
        nextStep,
        variables,
        data: { ...data, ...productsData },
      });
      if (!result) return;
      const { taskId: nextTaskId } = result;

      // metrics.api.track('service_order', {
      //   status: 'success',
      //   taskId,
      //   processInstanceId,
      //   camundaId,
      //   nextStep,
      //   formWithSteps,
      // });

      // const servicesList = yield select((state) => state.services.servicesList);
      // let serviceId;

      // if (servicesList && servicesList.drafts) {
      //   const { drafts } = servicesList;
      //   const service = drafts.find((item) => {
      //     let tasksIds = [];

      //     if (item.tasks && item.tasks.length) {
      //       tasksIds = item.tasks.map((task) => task.externalTaskId);
      //     }

      //     return tasksIds.includes(taskId);
      //   }) || {};
      //   serviceId = service.id;
      // }

      if (get(navigationButtons, 'next.waitMultiTask')) {
        yield put(actions.refreshProcessMultitask({ redirectTo }));
      } else if (nextTaskId) {
        yield put(actions.handleSendServiceFormStatus(false));
        redirectTo(`/accreditation-requests/services/${camundaId}/${nextTaskId}`);
      } else if (nextStep === 'FINISH' || !formWithSteps) {
        // Добавление новой таски в список на главной??????
        // yield put(actionsMain.addNewServiceId(serviceId));
        yield put(actions.handleSendServiceFormStatus(false));
        redirectTo('/accreditations');
      } else {
        yield put(actions.refreshProcess({ redirectTo }));
      }
      isSending = false;
    } catch (error) {
      console.log(error, 'error');
      isSending = false;
      if (checkErrorResponseCode(error)) {
        yield put(actions.refreshProcess({ redirectTo }));
        yield put(actions.errorHandlerServices({
          hasError: true,
          message: getErrMsg(error),
          errorFrom: 'finishServiceForm',
        }));
        return;
      }
      yield put(actions.handleSendServiceFormStatus(false));

      // metrics.api.track('service_order', {
      //   status: 'failure',
      //   taskId,
      //   processInstanceId,
      //   camundaId,
      //   nextStep,
      //   formWithSteps,
      // });
    }
  },
  * [refreshProcess]({ payload: { redirectTo } }) {
    const DEFAULT_DELAY = 2500;
    const DEFAULT_TRIES = 1;

    const ns = 'ServiceProfile';
    let finished = false;
    let counterOfCalls = 0;

    try {
      const {
        processInstanceId,
        tries,
        refreshDelay,
        camundaId,
        subtasks,
        restoreScrollPosition,
      } = yield select((state) => ({
        processInstanceId: get(state, `${ns}.taskContext.task.processInstanceId`, ''),
        camundaId: get(state, `${ns}.taskContext.task.camundaId`, ''),
        subtasks: get(state, `${ns}.taskContext.task.subtasks`, {}), // temp solution
        tries: get(state, `${ns}.taskContext.uiSchema.formRefresh.tries`, DEFAULT_TRIES),
        refreshDelay: get(state, `${ns}.taskContext.uiSchema.formRefresh.delay`, DEFAULT_DELAY),
        restoreScrollPosition: get(state, `${ns}.taskContext.uiSchema.restoreScrollPosition`, false),
      }));

      const url = bpmnApi.constants.BPMN_TASKS_API;

      while (!finished) {
        const { data } = yield call(bpmnApi.adapter, {
          method: 'get',
          url,
          headers: {
            camundaId,
          },
          params: {
            processInstanceId,
            size: 20,
          },
        });

        const nextTask = get(data, 'content', []).find(({ formKey }) => !subtasks[formKey]);// exclude subtasks

        if (nextTask) {
          const taskId = yield select((state) => state[ns].taskContext.task.id);
          yield put(actions.handleSendServiceFormStatus(false));
          finished = true;
          if (nextTask.id === taskId) {
            yield put(actions.getServiceInfo({
              taskId,
              camundaId,
            }));
            yield put(actions.resetPromptData());
            return;
          }
          redirectTo(`/accreditation-requests/services/${camundaId}/${nextTask.id}`, restoreScrollPosition);
        }

        counterOfCalls += 1;
        if (counterOfCalls >= tries) {
          finished = true;
          redirectTo('/accreditations');
        }
        yield delay(refreshDelay);
      }
    } catch (error) {
      finished = true;
      yield put(actions.handleSendServiceFormStatus(false));
      yield put(actions.errorHandlerServices({
        hasError: true,
        message: getErrMsg(error),
        errorFrom: 'refreshProcess',
      }));
    }
  },
  * [refreshProcessMultitask]({ payload: { redirectTo } }) {
    const DEFAULT_DELAY = 5000;
    const DEFAULT_TRIES = 16;

    const ns = 'ServiceProfile';
    let finished = false;
    let counterOfCalls = 0;

    try {
      const {
        processInstanceId,
        tries,
        refreshDelay,
        camundaId,
      } = yield select((state) => ({
        processInstanceId: get(state, `${ns}.taskContext.task.processInstanceId`, ''),
        camundaId: get(state, `${ns}.taskContext.task.camundaId`, ''),
        tries: get(state, `${ns}.taskContext.uiSchema.formRefresh.tries`, DEFAULT_TRIES),
        refreshDelay: get(state, `${ns}.taskContext.uiSchema.formRefresh.delay`, DEFAULT_DELAY),
      }));

      while (!finished) {
        const { data } = yield call(tasksApi.adapter, {
          method: 'GET',
          url: tasksApi.utils.refreshProcessesRoute,
          params: {
            processInstanceIds: processInstanceId,
          },
        });

        if (data && data[0]) {
          const nextMultitask = get(data[0], 'tasks', []).find(({ additionalInfo }) => additionalInfo
            && String(additionalInfo.multiTask) === 'true');

          if (nextMultitask && nextMultitask.externalTaskId && nextMultitask.camundaId) {
            const taskId = yield select((state) => state[ns].taskContext.task.id);
            yield put(actions.handleSendServiceFormStatus(false));
            finished = true;
            if (nextMultitask.externalTaskId === taskId) {
              yield put(actions.getServiceInfo({
                taskId,
                camundaId,
              }));
              yield put(actions.resetPromptData());

              return;
            }
            redirectTo(`/accreditation-requests/services/${nextMultitask.camundaId}/${nextMultitask.externalTaskId}`);
          }
        }

        counterOfCalls += 1;
        if (counterOfCalls >= tries) {
          finished = true;
          redirectTo('/accreditation-requests');
        }
        yield delay(refreshDelay);
      }
    } catch (error) {
      finished = true;
      yield put(actions.handleSendServiceFormStatus(false));
      yield put(actions.errorHandlerServices({
        hasError: true,
        message: getErrMsg(error),
        errorFrom: 'refreshProcessMultitask',
      }));
    }
  },
};

export default sagaGenerator(HANDLERS);
