import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { DateInput as DateInputBase } from 'elk-uikit';
import styles from './index.module.scss';

const DatePickerBase = ({
  type,
  startDate = '',
  endDate = '',
  onDateEndChange,
  onDateStartChange,
  positionCalendar,
  readonly,
}) => (
  <div className={styles.wrapperDatePicker}>
    <DateInputBase
      onChange={onDateStartChange}
      value={startDate}
      placeholder="дд.мм.гггг"
      positionCalendar={positionCalendar}
      dateFormat="dd.MM.yyyy"
      useSameStyles={styles.datePicker}
      readonly={readonly}
      disabled={readonly}
    />
    {type === 'range' && (
      <>
        <div className={styles.hyphen}>&nbsp;—&nbsp;</div>
        <DateInputBase
          onChange={onDateEndChange}
          value={endDate}
          positionCalendar={positionCalendar}
          useSameStyles={styles.datePicker}
          placeholder="дд.мм.гггг"
          dateFormat="dd.MM.yyyy"
          readonly={readonly}
          disabled={readonly}
        />
      </>
    )}
  </div>
);

DatePickerBase.propTypes = {
  type: PropTypes.oneOf(['range', 'single']),
  positionCalendar: PropTypes.oneOf(['left', 'right']),
  startDate: PropTypes.string,
  endDate: PropTypes.shape(),
  onDateEndChange: PropTypes.func,
  onDateStartChange: PropTypes.func,
  readonly: PropTypes.bool,
};
DatePickerBase.defaultProps = {
  type: 'range',
  startDate: null,
  endDate: null,
  onDateEndChange: () => {},
  onDateStartChange: () => {},
  readonly: false,
  positionCalendar: 'right',
};

export default memo(DatePickerBase);
